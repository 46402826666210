<template>
    <div class="application-card" @click.prevent.stop="cardOpened = !cardOpened">
        <div class="application-block">
            <div class="application-card__data">Номер заявки: {{ application.NAME }}</div>
            <div class="dropdown-wrapper">
                <b-dropdown variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            class="menu-wrapper">
                    <template #button-content>
                        <div class="menu"></div>
                    </template>
                    <b-dropdown-item @click="$emit('pickApplication', application)" v-b-modal.application-detail>
                        <img src="~@/assets/images/karandash.svg" alt="">
                        <span>Редактировать</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop="openModal">
                        <img src="~@/assets/images/copy.svg" alt="">
                        <span>Дублировать</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop="remove">
                        <img src="~@/assets/images/copy.svg" alt="">
                        <span>Удалить</span>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <div class="application-block date">
            <div class="application-card__data">Дата создания: {{ application.CREATE_DATE }}</div>
        </div>
        <div class="application-card__time">Время принятия: 12:45</div>
        <div class="application-card__service">{{ application.INSURANCE_CASE_TYPE }}</div>
        <p class="application-card__name">{{ application.DECLARER }}</p>
        <p class="application-card__phone">{{ application.DECLARER_PHONE }}</p>
        <div class="application-card__polisy">
            <h5 class="application-card__title">Номер полиса</h5>
            <div class="application-card__number-polisa">{{ application.CONTRACT_NAME }}</div>
        </div>

        <div class="card-hidden" :class="cardOpened ? '' : 'hidden'">
            <div class="card-hidden__block" v-if="application.COMMENTS">
                <h5 class="application-card__title">Комментарий</h5>
                <p @click.stop="commentsOpened = !commentsOpened" :class="commentsOpened ? '' : 'text-hidden'" class="application-card__comments">
                    {{ application.COMMENTS }}
                </p>
            </div>
            <div v-if="application.ADDRESS" class="card-hidden__block">
                <h5 class="application-card__title">{{ addressFieldName }}</h5>
                <div class="application-card__place">{{ application.ADDRESS }}</div>
            </div>
            <div class="card-hidden__block" v-if="application.START_DATE && application.END_DATE">
                <h5 class="application-card__title">Срок действия</h5>
                <div class="application-card__validity-date">
                    {{ application.START_DATE }} - {{ application.END_DATE }}
                </div>
            </div>
        </div>

        <div class="hr"></div>
        <div class="application-card__operator">
            Оператор: <span>{{ creators[application.CREATOR_IIN] !== undefined ? creators[application.CREATOR_IIN] : application.CREATOR_IIN }}</span>
        </div>
        <!--<div class="application-card__validity">Срок действия: <span>15 мин</span></div>-->

        <div class="card-hidden" :class="cardOpened && historyShow ? '' : 'hidden'"  >
            <div @click.stop="changeHistory = !changeHistory" class="application-card__history-title-container">
                <h4 class="application-card__history-title">История изменений</h4>
                <div :class="changeHistory ? 'rotate' : ''" class="application-card__history-arrow"></div>
            </div>
            <div class="history__content" :class="changeHistory ? '' : 'hidden'">
                <div class="history__block sozdalZayavky">
                    <div class="history-time">2 октября 12:45</div>
                    <div class="history-title">Создал заявку</div>
                    <div class="history-name">Олег Иванов</div>
                </div>
                <div class="history__block vzyalVRaboty">
                    <div class="history-time">3 октября 12:45</div>
                    <div class="history-title">Создал заявку</div>
                    <div class="history-name">Олег Иванов</div>
                </div>
                <div class="history__block vipolnil">
                    <div class="history-time">4 октября 12:45</div>
                    <div class="history-title">Создал заявку</div>
                    <div class="history-name">Олег Иванов</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
    name: "ApplicationCard",
    props: {
        historyShow: {
            type: Boolean
        },
        application: {
            type: Object,
            required: true
        },
        creators: {
            type: Object,
            required: true
        },
        directories: {
            type: Object,
            required: true
        },
    },
    data: () => {
        return {
            cardOpened: false,
            commentsOpened: false,
            changeHistory: false
        }
    },
    methods: {
        ...mapActions({
            setCurrentApplication: 'modalOptions/setCurrentApplication'
        }),
        openModal() {
            this.setCurrentApplication(this.application)
            this.$bvModal.show('insurance-case-crm-modal')
        },
        remove() {
            this.$axios.post(`/insurance-cases/crm/change-status`, {
                id: this.application.INSURANCE_CASE_ID,
                status: 'TO_DELETED'
            }).then(response => {
                alert(this.$t(response.data.message))
                setTimeout(()=> {
                    window.location.reload();
                }, 2000)
            }).catch(errors => {
                alert(this.$t(errors.response.data.message))
            })
        }
    },
    computed: {
        addressFieldName() {
            let insuranceCaseType = this.directories['ins_case_type_id'].find(
                x => x.name === this.application.INSURANCE_CASE_TYPE || x.name === 'Осмотр для оценки с выездом'
            )
            return insuranceCaseType !== undefined ? insuranceCaseType.addressFieldName : ''
        }
    }
}
</script>

<style lang="scss">
/* Карточка заявки */
.application-card {
    cursor: pointer;
    margin: 0 auto 15px;
    padding: 10px 15px;
    width: 80%;
    min-height: 125px;
    background: #FFFFFF;
    box-shadow: 0 4px 5px rgba(115, 117, 140, 0.15);
    border-radius: 2px;
    &:hover{
        box-shadow: 0 4px 16px 1px rgba(115, 117, 140, 0.21);
    }
}
.application-block {
    display: flex;
    justify-content: space-between;

    &.date {
        margin-bottom: 10px;
    }

    .menu {
        padding: 5px;
        width: 15px;
        height: 3px;
        background: url('~@/assets/images/menu.svg') center / auto no-repeat;
        cursor: pointer;
    }
}
.application-card__data,
.application-card__time {
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 127.1%;
    color: #60627B;
}
.application-card__data {
    margin-bottom: 2px;
}
.application-card__service {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 127.1%;
}

.application-card__name {
    margin: 1px 0 5px;
    font-size: 13px;
    line-height: 127.1%;
}
.application-card__phone{
    font-size: 13px;
    margin-bottom: 13px;
}
.application-card__polisy{
    margin-bottom: 20px;
    .application-card__title{
    }
    .application-card__number-polisa{
        font-size: 14px;
    }
}
.application-card__operator {
    margin: 7px 0 6px 0;
}
.application-card__operator,
.application-card__validity {
    font-size: 12px;
    line-height: 127.1%;
    color: #60627B;
}
.application-card__operator span,
.application-card__validity span {
    font-weight: 600;
}

.card-hidden__block {
    margin-bottom: 20px;
}
.application-card__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 127.1%;
    color: #8A94A6;
}
.application-card__number-polisa,
.application-card__comments,
.application-card__validity-date,
.application-card__place {
    margin-top: 5px;
    font-size: 14px;
    line-height: 127.1%;
}
.application-card__comments {
    cursor: pointer;
    transition: all 1.3s;
}
.text-hidden {
    position: relative;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-line-clamp: 3;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    display: -webkit-box;
    display:box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    box-orient: vertical;
}
.text-hidden::after {
    content: '';
    position: absolute;
    bottom: 3px;
    right: 0;
    width: 5px;
    height: 8px;
    background: url('~@/assets/images/text-arr.svg') center / cover no-repeat;
}
.application-card__history-title-container {
    display: flex;
    align-items: center;
    padding: 20px 0 15px;
}
.application-card__history-arrow {
    margin-left: 5px;
    width: 12px;
    height: 8px;
    background: url('~@/assets/images/orangeArr.svg') center / contain no-repeat;
    transition: all 0.2s ease;
    &.rotate {
        transform: rotate(180deg);
    }
}
.application-card__history-title {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: #EF7F22;
    cursor: pointer;
}
.history__block {
    position: relative;
    margin-left: 4px;
    padding-left: 16px;
    border-left: 1px solid #ACB9CF;
    padding-bottom: 20px;
    &:last-child{
        min-height: 65px;
        flex-grow: 1;
    }
}
.history__block::after {
    position: absolute;
    content: '';
    left: -4px;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
.sozdalZayavky::after {
    background-color: #ACB9CF;
}
.vzyalVRaboty::after {
    background-color: #ACB9CF;
}
.vipolnil::after {
    background-color: #ACB9CF;
}
.history-time {
    font-size: 10px;
    line-height: 127.1%;
    color: #EF7F22;
}
.history-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 127.1%;
}
.history-name {
    font-size: 12px;
    line-height: 127.1%;
}
.hidden {
    display: none;
}
.history__content {
    margin-bottom: -10px;
}
</style>
