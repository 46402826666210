<template>
  <div class="calc-module-210222">
    <section class="base-calc kascoTrn-calc" data-calc="TRANSFORMER">
      <div class="container">
        <div id="pre-loader">
          <b-spinner />
        </div>
        <div class="calculator-description-box">
          <div class="calculator-description-header">
            <h2 class="calculator-title">{{$t('CALCULATOR_INFO.TRANSFORMER.TITLE')}}</h2>
            <span :class="['calculator-description-arrow', {'is-closed': isMobile}]"></span>
          </div>
          <div :class="['calculator-description-body', {'is-closed': isMobile}, {'mob-show-scroll': isMobile}]">
            <p class="calculator-description-text" v-html="$t('CALCULATOR_INFO.TRANSFORMER.DESCRIPTION')"></p>
          </div>
        </div>
        <h1 class="ogpo-calc__main-title">{{ calculatorName() }}</h1>
        <div class="ogpo-content">
            <ExtendedConfirmationModal/>
            <ExtendedConfirmationInsuredModal/>
            <FullScreenPreloader :loading="loading"/>
          <!--step 1 - agreement with data collection and processing-->
          <div class="rights-blocker iin-collect" data-rights-blocker>
            <div class="popup">
              <div class="loader-container" data-loader>
                <div class="loader"></div>
              </div>
              <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
              <p class="popup-text" v-html="$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
              <label class="ogpo-calc__label step1 agreement-container">
                <p class="step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                <input data-field="iin_for_agree" name="iin_for_agree" type="text"
                       class="iin-for-agree imask-iin">
                <span data-error="iin-collect-error-field" class="iin-collect-error-field"></span>
              </label>
              <button class="iin-collect__modal-button mob-right" data-btn="data_collection_confirm">
                {{ $t('I_CONFIRM') }}
              </button>
            </div>
          </div>

          <!--step 2 - agreement with rules of policy-->
          <div class="rights-blocker" data-personal-blocker style="display: none">
            <div class="popup">
              <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
              <p class="popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>
              <label class="ogpo-calc__label step2">
                <input data-field="confirm" name="confirm" type="checkbox" value="true">
                <span class="ogpo-calc__checkBox"></span>
                <div v-html="$t('CALC_RIGHT_S2_CONFIRM')"></div>
              </label>
            </div>
          </div>
          <div class="ogpo-calc__nav">
            <div class="ul">
              <div class="ogpo-calc__line ogpo-calc__line-1 active" data-step-tab="1">
                <div class="ogpo-calc__nav-link link-step-1">1</div>
                <div class="line"></div>
              </div>

              <div class="ogpo-calc__line ogpo-calc__line-2" data-step-tab="2">
                <div class="ogpo-calc__nav-link link-step-2">2</div>
                <div class="line"></div>
              </div>
              <div class="ogpo-calc__line ogpo-calc__line-3" data-step-tab="3">
                <div class="ogpo-calc__nav-link link-step-3">3</div>
                <div class="line"></div>
              </div>
              <div class="ogpo-calc__line ogpo-calc__line-4"></div>
            </div>
          </div>
          <div class="step-1 step" data-step-cont="1">
            <div class="step-1__wrapper">
              <form class="ogpo-calc__block-top-box" action="javascript:void(0)" data-form="first"
                    method="post">
                <div class="ogpo-calc__block-checkbox main-confirm-step1">
                  <label class="ogpo-calc__label">
                    <input required class="ogpo-calc__input" type="checkbox" data-linked="confirm"
                           data-field="confirm"
                           value="true">
                    <span class="ogpo-calc__checkBox"></span>
                    <div v-html="$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                  </label>
                </div>
                <div class="kascoTrn__block-top">
                  <div class="kascoTrn__top-flex">
                    <div class="ogpo-calc__left">
                      <div class="ogpo-calc__srok">
                        <h4 class="ogpo-calc__title input__title">{{ $t('TERM_OF_INSURANCE') }}</h4>
                        <select class="js-example-basic-single js-select2 input transformer-input"
                                name="insurance_time" data-linked="insurance_time"
                                data-dun-select="insurance_time">
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                    <div class="ogpo-calc__right">
                      <div class="col-wrapper">
                        <h4 class="ogpo-calc__title input__title">{{ $t('CALL_POLICE') }}</h4>
                        <select class="l-select mst-input-b js-select2 transformer-input" name="call_police"
                                data-linked="call_police" data-dun-select="call_police">
                          <option>No data</option>
                        </select>
                      </div>
                    </div>


                  </div>
                  <div class="d-flex space-btwn flex-w calc__middle-row">
                    <div class="col-wrapper">
                      <h4 class="ogpo-calc__title input__title">{{ $t('SECURITY_EQUIPMENT') }}</h4>
                      <select class="select mst-input-b transformer-input" name="price"
                              data-linked="security_equipment"
                              data-dun-select="security_equipment">
                        <option>No data</option>
                      </select>
                    </div>
                    <div class="col-wrapper">
                      <h4 class="ogpo-calc__title input__title">{{ $t('WHERE_GET_INSURANCE') }}</h4>
                      <select class="select mst-input-b transformer-input" name="damage_assessment"
                              data-linked="damage_assessment"
                              data-dun-select="damage_assessment">
                        <option>No data</option>
                      </select>
                    </div>
                  </div>

                  <div class="d-flex space-btwn flex-w calc__middle-row">
                  <div class="col-wrapper">
                    <div class="title-block input__title">
                      <h4 class="ogpo-calc__title">{{ $t('SUM_INSURED_INFO') }}</h4>
                      <div class="info-icon mob-center custom-width"
                           :aria-label="$t('SUM_INSURED')"
                           data-cooltipz-dir="bottom">
                        <div class="question-mark"></div>
                      </div>
                    </div>
                    <input class="mst-input-b imask-ins_sum-trn" type="text"
                           data-linked="ins_sum" data-field="ins_sum">
                  </div>
                  <div class ="col-wrapper">
                      <div class="col-wrapper__car-electric-empty-block"></div>
                    <div class="ogpo-calc__block-checkbox">
                        <label class="ogpo-calc__label">
                        <input class="ogpo-calc__input input__lgotYsl" type="checkbox" data-linked="car_electric" data-field="car_electric" >
                          <span class="ogpo-calc__checkBox"></span>
                            {{ $t('ELECTRIC_CAR') }}
                        </label>
                    </div>
                    </div>
                    </div>
                  <div class="kascoTrn__wrap-flex">
                    <div class="kascoTrn__block">
                      <div class="row m-bottom custom__input-container">
                        <div class="kascoTrn__wrapper custom__input-wrapper">
                          <h4 class="ogpo-calc__title input__title custom__intput-title text-wrap-kostyl">{{ $t('PURPOSE_OF_USE') }}</h4>
                          <select class="s-select mst-input-sm js-select2"
                                  name="purpose_of_use" data-linked="purpose_of_use"
                                  data-dun-select="purpose_of_use">
                            <option>No data</option>
                          </select>
                        </div>
                        <div class="kascoTrn__wrapper custom__input-wrapper">
                          <h4 class="ogpo-calc__title input__title custom__intput-title">{{ $t('INSURANCE_TERRITORY') }}</h4>
                          <select class="price-select mst-input-sm js-select2"
                                  name="insurance_territory" data-linked="insurance_territory"
                                  data-dun-select="insurance_territory">
                            <option>No data</option>
                          </select>
                        </div>
                      </div>
                      <div class="kascoTrn__rangeSlide">
                        <div class="ogpo-calc__title">{{ $t('DAMAGE_FRANCHISE2') }}</div>
                        <div class="tick-slider2 js-tick-slider2" data-name="damage_franchise"
                             data-field="damage_franchise" data-json=""></div>
                        <!--                    <input type="text" class="step-3__range" name="rangeSlider" value="">-->
                      </div>
                    </div>
                    <div class="kascoTrn__block">
                      <h4 class="kascoTrn__risks-title input__title" v-html="$t('RISKS')"></h4>
                      <div class="js-param_risks">
                      </div>
                      <div class="risks-validation-error">
                        <p class="ogpo-calc__validation-error">{{$t('REQUIRED_FIELDS_ERROR')}}</p>
                      </div>

                    </div>
                  </div>
                </div>
              </form>
              <div class="ogpo-calc__block-mob">
                <!--form add driver-->
                <div data-add-insured="TRANSFORMER">
                  <form class="ogpo-calc__person" data-form="add_insured">
                    <input type="hidden" value="true" data-linked="is_insurant"
                           data-field='is_insurant'>
                    <div class="horizontal-mob-grid">
                      <div class="ogpo-calc__flex">
                        <div class="custom_mob-title-wrapper input__title">
                          <h4>{{ $t('IIN') }}</h4>
                          <h4 class="ogpo-calc__person-name-title" data-text="is_insurant">
                            {{ $t('I_INSURANT') }}
                          </h4>
                        </div>
                        <div class="ogpo-calc__flex">
                          <div class="ogpo-calc__person-left">
                            <input class="input-iin input-iin-1 imask-iin" type="text"
                                   data-linked="iin" data-field="iin" :value="iinValue">
                            <div class="ogpo-calc__block-checkbox" style="display: none">
                              <label class="ogpo-calc__label">
                                <input class="ogpo-calc__input input__lgotYsl" type="checkbox"
                                       data-field="has_privileges" data-linked="has_privileges">
                                <span class="ogpo-calc__checkBox"></span>
                                {{ $t('PRIVILEGIES') }}
                              </label>
                            </div>
                          </div>
                          <div class="ogpo-calc__person-right">

                            <div class="ogpo-calc__person-info person-info-iin"
                                 data-result="success">
                              <p class="ogpo-calc__person-name" data-result="name"></p>
                              <p class="ogpo-calc__person-class" data-result="class"
                                 :data-mask="'%num% ' + $t('CLASS')">1 {{ $t('CLASS') }}</p>
                            </div>
                            <p class="ogpo-calc__validation-error" data-result="error">
                              {{ $t('ERROR_DATA') }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="ogpo-calc__flex" style="display: none">
                        <div class="ogpo-calc__person-left" id="born-container-trn">
                          <div class="ogpo-calc__date" id="born-container-trn-date">
                            <h4 class="input__title">{{ $t('DTP_BORN_LABEL') }}</h4>
                            <div class="datepicker-wrapper">
                              <input class="input datepicker-base imask-date" type="text" data-field="born" :placeholder="$t('DMY')">
                            </div>
                          </div>
                          <div class="ogpo-calc__person-right">
                            <p class="ogpo-calc__validation-error" data-born-error>{{ $t('ENTER_BORN_DATE') }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--active-->
                    <div class="ogpo-calc__dop-polya" data-field="has_privileges_fields"
                         style="display: none">
                      <select class="select2 input-doc" name="privileges" data-linked="privileges"
                              data-dun-select="privileges">
                        <option value="no">{{ $t('NO') }}</option>
                      </select>
                      <div class="dop-polya__flex">
                        <div class="dop-polya__flex-left">
                          <h4 class="ogpo-calc__title input__title">{{ $t('PRIV_UDO_NUM') }}</h4>
                          <input class="input__number-doc" type="text"
                                 data-linked="privilege_doc_num">
                        </div>
                        <div class="dop-polya__flex-right">
                          <h4 class="ogpo-calc__title input__title">{{ $t('DATE') }}</h4>
                          <input class="input__number-doc datepicker-base imask-date"
                                 type="text" data-linked="privilege_doc_date">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <!--form add car-->
                <div data-add-cars="TRANSFORMER">
                  <div class="ogpo-calc__car mob-car-grid">
                    <div class="car-grid-item">
                      <h4 class="input__title">{{ $t('CUR_NUM') }}</h4>
                      <div class="ogpo-calc__flex">
                        <div class="ogpo-calc__car-left">
                          <input class="input-carNum" type="text" data-linked="number"
                                 data-field='number'>
                        </div>
                        <div class="ogpo-calc__car-right">
                          <p class="ogpo-calc__car-info" data-result="success"></p>
                          <p class="ogpo-calc__validation-error" data-result="error"></p>
                        </div>
                      </div>
                    </div>
                    <div class="car-grid-item" >
                      <div class="ogpo-calc__flex">
                        <h4 class="input__title">{{ $t('CAR_PASSPORT_NUM') }}</h4>
                        <div data-v-0284ddcc="" class="ont__wrapper">
                          <input data-v-0284ddcc="" type="text" class="ont__input input-iin" data-linked="car_passport" data-field='car_passport'>
                          <div data-v-0284ddcc="" class="ont-calc__name">
                            <div data-v-0284ddcc="" data-result="placeholder" class="input-iin-placeholder" v-html="$t('TECH_PASS_HINT')"></div>
                            <p data-v-0284ddcc="" data-loading="" class="ogpo-calc__validation-error" style="display: none;">Загрузка...</p>
                            <div data-v-0284ddcc="" data-result="name" class="name-text" style="display: none;">
                            </div>
                            <p data-v-0284ddcc="" data-result="passport-error" class="ont-calc__validation-error" style="display: none;">$t('ERROR_DATA')</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="ogpo-calc__block-bottom">
                <div data-add-insured="TRANSFORMER">
                  <!--insured form-->
                  <div class="ogpo-calc__person des">
                    <form action="" data-form="add_insured">
                      <input type="hidden" data-linked="is_insurant" data-field='is_insurant'
                             value="true">
                      <h4 class="ogpo-calc__title input__title">{{ $t('IIN') }}</h4>
                      <div class="ogpo-calc__flex">
                        <div class="ogpo-calc__person-left">
                          <!--input__validation-error-->
                          <input class="input-iin input-iin-1  imask-iin" type="text"
                                 data-linked="iin" data-field="iin" :value="iinValue">

                          <div class="ogpo-calc__block-checkbox" style="display: none">
                            <label class="ogpo-calc__label">
                              <input class="ogpo-calc__input input__lgotYsl"
                                     type="checkbox" data-linked="has_privileges"
                                     data-field="has_privileges">
                              <span class="ogpo-calc__checkBox"></span>
                              {{ $t('PRIVILEGIES') }}
                            </label>
                          </div>
                        </div>
                        <div class="ogpo-calc__person-right">
                          <h4 class="ogpo-calc__person-name-title" data-text="is_insurant">
                            {{ $t('I_INSURANT') }}</h4>
                          <div class="ogpo-calc__person-info person-info-iin"
                               data-result="success">
                            <p class="ogpo-calc__person-name" data-result="name"></p>
                            <p class="ogpo-calc__person-class" data-result="class"
                               :data-mask="'%num% ' + $t('CLASS')">1 {{ $t('CLASS') }}</p>
                          </div>
                          <p class="ogpo-calc__validation-error" data-result="error">
                            {{ $t('ERROR_DATE') }}</p>
                        </div>
                      </div>
                      <div class="ogpo-calc__dop-polya" data-field="has_privileges_fields"
                           style="display: none">
                        <select class="select2 input-doc" name="privileges"
                                data-linked="privilege_type" data-dun-select="privileges">
                          <option value="no">{{ $t('NO') }}</option>
                        </select>
                        <div class="dop-polya__flex">
                          <div class="dop-polya__flex-left">
                            <h4 class="ogpo-calc__title input__title">{{ $t('PRIV_UDO_NUM') }}</h4>
                            <input class="input__number-doc" type="text"
                                   data-linked="privilege_doc">
                          </div>
                          <div class="dop-polya__flex-right">
                            <h4 class="ogpo-calc__title input__title">{{ $t('DATE') }}</h4>
                            <input class="input__number-doc datepicker-base imask-date"
                                   type="text" data-linked="privilege_date">
                          </div>
                        </div>
                      </div>
                      <div class="ogpo-calc__flex">
                        <div class="ogpo-calc__person-left custom-calc__margin-top" id="born-container-trn">
                          <h4 class="ogpo-calc__title input__title">{{ $t('DTP_BORN_LABEL') }}</h4>
                          <div class="custom__datepicker-wrapper">
                            <div class="ogpo-calc__date">
                              <div class="datepicker-wrapper">
                                <input class="input datepicker-base imask-date" type="text" data-field="born" :placeholder="$t('DMY')">
                              </div>
                            </div>
                            <div class="ogpo-calc__person-right">
                              <p class="ogpo-calc__validation-error custom__date-error" data-born-error>{{ $t('ENTER_BORN_DATE') }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!--insured result-->
                  <div data-list></div>
                </div>
                <!--car result-->
                <div data-add-cars="TRANSFORMER">
                  <div class="ogpo-calc__car des">
                    <form action="" data-form="add_insured">
                      <h4 class="ogpo-calc__title input__title">{{ $t('CUR_NUM') }}</h4>
                      <div class="ogpo-calc__flex">
                        <div class="ogpo-calc__car-left d-flex space-btwn">
                          <div>
                            <input class="input-carNum" type="text" data-linked="number"
                                   data-field='number'>
                          </div>

                        </div>
                        <div class="ogpo-calc__car-right">
                          <p class="ogpo-calc__car-info" data-result="success"></p>
                          <p class="ogpo-calc__validation-error" data-result="error">
                            {{ $t('ERROR_DATA') }}</p>
                        </div>
                      </div>
                      <div class="custom__input-wrapper">
                        <h4 class="input__title">{{ $t('CAR_PASSPORT_NUM') }}</h4>
                        <div data-v-0284ddcc="" class="ont__wrapper">
                          <input data-v-0284ddcc="" type="text" class="ont__input input-iin" data-linked="car_passport" data-field='car_passport'>
                          <div data-v-0284ddcc="" class="ont-calc__name">
                            <div data-v-0284ddcc="" data-result="placeholder" class="input-iin-placeholder" v-html="$t('TECH_PASS_HINT')"></div>
                            <p data-v-0284ddcc="" data-result="passport-error" class="ont-calc__validation-error" style="display: none;">$t('ERROR_DATA')</p>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                  <!--car result-->
                  <div data-list></div>
                </div>
              </div>
              <div class="mst-calc__block-bottom">
                <div class="col-wrapper"></div>
                <div class="form-mst-result-price">
                  <div class="ogpo-calc__price">
                    <h4 class="ogpo-calc__title input__title">{{ $t('POLICY_COST') }}</h4>
                    <div class="ogpo-calc__price-value" data-calc-result="price">18 858 KZT</div>
                  </div>
                  <button class="ogpo-calc__button mst-buy-mst-step1 custom__calc-button" data-btn="buy-step-1">{{ $t('PAY_BTN_LINK') }}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="step-2 step" data-step-cont="2">
            <form method="post" action="javascript:;" data-form="second">
              <input type="hidden" name="notify_phone" value="true">
              <div class="ogpo-calc__block-checkbox main-confirm-step1 main-confirm-step2">
                <label class="ogpo-calc__label">
                  <input required class="ogpo-calc__input" type="checkbox" data-linked-step2="confirm"
                         data-field="confirm">
                  <span class="ogpo-calc__checkBox"></span>
                  <div v-html="$t('CALC_RIGHT_S2_CONFIRM')"></div>
                </label>
              </div>
              <div class="step-2__form-wrapper">
                <form action="">
                  <h2 class="step-2__form-title">{{ $t('INSURANT_INFO') }}</h2>
                  <p class="step-2__form-name" data-insurant="name">Олег И</p>
                  <div class="step-2__row">
                    <div class="step-2__col datepicker-wrapper">
                      <div class="input__title">{{ $t('VIEW_DATE') }}*</div>
                      <input readonly class="step-2__input transformer-calc__datepicker width100" type="text"
                             :placeholder="$t('DMY')" data-linked-step2="ins_date">
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('NAME') }}*</div>
                      <input class="step-2__input" type="text" required data-linked-step2="name"
                             data-main-user-iin :value="user.first_name">
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('SURNAME') }}*</div>
                      <input class="step-2__input" type="text" required
                             data-linked-step2="surname" data-main-user-iin
                             :value="user.last_name">
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('IMUSHESTVO_UDO') }}*</div>
                      <input class="step-2__input imask-udo-num" type="text" required
                             data-linked-step2="udo_num"
                             data-main-user-iin>
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('IMUSHESTVO_UDO_DATE') }}*</div>
                      <input class="step-2__input datepicker-base imask-date"
                             :placeholder="$t('DMY')" type="text" required
                             data-linked-step2="udo_date" data-main-user-iin>
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('HOW_UDO_AUTHOR') }}*</div>
                      <input class="step-2__input" type="text" required
                             data-linked-step2="udo_author">
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">

                      <div class="input__title">{{ $t('REG_ADDRESS') }}*</div>
                      <input class="step-2__input" type="text" required
                             data-linked-step2="address">
                    </div>
                    <div class="step-2__col">
                      <div class="horizontal-wrapper input__title">
                        <div>{{ $t('PHONE_NUMBER') }}*</div>
                        <div class="info-icon"
                             :aria-label="$t('VERIFICATION_PHONE_NUMBER_TOOLTIP')"
                             data-cooltipz-dir="bottom">
                          <div class="question-mark"></div>
                        </div>
                      </div>
                      <input class="step-2__input imask-phone" type="text" required
                             data-linked-step2="phone" :value="user.phone_number">
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="input__title">Email*</div>
                      <input class="step-2__input" type="email" required
                             data-linked-step2="email" :value="user.email">
                    </div>
                  </div>

                  <!--print insureds fields-->
                  <div data-insured-step2></div>
                  <!--print insureds fields END-->

                  <div class="step-2__checkbox-wrapper">
                    <div class="step-2__checkbox">
                      <label class="ogpo-calc__label">
                        <input class="ogpo-calc__input" type="checkbox"
                               data-linked-step2="notify_email">
                        <span class="ogpo-calc__checkBox"></span>
                        <div>{{ $t('GET_INFO_ON_EMAIL') }}</div>
                      </label>
                    </div>

                  </div>
                  <div class="step-2__flex">
                    <button class="step-2__button" data-btn="buy-step-2" disabled>
                      <span v-html="$t('CONFIRM_NUMBER')"></span>
                      <span data-btn="check-btn-stp-2"></span>
                    </button>
                    <div class="block">
                      <div class="step-2__price-lable">{{ $t('POLICY_COST') }}</div>
                      <div class="step-2__price-value" data-calc-result="price"></div>
                    </div>
                  </div>
                  <div class="step-2__validation-error" data-form-step2="error">
                    <p>
                      <span>!</span> {{ $t('ERROR_DATA_CHECK_FIELDS') }}</p>
                  </div>
                </form>
              </div>
            </form>
          </div>
          <div class="step-3 step" data-step-cont="3">
            <div class="step-3__wrapper">
              <div class="slick-slider">
                <div>
                  <form class="step-3__card" data-form="third" action="javascript:;">
                    <input type="hidden" name="type" value="default">
                    <template v-if="$t('URL_PREFIX')==='/kz'">
                      <p class="step-3__card-text">{{ $t('POLICY_COST') }}</p>
                      <p class="step-3__card-text bolder">{{ $t('TRN_INFO') }}</p>
                    </template>
                    <template v-else>
                      <p class="step-3__card-text bolder">{{ $t('TRN_INFO') }}</p>
                      <p class="step-3__card-text">{{ $t('POLICY_COST') }}</p>
                    </template>
                    <div class="step-3__risks">
                      <p class="title-risks">{{ $t('CALC_RIGHT_S3_RISKS_OUT') }}:</p>
                      <div class="step-3__risks-out" data-step-3="risks-out">
                        <p>Дтп</p>
                      </div>
                    </div>
                    <div class="step-3__card-price" data-calc-result='price'>0 KZT</div>
                    <div class="step-3__card-month">
                      <span class="num" data-calc-result='period'>12 месяцев</span>
                    </div>
                    <button class="step-3__card-button" data-btn="buy-step-3"
                            type="button">{{ $t('PAY_BTN_LINK') }}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import {calculatorsMixin} from "@/mixins/calculators/calculatorsMixin";
import ExtendedConfirmationModal from "@/components/modals/setClient/ExtendedConfirmationModal.vue";
import ExtendedConfirmationInsuredModal from "@/components/modals/setClient/ExtendedConfirmationInsuredModal.vue";
import FullScreenPreloader from "@/components/FullScreenPreloader.vue";

export default {
  name: "KaskoTransformer",
    components: {FullScreenPreloader, ExtendedConfirmationInsuredModal, ExtendedConfirmationModal},
  metaInfo: {
    title: i18n.tc('TRANSFORMER'),
    titleTemplate: '%s | Nomad.kz'
  },
  props: {
    policy: {
      type: Object
    }
  },
  data() {
    return {
      calcName: 'TRANSFORMER',
      user: {},
      policyFromSite: {},
      otherDrivers: {},
      userDataFromSite: false,
      loading: false,
      isMobile: false
    }
  },
  mixins: [calculatorsMixin],
  methods: {
    fetchUser() {
      this.user = {...this.$store.getters["auth/user"]}
      this.user.phone_number = '7' + this.user.phone_number.slice(1)
    },
    fetchPolicy() {
      if (!this.policy)
        return

      this.loading = true

      this.$axios.get(`/contracts/get/from-site/${this.policy.global_id}`)
      .then(response => {
        window.calcTransformer.setProlongingPolicy()
        if (!response.data.success)
          return

        this.policyFromSite = response.data.contract

        let props = this.policyFromSite.props

        let fields = {
          ins_date: this.beginDate,
          ins_sum: props['INSURANCE_PAYMENT'],
          insurance_time: Object.keys(props['INSURANCE_TIME'])[0],
          purpose_of_use: Object.keys(props['PURPOSE_OF_USE'])[0],
          insurance_territory: Object.keys(props['INSURANCE_TERRITORY'])[0],
          damage_franchise: Object.keys(props['DAMAGE_FRANCHISE'])[0],
          risks: props['RISKS'],
          call_police: Object.keys(props['CALL_POLICE'])[0],
          damage_assessment: Object.keys(props['DAMAGE_ASSESSMENT'])[0],
          security_equipment: Object.keys(props['SECURITY_EQUIPMENT'])[0]
        }

        window.calcTransformer.setFormData(fields)

        // слайдер франшизы
        document.querySelector('input[type=range]').value = fields.damage_franchise - 1
        window.calcTransformer.franchizeSl.setValue(fields.damage_franchise - 1)

        // добавление застрахованных
        let drivers = []
        props['DRIVERS_IIN'].forEach((item) => {
          drivers.push(item)
        })

        drivers.forEach((item, index) => {
          window.calcTransformer.modules.addInsured.obj.addRecord(item, props['DRIVING_EXPERIENCE'][index])
        })

        // добавление машин
        window.calcTransformer.modules.addCars.obj.addRecord(props['CAR_NUM'], props['CAR_PASSPORT'], true)

        let secondFormFields = {
          name: props.NAME,
          surname: props.SURNAME,
          udo_num: props.UDO_NUM,
          address: this.user.address ? this.user.address : props.ADDRESS_INS,
          udo_date: props.UDO_DATE,
          udo_author: props.IIN_AUTHOR,
          phone: "7" + props.PHONE.slice(1),
          email: props.EMAIL
        }

        window.calcTransformer.setSecondFormData(secondFormFields)

        for (let i = 0; i < props['INSURED_NAME'].length; i++) {
          if (i === 0) continue

          this.otherDrivers['insured_name[' + props['DRIVERS_IIN'][i] + ']'] = props['INSURED_NAME'][i]
          this.otherDrivers['insured_surname[' + props['DRIVERS_IIN'][i] + ']'] = props['INSURED_SURNAME'][i]
          this.otherDrivers['insured_udo_num[' + props['DRIVERS_IIN'][i] + ']'] = props['INSURED_UDO_NUM'][i]
          this.otherDrivers['insured_udo_date[' + props['DRIVERS_IIN'][i] + ']'] = props['INSURED_UDO_DATE'][i]
        }
      }).finally(() => {
        this.loading = false
      })
    },
    setOthersDriversData() {
      if (this.policy) {
        setTimeout(() => window.TRN.setSecondFormData(this.otherDrivers), 0)
      }
    },
    fetchUserData() {
      if (this.policy)
        return

      this.loading = true

      this.$axios.get('/contracts/user-data/get/from-site', {
        params: {
          iin: this.user.iin,
          product_name: 'Каско Трансформер'
        }
      }).then(response => {
        if (!response.data.success)
          return

        let data = response.data.data;
        let experience = this.getExperience(data.DRIVER_CLASS)
        this.userDataFromSite=true
        window.calcTransformer.modules.addCars.obj.addRecord(data.CAR_NUMBER, data.CAR_PASSPORT, true)
        window.calcTransformer.modules.addInsured.obj.addRecordEmit(data.IIN, experience)

        let secondFormFields = {
          udo_num: data.DOCUMENT_NUMBER,
          udo_date: data.DOCUMENT_DATE,
          udo_author: data.DOCUMENT_AUTHOR,
          address: this.user.address ? this.user.address : data.ADDRESS,
          phone: "7" + data.PHONE.slice(1),
          email: data.EMAIL
        }

        window.calcTransformer.setSecondFormData(secondFormFields)
      }).finally(() => {
        this.loading = false
      })
    },
    getExperience(driverClass) {
      if  (driverClass >= 3) {
        return 1;
      } else if (driverClass >= 4 && driverClass <= 6) {
        return 2;
      } else if (driverClass >= 7 && driverClass <= 10) {
        return 3;
      } else if (driverClass < 10) {
        return 4;
      }
    },
    calculatorName() {
      return this.mobileApp.isWebView() ? this.$t('TRANSFORMER_MOBILE') : this.$t('TRANSFORMER')
    }
  },
  mounted() {
    this.fetchUser()
    /* eslint-disable */
    this.isMobile = isMobile()
    this.$nextTick(() => {
      window.calcTransformer = new calcTransformer();
      window.calcTransformer.init();
      initImask()
      initSelect2()
      initDatepickers()
      initDescriptionAccordion()
      // подставляем ИИН пользователя
      $('[data-field="iin_for_agree"]').val(this.user.iin)
    })
  },
  computed: {
    beginDate() {
      if (!this.policy)
        return this.dateHelper.nextDay(new Date().toLocaleDateString())

      if (this.policy.active)
        return this.policy.end_date ? this.dateHelper.nextDay(this.policy.end_date) : ''
      else
        return this.dateHelper.nextDay(new Date().toLocaleDateString())
    },
    iinValue () {
      if (this.policy || this.userDataFromSite) {
        return ''
      } else  {
        return this.user.iin
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.calc-module-210222 {

    overflow-x: hidden;

    .container {
        margin: 0 auto;
        position: relative;
    }

    .row {
        margin-left: 0;
    }

    .row > * {
        width: auto;
    }
    .spinner-border {
        border: 0.25em solid currentColor;
        border-right-color: transparent;
    }
}
.calc-module-210222 .ogpo-calc__srok.pos-r {
    width: 298px;
}

.calc-module-210222 .ogpo-calc__srok.pos-r {
    width: 298px;
}

@media (max-width: 768px) {
    .calc-module-210222 .ogpo-calc__srok .select2-selection--single {
        width: 250px !important;
        height: 31px !important;
    }
}

.col-wrapper__car-electric-empty-block {
    height: calc(1em + 5px);
}

@media (max-width: 768px) {
    .col-wrapper__car-electric-empty-block {
        display: none;
    }
}

</style>
