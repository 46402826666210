<template>
    <b-modal modal-class="alert-info-text"
             ref="alert-info-text"
             id="alert-info-text"
             centered
             hide-footer
             hide-header
             @hide="onHide"
    >
        <p class="popup-text" v-html="alertText"></p>
        <div class="btns">
            <button type="button" class="btn-ok" @click="onOk">Ок</button>
        </div>
    </b-modal>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: 'AlertInfoText',
    computed: {
        alertText() {
            return this.$store.getters["modalOptions/alertText"]
        }
    },
    methods: {
        ...mapActions({
            setAlertText: 'modalOptions/setAlertText'
        }),
        onOk() {
            this.$bvModal.hide('alert-info-text')
        },
        onHide() {
            this.setAlertText('')
        }
    },
    watch: {
        alertText(newVal) {
            if (newVal.toString().length > 0) {
                this.$bvModal.show('alert-info-text')
            } else {
                this.$bvModal.hide('alert-info-text')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-content {
    max-width: 390px;
    width: 95%;
    background: #fff;
    border: 1px solid #d8d8d8;
    padding: 25px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    border-radius: 10px;
    font-size: 14px;
    color: #7E7E7E;
    font-weight: 400;
    margin: 0 auto;

    .modal-body {
        padding: 0;

        .popup-title {
            color: #EF7F22;
            margin-bottom: 12px;
        }

        .popup-text {
            margin-bottom: 21px;
            text-align: center;
        }

    }
    .btns{
        text-align: center;
    }
    .btn-ok,
    .btn-cancel{
        margin: 0 15px;
        min-width: 120px;
        display: inline-block;
        justify-content: center;
        align-items: center;
        background: $oriolesOrange;
        border:1px solid #EF7F22;
        border-radius: 50px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        transition: background 0.2s;
        cursor: pointer;
        padding: 14px 25px;
        &:hover{
            background: $hoverOriolesOrange;
            color: #fff;
        }
    }
    .btn-cancel{
        background: #fff;
        color: $oriolesOrange;
    }
}
@media (max-width: 991px) {
    .modal-content {
        .btn-ok,
        .btn-cancel {
            min-width: 95px;
            font-size: 13px;
            padding: 7px 25px;
        }
    }
    .popup-text {
        margin-bottom: 21px;
        text-align: center;
        font-size: 14px;
    }
}
</style>
