import axios from "axios";
import store from "@/store"
import Cookies from "js-cookie";

const httpServicePlugin = {
  install(Vue) {
    axios.defaults.withCredentials = true
    // axios.defaults.baseURL = process.env.VUE_APP_API_URLja4Hq
    console.log(process.env.VUE_APP_API_URL);
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    axios.defaults.headers['Accept'] = 'application/json'
    axios.defaults.headers['Content-Type'] = 'application/json'
    axios.defaults.headers['locale'] = store.getters['i18n/locale']
    axios.interceptors.request.use(request => {
      const token = store.getters['auth/token']

      if (token) {
        request.headers.common.Authorization = `Bearer ${token}`
      }

      if (['post', 'put', 'delete'].includes(request.method) && !Cookies.get('XSRF-TOKEN')) {
        return setCSRFToken().then(() => request)
      }

      return request
    })

    const setCSRFToken = () => {
      return axios.get('/sanctum/csrf-cookie');
    }

    axios.interceptors.response.use(
      response => {
        return response
      },
      error => {
        switch (error.response.status) {
          case 401: // Not logged in
            store.dispatch('auth/clear')
            break;
          case 419: // Session expired
          case 403: // Forbidden
            if (store.getters['auth/isOperator'])
              store.dispatch('auth/logoutOperator')
            else
              store.dispatch("auth/logout");
            break;
          default:
            // Allow individual requests to handle other errors
            return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

    Vue.prototype.$axios = axios
    window.axios=Vue.prototype.$axios
  }
}

export default httpServicePlugin

