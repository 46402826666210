<template>
  <div class = "calc-module-210222">
    <section class = "base-calc ogpo-calc" data-calc = "OPP">
      <div class = "container">
        <div id = "pre-loader">
          <b-spinner />
        </div>
        <h1 class = "ogpo-calc__main-title">{{ $t('OPP_CALCULATOR') }}</h1>
        <div class = "opp-content">
          <ExtendedConfirmationModal />
          <FullScreenPreloader :loading = "loading" v-if = "loading" />
          <!--step 1 - agreement with data collection and processing-->
          <div class = "rights-blocker iin-collect" data-rights-blocker>
            <div class = "popup">
              <div class = "loader-container" data-loader>
                <div class = "loader"></div>
              </div>
              <p class = "popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
              <p class = "popup-text" v-html = "$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
              <label class = "ogpo-calc__label step1 agreement-container">
                <p class = "step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                <input data-field = "iin_for_agree"
                       name = "iin_for_agree"
                       type = "text"
                       class = "iin-for-agree imask-iin">
                <span data-error = "iin-collect-error-field" class = "iin-collect-error-field"></span>
              </label>
              <button class = "iin-collect__modal-button mob-right" data-btn = "data_collection_confirm">
                {{ $t('I_CONFIRM') }}
              </button>
            </div>
          </div>

          <div class = "rights-blocker" data-personal-blocker>
            <div class = "popup">
              <p class = "popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
              <p class = "popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>
              <label class = "ogpo-calc__label step2">
                <input data-field = "confirm" name = "confirm" type = "checkbox" value = "true">
                <span class = "ogpo-calc__checkBox"></span>
                <div v-html = "$t('CALC_RIGHT_S2_CONFIRM_OPP')"></div>
              </label>
            </div>
          </div>
          <div class = "ogpo-calc__nav">
            <div class = "ul">
              <div class = "ogpo-calc__line ogpo-calc__line-1 active" data-step-tab = "1">
                <div class = "ogpo-calc__nav-link link-step-1">1</div>
                <div class = "line"></div>
              </div>
              <div class = "ogpo-calc__line ogpo-calc__line-2" data-step-tab = "2">
                <div class = "ogpo-calc__nav-link link-step-2">2</div>
                <div class = "line"></div>
              </div>
              <div class = "ogpo-calc__line ogpo-calc__line-3" data-step-tab = "3">
                <div class = "ogpo-calc__nav-link link-step-3">3</div>
                <div class = "line"></div>
              </div>
              <div class = "ogpo-calc__line ogpo-calc__line-4"></div>
            </div>
          </div>

          <div class = "step-1 step" data-step-cont = "1">
            <div class = "step-1__wrapper">
              <form class = "ogpo-calc__block-top-box" action = "javascript:;" data-form = "first" method = "post">
                <div class = "ogpo-calc__block-checkbox main-confirm-step1">
                  <label class = "ogpo-calc__label">
                    <input required
                           class = "ogpo-calc__input"
                           type = "checkbox"
                           data-linked = "confirm"
                           data-field = "confirm"
                           value = "true">
                    <span class = "ogpo-calc__checkBox"></span>
                    <div v-html = "$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                  </label>
                </div>

                <div class = "opp-calc__main">
                  <div class = "calc__row brd-b pb-20">
                    <div class = "ogpo-calc__left">
                      <div class = "ogpo-calc__date">
                        <h4 class = "input__title">{{ $t('INS_DATE') }}</h4>
                        <div class = "datepicker-wrapper">
                          <input class = "input ogpo-calc__datepicker datepicker-ins-date imask-date"
                                 type = "text"
                                 data-linked = "ins_date"
                                 readonly>
                        </div>
                      </div>
                      <div class = "ogpo-calc__srok">
                        <h4 class = "input__title">{{ $t('TERM_OF_INSURANCE') }}</h4>
                        <select class = "js-example-basic-single input select2"
                                name = "ins_period"
                                data-linked = "ins_period"
                                data-dun-select = "ins_period">
                          <option value = "12 month">12 месяцев</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div data-add-cars-opp = "OPP">
                    <div class = "calc__row mt-17">
                      <div class = "horizontal-flex-wrapper input__title">
                        <h4 class = "input__title">{{ $t('CUR_NUM') }}</h4>
                        <div class = "flex-column align-items-start m-bottom-10">
                          <div class = "ogpo-calc__grid">
                            <div class = "opp-calc__car-left">
                              <input class = "input-carNum js-carNum"
                                     type = "text"
                                     data-linked = "number"
                                     data-field = "number">
                            </div>
                            <div class = "ogpo-calc__car-right custom-error-margin">
                              <p class = "calc__car-title" data-car-placeholder = "loading" style = "display: none">
                                Загрузка</p>
                              <p class = "ogpo-calc__car-info" data-result = "success"></p>
                              <p class = "ogpo-calc__validation-error" data-result = "error"></p>
                            </div>
                          </div>
                        </div>
                        <h4 class = "input__title">{{ $t('CAR_PASSPORT_NUM') }}</h4>
                        <div class = "ogpo-calc__grid">

                          <div class = "opp-calc__car-left">

                            <input class = "input-carNum"
                                   type = "text"
                                   data-linked = "car_passport"
                                   data-field = "car_passport">
                          </div>
                          <div class = "ogpo-calc__car-right custom-error-margin">
                            <p class = "calc__car-title"
                               data-car-placeholder = "OPP"
                               v-html = "$t('TECH_PASS_HINT')"></p>
                              <p class = "ogpo-calc__validation-error" data-result = "passport-error"></p>
                          </div>
                        </div>
                      </div>

                      <div class = "ogpo-calc__right mob-column">
                        <div class = "opp__wrapper">
                          <h4 class = "input__title">{{ $t('TF_TYPE') }}</h4>
                          <select class = "select2 input-doc"
                                  name = "tf_types"
                                  data-linked = "tf_types"
                                  data-dun-select = "tf_types">
                            <option value = "no">{{ $t('NO') }}</option>
                          </select>
                        </div>
                        <div class = "opp__wrapper">
                          <h4 class = "input__title">{{ $t('PASSENGERS_COUNT') }}</h4>

                          <input class = "opp-input-m js-count-pas"
                                 value = ""
                                 disabled
                                 data-linked = "num_of_passengers">

                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </form>
              <div class = "opp-calc__block-bottom">
                <div data-add-insured-opp = "OPP">
                  <!--insured form-->
                  <div class = "opp-calc__person des">
                    <form action = ""
                          class = "opp-calc__person_mob"
                          data-form = "add_insured"
                          style = "display: flex; justify-content: space-between; width: 100%">
                      <input type = "hidden" data-linked = "is_insurant" data-field = "is_insurant" value = "true">
                      <div class = "opp-calc__person-left">

                        <div class = "ogpo-calc__flex">
                          <div class = "ogpo-calc__person-left">
                            <div class = "horizontal-title-wrapper input__title">
                              <h4>{{ $t('IIN') }}</h4>
                              <h4 class = "ogpo-calc__person-name-title" data-text = "is_insurant">{{
                                  $t('I_INSURANT')
                                                                                                   }}</h4>
                            </div>
                            <input class = "input-iin input-iin-1  imask-iin"
                                   type = "text"
                                   data-linked = "iin"
                                   data-field = "iin">
                          </div>
                          <div class = "ogpo-calc__person-right">

                            <div class = "ogpo-calc__person-info person-info-iin" data-result = "success">
                              <p class = "ogpo-calc__person-name" data-result = "name"></p>
                              <p class = "ogpo-calc__person-class"
                                 data-result = "class"
                                 :data-mask = "'%num% ' + $t('CLASS')">1 {{ $t('CLASS') }}</p>
                            </div>
                            <p class = "ogpo-calc__validation-error" data-result = "error">{{ $t('ERROR_DATA') }}</p>
                          </div>
                        </div>
                        <div class = "ogpo-calc__flex">
                          <div class = "ogpo-calc__person-left" id = "born-container">
                            <div class = "ogpo-calc__date">
                              <h4 class = "input__title">{{ $t('DTP_BORN_LABEL') }}</h4>
                              <div class = "datepicker-wrapper">
                                <input class = "input datepicker-base imask-date"
                                       type = "text"
                                       data-field = "born"
                                       :placeholder = "$t('DMY')">
                              </div>
                            </div>
                            <p class = "ogpo-calc__validation-error" data-born-error>{{ $t('ENTER_BORN_DATE') }}</p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div data-add-insured-opp = "OPP">
                <div data-list
                     style = "display: flex; justify-content: space-between; flex-wrap: wrap; width: 100%"></div>
              </div>
              <div class = "button-container">
                <div class = "ogpo-calc__price">
                  <h4 class = "ogpo-calc__price-title">{{ $t('POLICY_COST') }}</h4>
                  <div class = "ogpo-calc__right">
                    <div class = "ogpo-calc__price">
                      <h4 style = "display: none;" class = "ogpo-calc__price-title">{{ $t('DOPLATA') }}</h4>
                      <div style = "display: none;" class = "ogpo-calc__price-value" data-calc-result = "price"></div>
                      <h4 style = "display: none;" class = "ogpo-calc__price-title" data-calc-result = "price_remain">{{
                          $t('REMAIN_PREMIUM')
                                                                                                                      }}:
                        <span data-calc-result = "price_remain_value"></span>
                      </h4>
                      <h4 style = "display: none;" class = "ogpo-calc__price-title" data-calc-result = "price_new">{{
                          $t('NEW_POLICY_COST')
                                                                                                                   }}
                        <span data-calc-result = "price_new_value"></span>
                      </h4>
                      <div class = "ogpo-calc__price-value" data-calc-result = "price"></div>
                    </div>
                  </div>

                </div>
                <button class = "ogpo-calc__button mob-right"
                        data-btn = "buy-step-1"
                        :aria-label = "$t('OGPO_STEP1_BTN_TOOLTIP')"
                        data-cooltipz-dir = "center">{{ $t('PAY_BTN_LINK') }}
                </button>
              </div>
            </div>
          </div>
          <div class = "step-2 step" data-step-cont = "2">
            <form method = "post" action = "javascript:;" data-form = "second">
              <input type = "hidden" name = "notify_phone" value = "true">
              <div class = "ogpo-calc__block-checkbox main-confirm-step1">
                <label class = "ogpo-calc__label">
                  <input required
                         class = "ogpo-calc__input"
                         type = "checkbox"
                         data-linked-step2 = "confirm"
                         data-field = "confirm">
                  <span class = "ogpo-calc__checkBox"></span>
                  <div v-html = "$t('CALC_RIGHT_S2_CONFIRM_OPP')"></div>
                </label>
              </div>
              <div class = "step-2__form-wrapper">
                <form action = "">
                  <h2 class = "step-2__form-title">{{ $t('INSURANT_INFO') }}</h2>
                  <p class = "step-2__form-name" data-insurant = "name"></p>
                  <div class = "step-2__row">
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('NAME') }}*</div>
                      <input class = "step-2__input uppercase"
                             type = "text"
                             required
                             data-linked-step2 = "name"
                             data-field = "name"
                             data-main-user-iin>
                    </div>
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('SURNAME') }}*</div>
                      <input class = "step-2__input uppercase"
                             type = "text"
                             required
                             data-linked-step2 = "surname"
                             data-field = "surname"
                             data-main-user-iin>
                    </div>
                  </div>
                  <div class = "step-2__row">
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('IMUSHESTVO_UDO') }}*</div>
                      <input class = "step-2__input mask-udo-num"
                             type = "text"
                             required
                             data-linked-step2 = "udo_num"
                             data-field = "udo_num"
                             data-main-user-iin>
                    </div>
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('REG_ADDRESS') }}*</div>
                      <input class = "step-2__input"
                             type = "text"
                             required
                             data-linked-step2 = "address"
                             data-field = "address">
                    </div>
                  </div>
                  <div class = "step-2__row">
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('IMUSHESTVO_UDO_DATE') }}*</div>
                      <input class = "step-2__input datepicker-base imask-date"
                             type = "text"
                             required
                             data-linked-step2 = "udo_date"
                             data-main-user-iin
                             placeholder = "дд.мм.гггг"
                             data-field = "udo_date">
                    </div>
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('HOW_UDO_AUTHOR') }}*</div>
                      <input class = "step-2__input"
                             type = "text"
                             required
                             data-linked-step2 = "udo_author"
                             data-field = "udo_author">
                    </div>
                  </div>
                  <div class = "step-2__row option">
                    <div class = "step-2__col">
                      <div class = "input__title">{{ $t('PHONE_NUMBER') }}*</div>
                      <input class = "step-2__input mask-phone"
                             type = "text"
                             required
                             data-linked-step2 = "phone"
                             data-field = "phone">
                    </div>
                    <div class = "step-2__col">
                      <div class = "input__title">Email*</div>
                      <input class = "step-2__input"
                             type = "email"
                             required
                             data-linked-step2 = "email"
                             data-field = "email">
                    </div>
                  </div>

                  <!--print insureds fields-->
                  <div data-insured-step2></div>
                  <!--print insureds fields END-->

                  <div class = "step-2__checkbox-wrapper">
                    <div class = "step-2__checkbox">
                      <label class = "ogpo-calc__label">
                        <input class = "ogpo-calc__input" type = "checkbox" data-linked-step2 = "notify_email">
                        <span class = "ogpo-calc__checkBox"></span>
                        <div class = "step-2-checkbox-text">{{ $t('GET_INFO_ON_EMAIL') }}</div>
                      </label>
                    </div>
                  </div>

                  <div data-fields = "ipdl" style = "display: none">
                    <div class = "ogpo-calc__block-checkbox checkbox-prprt">
                      <label class = "ogpo-calc__label">
                        <input class = "ogpo-calc__input input-IPDL"
                               type = "checkbox"
                               data-linked-step2 = "is_ipdl"
                               value = "true">
                        <span class = "ogpo-calc__checkBox"></span>
                        <div>{{ $t('I_AM_IPDL') }}</div>
                      </label>
                    </div>

                    <div class = "step-2__dop-polya" data-field-step2 = "ipdl_options">
                      <div class = "step-2__radio-wrapper">
                        <label class = "step-2__radio-label">
                          <input class = "step-2__radio-input"
                                 type = "radio"
                                 name = "IPDL"
                                 value = "Зарплата"
                                 checked
                                 data-linked-step2 = "ipdl_type">
                          <span class = "step-2__radio-style"></span>
                          <div class = "step-2__radio-text">{{ $t('SALARY') }}</div>
                        </label>
                      </div>
                      <div class = "step-2__radio-wrapper">
                        <label class = "step-2__radio-label">
                          <input class = "step-2__radio-input"
                                 type = "radio"
                                 name = "IPDL"
                                 value = "Доход от предпринимательской деятельности"
                                 data-linked-step2 = "ipdl_type">
                          <span class = "step-2__radio-style"></span>
                          <div class = "step-2__radio-text">{{ $t('BUSINESS_INCOME') }}</div>
                        </label>
                      </div>
                      <div class = "step-2__radio-wrapper">
                        <label class = "step-2__radio-label">
                          <input class = "step-2__radio-input"
                                 type = "radio"
                                 name = "IPDL"
                                 value = "Дивиденды"
                                 data-linked-step2 = "ipdl_type">
                          <span class = "step-2__radio-style"></span>
                          <div class = "step-2__radio-text">{{ $t('DIVIDENDS') }}</div>
                        </label>
                      </div>
                      <div class = "step-2__radio-wrapper">
                        <label class = "step-2__radio-label">
                          <input class = "step-2__radio-input"
                                 type = "radio"
                                 name = "IPDL"
                                 value = "Иное"
                                 data-linked-step2 = "ipdl_type">
                          <span class = "step-2__radio-style"></span>
                          <div class = "step-2__radio-text">{{ $t('OTHER') }}</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class = "step-2__flex">
                    <button class = "step-2__button" data-btn = "buy-step-2" disabled>
                      <span v-html = "$t('CONFIRM_NUMBER')"></span>
                      <span data-btn = "check-btn-stp-2"></span>
                    </button>
                    <div class = "block">
                      <div class = "step-2__price-lable" v-html = "$t('POLICY_COST_OGPO_STEP2')"></div>
                      <div class = "step-2__price-value" data-calc-result = "price"></div>
                    </div>
                  </div>
                  <div class = "step-2__validation-error" data-form-step2 = "error">
                    <p>
                      <span>!</span>
                      {{ $t('ERROR_DATA_CHECK_FIELDS') }}
                    </p>
                  </div>
                  <div class = "step-2__validation-error" data-form-step2 = "error-email">
                    <p>
                      <span>!</span>
                      {{ $t('EMAIL_REQUIRED') }}</p>
                  </div>
                </form>
              </div>
            </form>
          </div>
          <div class = "step-3 step" data-step-cont = "3">
            <div class = "step-3__wrapper">
              <div class = "calc-type">{{ $t('STD') }}</div>
              <div class = "slick-slider">
                <div>
                  <form class = "step-3__card" data-form = "third" action = "javascript:;">
                    <input type = "hidden" name = "type" value = "default">
                    <template v-if = "$t('URL_PREFIX')==='/kz'">
                      <p class = "step-3__card-text bolder">{{ $t('OPP_INFO') }}</p>
                      <p class = "step-3__card-text">{{ $t('POLICY_COST') }}</p>
                    </template>
                    <template v-else>
                      <p class = "step-3__card-text">{{ $t('POLICY_COST') }}</p>
                      <p class = "step-3__card-text bolder">{{ $t('OPP_INFO') }}</p>
                    </template>

                    <div class = "step-3__card-price" data-calc-result = "price">0 KZT</div>
                    <div class = "step-3__card-month"><span class = "num" data-calc-result = "period">12</span>
                      {{ $t('MONTH') }}
                    </div>
                    <button class = "step-3__card-button"
                            data-btn = "buy-step-3"
                            type = "button">{{ $t('PAY_BTN_LINK') }}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import ExtendedConfirmationModal from '@/components/modals/setClient/ExtendedConfirmationModal.vue'
import FullScreenPreloader from '@/components/FullScreenPreloader.vue'

export default {
  name: 'ACCIDENT',
  components: { FullScreenPreloader, ExtendedConfirmationModal },
  metaInfo: {
    title: i18n.tc('OPP_CALCULATOR'),
    titleTemplate: '%s | Nomad.kz'
  },
  props: {
    policy: {
      type: Object
    }
  },
  data () {
    return {
      calcName: 'OPP',
      user: {},
      phone: '',
      policyFromSite: {},
      userDataFromSite: false,
      loading: false,
      isMobile: false
    }
  },
  methods: {
    fetchUser () {
      this.user = { ...this.$store.getters['auth/user'] }
    },
  },
  mounted () {
    /* eslint-disable */
    this.loading = true
    this.isMobile = isMobile()
    this.fetchUser()
    this.$nextTick(() => {
      window.calcOpp = new calcOpp()
      window.calcOpp.init()
      // подставляем ИИН пользователя
      $('.popup .agreement-container input[data-field="iin_for_agree"]').val(this.user.iin)
      this.loading = false
      initImask()
      initMasks()
      initSelect2()
      initDatepickers()
      initDescriptionAccordion()
    })
  }
}
</script>
