<template>
    <div class="europrotocol-page flexible-size">
        <AlertInfoTextVue/>
        <template v-if="!isOpenErrorPage || false">
            <StepsList
                v-if="!isOpenErrorPage"
                :count-steps="5"
                :active-step="activeStep"
                :time="getTimer"
            />

            <StepOneQuestions v-if="activeStep===1"
                              :questions="step1Questions"
                              :number="activeSubStep"
                              :sendCallback="stepQuestionsAnswer"/>

            <StepTwoPersonsForms v-if="activeStep === 2"/>
            <StepThree v-if="activeStep === 3"/>
            <StepFourPhotos v-if="activeStep === 4"/>
            <StepFive v-if="activeStep === 5"/>
            <StepSix v-if="activeStep === 6"/>
        </template>
        <EuroprotocolErrorOne v-if="isOpenErrorPage"
                              :text="errorPageText"/>
        <EuroprotocolErrorGeolocation v-if="isOpenErrorPageGeolocation"
                              :text="errorPageGeolocationText"
                              :description="errorPageGeolocationDescription"/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {Keyboard} from "@capacitor/keyboard";
import {Capacitor} from "@capacitor/core";
import i18n from '@/plugins/i18n'
import StepsList from '@/components/mobileApp/europrotocol/StepsList'
import StepOneQuestions from '@/components/mobileApp/europrotocol/StepOneQuestions'
import StepTwoPersonsForms from '@/components/mobileApp/europrotocol/StepTwoPersonsForms'
import EuroprotocolErrorOne from '@/components/mobileApp/europrotocol/errors/EuroprotocolErrorOne'
import StepThree from '@/components/mobileApp/europrotocol/StepThree'
import StepFourPhotos from '@/components/mobileApp/europrotocol/StepFourPhotos.vue'
import StepFive from '@/components/mobileApp/europrotocol/StepFive.vue'
import StepSix from '@/components/mobileApp/europrotocol/StepSix.vue'
import AlertInfoTextVue from '../../components/modals/AlertInfoText.vue';
import { Geolocation } from '@capacitor/geolocation';
import EuroprotocolErrorGeolocation from '@/components/mobileApp/europrotocol/errors/EuroprotocolErrorGeolocation.vue'
import {europrotocolMixin} from "@/mixins/europrotocol/europrotocolMixin";

export default {
    name: 'EuroprotocolPage',
    metaInfo: {
        title: i18n.tc('Europrotocol'),
    },
    components: {
        EuroprotocolErrorGeolocation,
        StepFive,
        StepFourPhotos,
        StepsList,
        StepOneQuestions,
        StepTwoPersonsForms,
        EuroprotocolErrorOne,
        StepThree,
        StepSix,
        AlertInfoTextVue
    },
    data() {
        return {
            title: this.$t('Europrotocol'),
            backLinkName: 'dashboard',
        }
    },
    computed: {
        ...mapGetters('europrotocol', [
            'activeStep',
            'activeSubStep',
            'step1Questions',
            'isOpenErrorPage',
            'errorPageText',
            'errorPageGeolocationText',
            'errorPageGeolocationDescription',
            'isOpenErrorPageGeolocation',
            'getTimer',
            'isError',
        ])
    },
    mixins: [europrotocolMixin],
    methods: {
        ...mapActions('europrotocol', ['stepQuestionsAnswer', 'getLastStep', '_stepGoNext',
            'setError', 'showErrorPage']),

        showGeolocationNotAvailable: europrotocolMixin.methods.showGeolocationNotAvailable,
        showGeolocationPermissionDenied: europrotocolMixin.methods.showGeolocationPermissionDenied,

        async requestLocationPermission() {
            // проверяем и запрашиваем разрешения у приложения на доступ к геолокации
            try {
                const permissionStatus = await Geolocation.requestPermissions();

                if (!permissionStatus || permissionStatus.location !== 'granted') {
                   this.showGeolocationPermissionDenied()
                }
            } catch(error) {
                this.showGeolocationNotAvailable()
            }
        }
    },
    mounted() {
        this.getLastStep()
        if(Capacitor.isPluginAvailable('Keyboard')) {
            Keyboard.removeAllListeners().then(() => {
                Keyboard.addListener('keyboardDidShow', () => {
                    document.activeElement.scrollIntoView({block: "center", behavior: "smooth"})
                });
            })
        }

        if(Capacitor.getPlatform() !== 'web') {
            this.requestLocationPermission();
        }
    },
}
</script>

<style lang="scss">
.europrotocol-page{
    padding: size(16px) size(16px) size(24px);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    //height: 100%;
    min-height: 100%;
    background-color: #FFFFFF;
}

.ep-button{
    position: relative;
    display: flex;
    padding: size(8px) size(20px);
    justify-content: center;
    align-items: center;
    border-radius: size(40px);
    background: #EF7F22;
    width: 100%;
    height: size(40px);
    color: #FFF;
    font-size: size(14px);
    font-weight: 600;
    line-height: 127.1%; /* 17.794px */
    border: 1px solid #EF7F22;
    outline: none;
    transition: .1s ease-in-out;
    transition-property: background-color, border-color;

    &.reverse{
        background-color: transparent;
        color: #EF7F22;

        &.ep-button-grey{
            color: #73758C;
            border: 1px solid #ACB9CF;
            font-weight: 400;
        }
    }

    &:disabled{
        background-color: #f2ccad;
        border-color: #f2ccad;
    }

    &.event-none{
        pointer-events: none;
    }

    .ep-spinner{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: size(30px);
        height: size(30px);
    }

    .spinner-border{
        margin: 0;
        width: 100%;
        height: 100%;
    }
}

.ep-text-m{
    color: #000;
    font-size: size(18px);
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.18px;
}
.ep-text-sm{
    margin-top: 8px;
    font-size: size(14px);
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.14px;
}

.ep-text-s{
    font-size: size(12px);
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.12px;
    a{
        color: #EF7F22;
        &:hover{
            color: #EF7F22;
        }
    }
}
.ep-text-s-grey{
    color: #999;
    font-size: size(12px);
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.12px;
}

.ep-text-xs-orange{
    color: #EF7F22;
    font-size: size(10px);
    font-weight: 400;
    line-height: 130%; /* 13px */
    letter-spacing: 0.1px;
}

.ep-input-group{
    padding-bottom: size(18px);
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: size(5px);

    &.padding-none{
        padding: 0;
    }

    .ep-card-content{
        margin-top: size(11px);
    }

    &.cb-group{
        padding-bottom: size(14px);
    }
}

.ep-input{
    padding: size(4px) size(10px);
    width: 100%;
    height: size(36px);
    border-radius: size(4px);
    border: 1px solid #D8D8D8;
    font-size: size(14px);
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    transition: border-color .1s ease-in-out;

    &::placeholder{
        color: #CCC;
        letter-spacing: 0.14px;
    }

    &.ep-textarea{
        padding: size(10px);
        display: block;
        height: size(150px);
        resize: none;
    }
}

.ep-input-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: size(12px);
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.ep-input-sub-text{
    color: #999;
    font-size: size(10px);
    font-weight: 400;
    line-height: 130%; /* 13px */
}

.ep-input-error{
    position: absolute;
    bottom: 0;
    color: #EB0909;
    font-size: size(10px);
    font-weight: 400;
    line-height: 130%; /* 13px */
    letter-spacing: 0.1px;
    opacity: 0;
    transition: opacity .1s ease-in-out;

    &.pos-static{
        position: static;
        opacity: 1;
    }
}

.validation-error{
    .ep-input-error{
        opacity: 1;
    }

    .ep-input{
        border-color: #EB0909;
    }
}

.ep-select{
    &.multiselect{
        .multiselect__single{
            font-size: size(14px);
            font-weight: 400;
            line-height: 130%; /* 18.2px */
            color: #111;
        }

        .multiselect__placeholder{
            font-size: size(14px);
            font-weight: 400;
            line-height: 130%; /* 18.2px */
        }

        .multiselect__tags{
            padding: size(4px) size(10px);
            display: flex;
            align-items: center;
            height: size(36px);
            border-radius: size(4px);
            border: 1px solid #D8D8D8;
            font-size: size(14px);
            font-weight: 400;
            line-height: 130%; /* 18.2px */
        }

        .multiselect__select{
            height: 100%;
            width: size(14px);

            &:before{
                width: size(14px);
                height: size(8px);
                background: url(~@/assets/images/icons/arrow-down.svg) center/contain no-repeat;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &--active{
            &:not(.multiselect--above){
                .multiselect__tags{
                    border-radius: size(4px);
                }
            }
        }

        .multiselect__option{
            display: flex;
            align-items: center;
            min-height: size(36px);
            font-size: size(14px);
            padding: 0 size(10px);
            border-bottom: none;
            color: #000;

            &--selected.multiselect__option--highlight{
                background: #f3f3f3;
                color: #000;
            }
        }
    }
}

.ep-step-body{
    flex-grow: 1;
}

.ep-step__question-list{
    margin-top: size(24px);

    .n-radio-label{
        padding: size(12px) 0;
        margin: 0;
    }
}

.ep-step-footer{
    padding: size(16px) size(16px) calc(size(24px) + env(safe-area-inset-bottom));
    display: flex;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, #FFF 59.24%, rgba(255, 255, 255, 0.00) 100%);
    height: size(100px);
    width: 100%;
}

.ep-step-content{
    margin-top: size(24px);
    padding-bottom: calc(size(100px) + env(safe-area-inset-bottom));
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &.ep-footer-l{
        padding-bottom: calc(148px + env(safe-area-inset-bottom));

        .ep-step-footer{
            height: size(148px);
            flex-direction: column;
            justify-content: flex-end;
            row-gap: size(12px);
        }
    }
    &.ep-footer-none{
        padding-bottom: 0;
    }
    &.ep-time-content{
        margin-top: size(80px);
        p{
            text-align: center;
        }
    }
    &.ep-step-completed{
        row-gap: size(24px);
    }
}
.ep-pin-container{
    padding: 0 size(22px);
}

.ep-pin-list{
    display: flex;
    column-gap: size(12px);
}

.ep-pin-item{

}

.ep-pin-input{
    padding: size(10px);
    height: size(64px);
    width: 100%;
    border: 0;
    border-bottom: 2px solid #EF7F22;
    border-radius: 0;
    outline: none;
    font-size: size(32px);
    font-weight: 300;
    text-align: center;
    transition: border-color .1s ease-in-out;

    &.pin-empty{
        border-color: #D8D8D8;
    }
}

.validation-error{
    .ep-pin-input{
        border-color: #EB0909;
    }
}

.ep-step-text-top{
    display: flex;
    flex-direction: column;
    row-gap: size(8px);
}

.ep-step-radio-list{
    .n-radio-label{
        padding: size(12px) 0;
        margin: 0;
    }
}

.ep-checkbox{
    .custom-control-input{
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0);

        &:checked +{
            .custom-control-label{
                &::before{
                    background-color: #EF7F22;
                    border-color: #EF7F22;
                }

                &::after{
                    background: url(~@/assets/images/icons/checkV2.svg) center/16px size(16px) no-repeat;
                    opacity: 1;
                }
            }
        }
    }

    .custom-control-label{
        margin: 0;
        padding-left: size(36px);
        display: flex;
        align-items: center;
        column-gap: size(14px);
        position: relative;
        font-size: size(14px);
        font-weight: 400;
        line-height: 130%; /* 18.2px */
        letter-spacing: 0.14px;

        &:after,
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: size(20px);
            height: size(20px);
        }

        &:before{
            border-radius: size(4px);
            border: 2px solid #D8D8D8;
            transition: .1s ease-in-out;
            transition-property: background-color, border-color;
        }

        &:after{
            opacity: 0;
        }
    }

    &.indeterminate{
        .custom-control-label{
            &::before{
                background-color: #EF7F22;
                border-color: #EF7F22;
            }

            &::after{
                background: url(~@/assets/images/icons/minus.svg) center/12px size(4px) no-repeat;
                opacity: 1;
            }
        }
    }
}

.ep-collapse-group{
    padding: size(12px) 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
}

.ep-collapse-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: size(20px);
    flex-grow: 1;

    &.not-collapsed{
        .ep-collapse-icon{
            transform: rotate(180deg);
        }
    }
}

.ep-collapse-icon{
    flex-shrink: 0;
    display: block;
    width: size(20px);
    height: size(12px);
    background: url(~@/assets/images/icons/arrow-down.svg) center/contain no-repeat;
    transition: transform .3s ease-in-out;
}

.ep-collapse-body{
    padding-left: size(34px);

    .custom-checkbox{
        padding: size(12px) 0;
    }
}

.ep-input-radio-wrapper{
    .n-radio-label{
        padding: size(12px) 0;
        margin: 0;
    }
}

.ep-row{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: size(24px);
}

.ep-text-list{
    display: flex;
    flex-direction: column;
    row-gap: size(8px);

    .ep-text-s{
        padding-left: size(16px);
        position: relative;

        &:after{
            content: '';
            position: absolute;
            left: size(6px);
            top: 50%;
            transform: translateY(-50%);
            width: size(3px);
            height: size(3px);
            border-radius: 50%;
            background-color: #111;
        }
    }
}
div.ep-declaration-dialog{
    margin: 0;
    background-color: #ffffff;
    height: 100%;
}
.ep-declaration-content{
    height: 100%;
}
div.ep-declaration-content{
    padding-top: calc(size(40px) + env(safe-area-inset-top));
}
div.ep-declaration-body{
    margin-top: size(16px);
    padding-top: 0;
    padding-right: size(16px);
    padding-left: size(16px);
}
.ep-image{
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
}
.ep-button-l{
    padding: size(12px);
    display: flex;
    align-items: center;
    column-gap: size(12px);
    color: #000;
    font-size: size(14px);
    font-weight: 400;
    line-height: 127.1%; /* 17.794px */
    letter-spacing: 0.14px;
    border-radius: size(7px);
    background-color: #EAEAEA;
}
.ep-button-l-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: size(40px);
    height: size(40px);
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #D7D7D7;
    .icon{
        display: block;
        width: size(16px);
        height: size(20px);
        mask: url("~@/assets/images/c-doc-no-time.svg") center / contain no-repeat;
        -webkit-mask: url("~@/assets/images/c-doc-no-time.svg") center / contain no-repeat;
        background-color: #868687;
    }
}
.ep-qr-container{
    padding: size(30px);
}
.ep-image-watch{
    margin: 0 auto;
    display: block;
    width: size(80px);
    height: size(80px);
    object-fit: contain;
}
#ep-declaration-modal{
    z-index: 2147483642;
}

.ep-error-content {
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    height: 100vh;
    padding: calc(66px + env(safe-area-inset-top)) 20px 20px 20px;
    z-index: 10;
    overflow-y: scroll;

    .ep-text-s {
        margin: size(24px) 0 size(8px) 0
    }

    .header{
        padding: calc(0.25rem + env(safe-area-inset-top)) 0.75rem 0.25rem;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(2.5rem + env(safe-area-inset-top));
        background-color: #fff;
        border-bottom: 1px solid #EAEAEA;
        z-index: 3;
        display: flex;
        align-items: center;

        .europrotocol-header-exit{
            display: block;
            margin-right: 10px;
        }
    }
}
</style>
