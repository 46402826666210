import axios from "axios";
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    checkPolicy: false,
    currentPolicy: false,
    currentApplication: false,
    insCaseParams: {},
    insCaseInRequestForDisplayInModal: {},
    alertText: '',
    changePolicyRules:{
      global_id: '',
      iin: '',
    }
  },
  getters: {
    checkPolicy: state => state.checkPolicy,
    currentPolicy: state => state.currentPolicy,
    insCaseParams: state => state.insCaseParams,
    currentApplication: state => state.currentApplication,
    insCaseInRequestForDisplayInModal: state => state.insCaseInRequestForDisplayInModal,
    alertText: state => state.alertText,
    getChangePolicyRules:state => state.changePolicyRules
  },
  actions: {
    openChangePolicyModal({state},{global_id,iin}){
      state.changePolicyRules.global_id=global_id
      state.changePolicyRules.iin=iin
      // eslint-disable-next-line no-undef
      $('[data-remodal-id=change-policy-rules-box]').remodal().open()
    },
    setCheckPolicy({commit}, checkPolicy) {
      commit('SET_CHECK_POLICY', checkPolicy)
    },
    setCurrentPolicy({commit}, currentPolicy) {
      commit('SET_CURRENT_POLICY', currentPolicy)
    },
    setCurrentApplication({commit}, currentApplication) {
      commit('SET_CURRENT_APPLICATION', currentApplication)
    },
    setInsCaseInRequestForDisplayInModal({commit}, insCaseInRequest) {
      commit('SET_INS_CASE_IN_REQUEST', insCaseInRequest)
    },
    updateInsuranceCaseModal({state}, insuranceCase) {
      state.insCaseParams=insuranceCase;
      Vue.set(state.insCaseParams,'docs',[])
      Vue.set(state.insCaseParams,'reqFiles',[])
      if (!insuranceCase.iin || !insuranceCase.pc_id) {
        console.log('Не указан iin или id')
      } else {
        axios.post('/insurance-cases/affair',
          {
            iin: insuranceCase.iin,
            id: insuranceCase.pc_id
          }
        ).then((response) => {
          //обновляем документы в конце
          Vue.set(state.insCaseParams,'docs',response.data.detail.payout_cases[0].docs)
          Vue.set(state.insCaseParams,'reqFiles',response.data.reqFiles)
        })
      }
    },
    setAlertText({commit}, alertText) {
      commit('SET_ALERT_TEXT', alertText)
    },
  },
  mutations: {
    SET_CHECK_POLICY(state, value) {
      state.checkPolicy = value
    },
    SET_CURRENT_POLICY(state, value) {
      state.currentPolicy = value
    },
    SET_CURRENT_APPLICATION(state, value) {
      state.currentApplication = value
    },
    SET_INS_CASE_IN_REQUEST(state, value) {
      state.insCaseInRequestForDisplayInModal = value
    },
    SET_ALERT_TEXT(state, value) {
      state.alertText = value
    },
  }
}
