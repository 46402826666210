<template>
    <div
        class="dialog-window__row js-chat-message"
        ref="messageElem"
        v-bind:data-id="message.id"
        :class="[!!message.is_incoming?'incoming-messages':'outgoing-messages',!!message.from_operator?'from-operator':'from-user']"
    >
        <div class="system-label-wrp" @contextmenu.prevent="onContextMenu($event,message.id)">
            <div class="dialog-window__msg-box">
                <div class="dialog-window__msg-title" v-if="!!message.from_operator">{{message.user_fio}}</div>
                <div class="msg-box--txt-wrp">
                    <p class="msg-body">
                        <slot></slot>
                    </p>
                    <div class="msg-box__time-wrapper">
                        <div class="msg-box__time">
                            <p>{{ formatDateShort(message.create_stamp) }}</p>
                        </div>
                        <MessageStatus
                            :message="message"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="message.can_be_signed"
                class="dialog-window__doc-label"
                @click="applySignFile"
                :class="{ 'doc-signed': getSignState }"
            >
                {{ getSignMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import MessageStatus from "@/components/MessageStatus.vue";
import dateHelper from "@/helpers/date-helpers";
import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            bottomAnchorObserver: null
        };
    },
    props: {
        chatId: Number,
        message: Object,
        lastReaded: Boolean
    },
    components: {
        MessageStatus,
    },
    computed: {
        ...mapGetters('auth', ['asOperator']),
        ...mapGetters('chat', ['lastMessageId']),
        isMobile() {
          // eslint-disable-next-line no-undef
          return isMobile()
        },
        getSignMessage() {
            const signStatuses = this.constants.sign.statuses
            if(this.message?.sign_state === signStatuses.new) {
                if(this.isMobile) {
                    return 'Инструкция';
                }
                return 'Подписать документ';
            }
            if(this.message?.sign_state === signStatuses.in_progress) {
                if(this.isMobile) {
                    return 'Инструкция';
                }
                return 'Ожидает подписи';
            }
            if(this.message?.sign_state === signStatuses.success) {
                return 'Документ подписан';
            }
            return ''
        },
        getSignState() {
            if(this.message?.is_signed) {
                return true;
            }
            if(this.asOperator) {
                return true;
            }
            return this.message.sign_state === 'signed'
        }
    },
    methods: {
        ...mapActions('chat', [
            'signFile',
            'elementViewed',
            'showMessageDropdown',
            'setMessageDropdownData'
        ]),
        formatDateShort(date) {
            return dateHelper.toFormat(new Date(date), "HH:mm");
        },
        addObserver() {
            //view observer
            if (
                !this.message.is_readed &&
                (
                    (this.asOperator && !this.message.from_operator) ||
                    (!this.asOperator && this.message.from_operator)
                )
            ) {
                this.bottomAnchorObserver = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            if(+this.message.is_readed) return
                            this.elementViewed({
                                chatId: this.chatId,
                                messageId: this.message.id,
                            })
                            // off event
                            this.bottomAnchorObserver.unobserve(this.$refs.messageElem);
                        }
                    });
                });
                this.bottomAnchorObserver.unobserve(this.$refs.messageElem);
                this.bottomAnchorObserver.observe(this.$refs.messageElem);
            }
        },
        onContextMenu(event,messageId) {
            if(this.asOperator&&+this.message.from_operator){
                this.setMessageDropdownData({chatId:this.chatId,messageId:messageId});
                this.showMessageDropdown(event)
            }
        },
        applySignFile() {
            if(
                this.message.can_be_signed
                && !this.message.sign_state.includes(['signed'])
                && !this.getSignState
            ) {
                this.signFile(this.message.id);
            }
        }
    },
    mounted() {
        this.addObserver()
    },
    updated() {
        this.addObserver()
    }
};
</script>

<style lang="scss" scoped>
@media (max-width: 725px) {
    .system-label-wrp {
        max-width: 80%;
    }
}
.dialog-window__row {
    margin: 5px 0 5px 0;
    display: flex;
    justify-content: center;
    &.outgoing-messages {
        justify-content: flex-end;

        .dialog-window__msg-box {
            background: #e7ebf2;
        }
    }

    &.incoming-messages {
        justify-content: flex-start;

        .dialog-window__msg-box {
            background: #ffffff;
        }
    }
}

.dialog-window__msg-title {
    font-size: 10px;
    color: #EF7F22;
    margin-bottom: 5px;
}

.dialog-window__msg-box {
    padding: 10px;
    max-width: 330px;
    border-radius: 5px;
    line-height: 15px;

    .msg-box--txt-wrp {
        display: flex;
        justify-content: space-between;

        .msg-body {
            max-width: 267px;
            font-size: 13px;
            white-space: pre-wrap;
            word-break: break-word;
        }
    }

    .msg-box__time-wrapper {
        display: flex;
        float: right;
        align-items: center;
        align-self: flex-end;
        margin-left: 8px;
        column-gap: 3px;
        position: relative;
        top: 4px;

        p {
            color: #acb9cf;
            font-size: 10px;
        }
    }
}

.dialog-window__doc-label {
    color: #73758c;
    font-size: 14px;
    background: #f3f5f8;
    border: 1px solid #acb9cf;
    margin-top: 5px;
    text-align: center;
    padding: 10px 0;
    border-radius: 10px;
    cursor: pointer;

    &.doc-signed {
        background: #e7ebf2;
        padding: 5px;
        color: #515d75;
        font-size: 12px;
        border-radius: 0;
        border: solid 1px #e7ebf2;
        cursor: auto;
    }
}
</style>
