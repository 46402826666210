import { render, staticRenderFns } from "./ExtendedConfirmationInsuredModal.vue?vue&type=template&id=0423f802&scoped=true"
import script from "./ExtendedConfirmationInsuredModal.vue?vue&type=script&lang=js"
export * from "./ExtendedConfirmationInsuredModal.vue?vue&type=script&lang=js"
import style0 from "./ExtendedConfirmationInsuredModal.vue?vue&type=style&index=0&id=0423f802&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0423f802",
  null
  
)

export default component.exports