<template>
  <div>
    <b-modal :modal-class = "['insurance-case-report-modal', 'hide-scroll-bar']"
             ref = "insurance-case-report-modal"
             id = "insurance-case-report-modal"
             @show = "fetchParams"
             @shown = "onShown"
             @hidden = "onHidden"
             centered
             hide-footer
             hide-header
             :data-modal-class = "[paddingForTabBar]"
             no-stacking>
      <div class = "close-modal" @click = "$bvModal.hide('insurance-case-report-modal')"></div>

      <div v-if = "loadingModal" class = "loader">
        <div class = "spinner-container">
          <b-spinner variant = "secondary" />
        </div>
      </div>
      <div v-else>
        <h3 class = "modal-title">{{ $t('Create request') }}</h3>

        <form @submit.prevent = "sendReport" action = "javascript:;">
          <div class = "application-2-content">
            <div class = "form-group">
              <label class = "label">{{
                  $t('Policy type')
                                     }}
                <span class = "red">*</span>
              </label>
              <multiselect v-model = "form.policyType"
                           :options = "params.policyTypes"
                           :multiple = "false"
                           :searchable = "false"
                           :disabled = "openedFromPolicy"
                           label = "name"
                           name = "policyType"
                           track-by = "name"
                           selectedLabel = ""
                           deselectLabel = ""
                           selectLabel = ""
                           :allow-empty = "false"
                           placeholder = "Выбрать"
                           :class = "{ error: errors.policyTypes }" />
            </div>

            <template v-if = "
                                form.policyType === '' ||
                                form.policyType.id === 'OGPO'
                            ">
              <div class = "form-group">
                <label class = "label">{{
                    $t('Your role in an insurance case')
                                       }}
                  <span class = "red">*</span>
                </label>
                <multiselect v-model = "form.clientRole"
                             :options = "params.clientRoles"
                             :multiple = "false"
                             :searchable = "false"
                             :loading = "loading"
                             label = "name"
                             track-by = "id"
                             selectedLabel = ""
                             deselectLabel = ""
                             selectLabel = ""
                             :allow-empty = "false"
                             placeholder = "Выбрать"
                             :class = "{ error: errors.clientRole }" />
              </div>
              <div class = "form-group">
                <label class = "label">{{
                    $t('Insurance case date')
                                       }}
                  <span class = "red">*</span>
                </label>
                <flat-pickr v-model = "form.accidentDate"
                            :config = "config"
                            class = "input picker bg-picker-icon"
                            :class = "{ error: errors.accidentDate }"
                            :placeholder = "$t('Select date')" />
              </div>
              <div class = "form-group">
                <label for = "iinBinCulprit" class = "label">{{
                    $t('IIN/BIN of culprit')
                                                             }}
                  <span class = "red">*</span>
                </label>
                <the-mask v-model = "form.mainParticipantIin" class = "input" :class = "{
                                        error: errors.mainParticipantIin,
                                    }" :mask = "['############']" id = "iinBinCulprit" />
              </div>
              <div class = "form-group">
                <label class = "label">{{
                    $t('type of affected object')
                                       }}
                  <span class = "red">*</span>
                </label>
                <multiselect v-model = "form.objectType"
                             :options = "params.objectTypes"
                             :multiple = "false"
                             :searchable = "false"
                             :loading = "loading"
                             label = "name"
                             track-by = "id"
                             selectedLabel = ""
                             deselectLabel = ""
                             selectLabel = ""
                             :allow-empty = "false"
                             placeholder = "Выбрать"
                             :class = "{ error: errors.objectType }" />
              </div>
              <div class = "form-group" v-if = "showTfFields">
                <label for = "mainTfNumber" class = "label">{{
                    $t('reg number of culprit')
                                                            }}
                  <span class = "red">*</span>
                </label>
                <input v-model = "form.mainTfNumber"
                       id = "mainTfNumber"
                       type = "text"
                       class = "input"
                       :class = "{ error: errors.mainTfNumber }" />
              </div>
              <div class = "form-group" v-if = "showTfFields">
                <label for = "victimTfNumber" class = "label">{{
                    $t('reg number of affected vehicle')
                                                              }}
                  <span class = "red">*</span>
                </label>
                <input v-model = "form.victimTfNumber"
                       id = "victimTfNumber"
                       type = "text"
                       class = "input"
                       :class = "{ error: errors.victimTfNumber }" />
              </div>
              <div class = "form-group" v-if = "showVictimClientFields">
                <label for = "iinBinVictim" class = "label">{{
                    $t('Victim IIN/BIN')
                                                            }}
                  <span class = "red">*</span>
                </label>
                <the-mask v-model = "form.victimClientIin"
                          class = "input"
                          :class = "{ error: errors.victimClientIin }"
                          :mask = "['############']"
                          id = "iinBinVictim" />
              </div>
              <div class = "form-group" v-if = "showPropertyFields">
                <label class = "label">{{
                    $t('Property')
                                       }}
                  <span class = "red">*</span>
                </label>
                <multiselect v-model = "form.propertyType"
                             :options = "params.propertyTypes"
                             :multiple = "false"
                             :searchable = "false"
                             :loading = "loading"
                             label = "name"
                             track-by = "id"
                             selectedLabel = ""
                             deselectLabel = ""
                             selectLabel = ""
                             :allow-empty = "false"
                             placeholder = "Выбрать"
                             :class = "{ error: errors.propertyType }" />
              </div>
              <div class = "form-group">
                <label for = "beneficiaryIin" class = "label">{{
                    $t('IIN/BIN of beneficiary')
                                                              }}
                  <span class = "red">*</span>
                </label>
                <the-mask v-model = "form.beneficiaryIin"
                          class = "input"
                          :class = "{ error: errors.beneficiaryIin }"
                          :mask = "['############']"
                          id = "beneficiaryIin" />
              </div>
              <div class = "form-group">
                <label class = "label">{{
                    $t('Region suitable for contact')
                                       }}
                  <span class = "red">*</span>
                </label>
                <multiselect v-model = "form.city"
                             :options = "params.cities"
                             :multiple = "false"
                             :searchable = "false"
                             :loading = "loading"
                             label = "name"
                             track-by = "id"
                             selectedLabel = ""
                             deselectLabel = ""
                             selectLabel = ""
                             :allow-empty = "false"
                             placeholder = "Выбрать"
                             :class = "{ error: errors.city }" />
              </div>
              <div class = "form-group">
                <label class = "label">{{ $t('Address') }}</label>
                <input v-model = "form.address" type = "text" class = "input" />
              </div>
              <div class = "form-group file-block">
                <label class = "label">{{
                    $t('Doc of GAI')
                                       }}
                  <span class = "red">*</span>
                </label>
                <span v-if = "docErrorText" class = "label red">{{
                    docErrorText
                                                                }}</span>
                <CustomInputFile v-for = "n in params.filesCount"
                                 :key = "n"
                                 v-model = "form.documents[n - 1]"
                                 :error = "
                                        errors[`documents.${n - 1}`] !==
                                        undefined
                                            ? errors[`documents.${n - 1}`]
                                            : ''
                                    "
                                 :docName = "`${$t(params.filesNamesArr[n - 1])} ${(n - 4) > 0 ? (n - 4) : n}`"
                                 :required = "n <= params.requiredFilesCount"
                                 @input = "handleUpload(n)"
                                 @deleteFromDocs = "deleteFromDocs"
                                 @binDeleteFromDocs = "binDeleteFromDocs"
                                 accept = ".doc, .docx, .xlsx, .xls, .pdf, .png, .jpg, .jpeg"
                                 :orderOfDoc = "n" />
              </div>
              <span v-if = "requestErrorText" class = "label red">{{
                  requestErrorText
                                                                  }}</span>
              <AppButton :title = "$t('SEND')"
                         :loading = "sending"
                         class = "w-202 fs-14 h-38 mt-25"></AppButton>
            </template>

            <template v-else>
              <div class = "form-group">
                <label class = "label">{{
                    $t('DESCRIPTION_OF_THE_INSURED_EVENT')
                                       }}
                  <span class = "red">*</span>
                </label>
                <textarea v-model = "form.description"
                          type = "text"
                          class = "input textarea"
                          :class = "{ error: errors.description }" />
              </div>
              <div class = "form-group">
                <label class = "label">{{
                    $t('DATE_OF_THE_INSURED_EVENT')
                                       }}
                  <span class = "red">*</span>
                </label>
                <flat-pickr v-model = "form.accidentDate"
                            :config = "config"
                            class = "input picker bg-picker-icon"
                            :class = "{ error: errors.accidentDate }"
                            :placeholder = "$t('Select date')" />
              </div>
              <span v-if = "requestErrorText" class = "label red">{{
                  requestErrorText
                                                                  }}</span>
              <AppButton title = "Отправить"
                         :loading = "sending"
                         class = "w-202 fs-14 h-38 mt-25"></AppButton>
            </template>
          </div>
        </form>
      </div>
    </b-modal>

    <InsuranceCaseReportSuccessModal id = "success-modal"
                                     :policy-name = "successModal.policyName"
                                     :insurance-date = "successModal.insuranceDate"></InsuranceCaseReportSuccessModal>
  </div>
</template>

<script>
import CustomInputFile from '@/components/CustomInputFile'

import { TheMask } from 'vue-the-mask'
import AppButton from '@/components/AppButton'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { Kazakh } from 'flatpickr/dist/l10n/kz.js'
import { insuranceCaseReportModalMixin } from '@/mixins/insuranceCaseReportModalMixin'
import InsuranceCaseReportSuccessModal from './InsuranceCaseReportSuccessModal.vue'
import { mapActions } from 'vuex'

export default {
  name: 'InsuranceCaseReportModal',
  mixins: [insuranceCaseReportModalMixin],
  data () {
    const requiredFilesCount = 3
    return {
      loading: false,
      loadingModal: false,
      sending: false,
      successModal: {
        policyName: '',
        insuranceDate: '',
      },
      // @deprecated
      // form: {
      //     policyType: "",
      //     clientRole: "",
      //     accidentDate: new Date().getDate()+'.'+(+new Date().getMonth()+1)+'.'+new Date().getFullYear(),
      //     description: "",
      //     mainParticipantIin: "",
      //     propertyType: "",
      //     victimClientIin: "",
      //     beneficiaryIin: "",
      //     mainTfNumber: "",
      //     victimTfNumber: "",
      //     objectType: "",
      //     city: "",
      //     address: "",
      //     documents: Array(requiredFilesCount).fill(""),
      // },
      params: {
        filesCount: 6,
        filesNamesArr: ['Document', 'Document', 'Document', 'Document', 'Photo DTP', 'Photo DTP'],
        requiredFilesCount: requiredFilesCount,
        policyTypes: [
          {
            id: 'OGPO',
            name: 'Авто',
          },
          {
            id: 'KASKO_DTP',
            name: 'Каско',
          },
          {
            id: 'DSGPO',
            name: 'ДСПГО Авто',
          },
          {
            id: 'MY_CAR',
            name: 'My car',
          },
          {
            id: 'MST',
            name: 'Туристу',
          },
          {
            id: 'IMUSHESTVO',
            name: 'Квартира',
          },
          {
            id: 'ONT',
            name: 'Нотариус',
          },
          {
            id: 'COMFORT',
            name: 'Дом/Квартира',
          },
          {
            id: 'ADVOKAT',
            name: 'Адвокат',
          },
          {
            id: 'CHSI',
            name: 'Судоисполнитель',
          },
          {
            id: 'ACCIDENT',
            name: 'Несчастный случай',
          },
          {
            id: 'ACCIDENT_SPORT',
            name: 'Несчастный случай (спорт)',
          },
          {
            id: 'OPP',
            name: 'Ответственность перевозчика перед пассажирами',
          }
        ],
        clientRoles: [],
        objectTypes: [],
        propertyTypes: [],
        cities: [],
        locale: '',
      },
      errors: {},
      docErrorText: '',
      requestErrorText: '',
      config: {
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'd.m.Y',
        altInput: true,
        dateFormat: 'd.m.Y',
        maxDate: new Date(),
        position: 'auto right',
        static: true,
        shorthandCurrentMonth: true,
        locale:
          this.$store.getters['i18n/locale'] === 'ru'
            ? Russian
            : Kazakh,
        disableMobile: true,
      },
      paddingForTabBar: '',
    }
  },
  props: {
    policy: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    CustomInputFile,
    TheMask,
    AppButton,
    Multiselect,
    flatPickr,
    InsuranceCaseReportSuccessModal,
  },
  methods: {
    ...mapActions({
      setAlertText: 'modalOptions/setAlertText',
    }),
    fetchParams () {
      if (
        this.params.cities.length > 0 &&
        this.params.locale === this.$i18n.locale
      )
        return
      this.loadingModal = true
      let locale = this.$i18n.locale
      this.$axios
      .get('/insurance-cases/params/get', {
        params: { locale: locale },
      })
      .then((response) => {
        this.params.cities = response.data.cities
        this.params.clientRoles = response.data.clientRoles
        this.params.objectTypes = response.data.objectTypes
        this.params.propertyTypes = response.data.propertyTypes
        this.params.locale = locale

        this.form.policyType = this.getPolicyType()
        this.form.clientRole = this.params.clientRoles[0]
        this.form.objectType = this.params.objectTypes[0]
        this.form.city = this.params.cities[0]
      })
      .finally(() => {
        this.loadingModal = false
      })
    },
    async sendReport () {
      this.errors = {}
      this.requestErrorText = ''
      let formData = this._createFormData(this.form)
      this.requestErrorText = ''
      const policyType = formData.get('policyType')
      if (!policyType) return
      // validate
        if (policyType) {
            if (policyType === 'OGPO') {
                let i = 0;
                let recheck = false;
                while (i < this.params.filesCount) {
                    recheck = false;

                    if (
                        formData.has(`documents[${i}]`)
                        && formData.get(`documents[${i}]`)
                        && formData.get(`documents[${i}]`) !== ""
                        && !(formData.get(`documents[${i}]`) instanceof File)
                    ) {
                        this.binDeleteFromDocs(i+1);
                        if (i >= this.params.requiredFilesCount) {
                            recheck = true;
                            formData = this._createFormData(this.form)
                        }
                    }
                    if (!recheck) {
                        i++;
                    }
                }

                // wrong inputs cleared and need refill to check required files
                formData = this._createFormData(this.form)

                for (let i = 0; i < this.params.requiredFilesCount; i++) {
                    if (!formData.has(`documents[${i}]`) || !formData.get(`documents[${i}]`)) {
                        this.errors[`documents.${i}`] = this.$t('Docs is required');
                    }
                }
            } else {
                if (formData.get('accidentDate') === 'null') {
                    this.errors.accidentDate = true
                }
                if (formData.get('description') === '') {
                    this.errors.description = true
                }
            }
            if (Object.keys(this.errors).length > 0) {
                return
            }
        }

      this.sending = true
      this.loading = true

      const globalId = this.policy.global_id
      if (policyType) {
        let payload = {
          policy_type: policyType,
          global_id: globalId,
          message: formData.get('description'),
          ins_event_date: formData.get('accidentDate'),
        };

        if (policyType === 'OGPO') {
          payload.documents = this.form.documents;

          payload.ogpo_data = this.formatOgpoReportFormToJson(this.form);
          payload.message = 'ogpo';
        }

        const chatFormData = this._createFormData(payload);
        const chatId = await this.$store.dispatch('chat/startChat', chatFormData)
        this.sending = false
        this.loading = false
        if (chatId) {
          await this.$store.dispatch('chat/insuranceCaseReportModalStore/resetForm')
          // @deprecated
          // this.resetForm()
        }
        /* @deprecated
        await this.$store.dispatch("chat/sendMessage", {
            chatId: chatId,
            message: new Message({
                text: this.form.description,
                sentAt: new Date(),
            }),
        });*/
        await this.$store.dispatch('chat/selectChat', {id:chatId,showModal:false})
        let policyName = ''
        this.params.policyTypes.forEach(elem => {
          if (elem.id === policyType) {
            policyName = elem.name
          }
        })
        // set policyName
        this.successModal.policyName = policyName
        this.successModal.insuranceDate = formData.get('accidentDate')
        this.sending = false
        this.loading = false
        this.$bvModal.show('success-modal')
      }


    },
    handleUpload (n) {
      delete this.errors[`documents.${n - 1}`]
      this.setDocErrorText()
    },
    /* @deprecated
    resetForm () {
      this.form.policyType = this.params.policyTypes[0]
      this.form.clientRole = this.params.clientRoles[0]
      this.form.objectType = this.params.objectTypes[0]
      this.form.propertyType = ''
      this.form.victimClientIin = ''
      this.form.city = this.params.cities[0]
      this.form.accidentDate = new Date().getDate() + '.' + (+new Date().getMonth() + 1) + '.' + new Date().getFullYear()
      this.form.mainParticipantIin = ''
      this.form.beneficiaryIin = ''
      this.form.mainTfNumber = ''
      this.form.victimTfNumber = ''
      this.form.address = ''
      this.form.description = ''
      this.form.documents = Array(this.params.requiredFilesCount).fill(
        ''
      )
      this.requestErrorText = ''
    },*/
    deleteFromDocs (n) {
      if (n <= this.params.requiredFilesCount) return
      this.form.documents.splice(n - 1, 1)
    },
    binDeleteFromDocs (n) {
      if (n <= this.params.requiredFilesCount) {
        this.$set(this.form.documents, n - 1, '')
        return
      }
      this.form.documents.splice(n - 1, 1)
    },
    setDocErrorText () {
      let text = ''
      for (let i = 0; i < this.params.filesCount; i++) {
        if (typeof this.errors[`documents.${i}`] !== 'undefined') {
          text = this.errors[`documents.${i}`]
        }
      }
      this.docErrorText = text
    },
    onShown () {
      /*eslint-disable*/
      this.form.policyType = this.getPolicyType()

      if (this.mobileApp.isIosWV()) {
        $('#insurance-case-report-modal').animate(
          { scrollTop: 0 },
          200
        )
      }
      /*eslint-enable*/
    },
    onHidden () {
      this.$emit('resetInsuranceCase')
    },
    setCurrentDate () {
      const date = new Date()
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())

      this.form.accidentDate = `${day}.${month}.${year}`
    },
    _policyNameByType (policyType) {
      const policy = this.params.policyTypes.find(
        (policy) => policy.id === policyType
      )
      return policy && policy.name
    },
    formatOgpoReportFormToJson(formData) {
      const fieldMappings = {
        policyType: this.$t('Policy type'),
        clientRole: this.$t('Your role in an insurance case'),
        accidentDate: this.$t('Insurance case date'),
        mainParticipantIin: this.$t('IIN/BIN of culprit'),
        objectType: this.$t('type of affected object'),
        mainTfNumber: this.$t('Reg number of culprit'),
        victimTfNumber: this.$t('reg number of affected vehicle'),
        victimClientIin: this.$t('Victim IIN/BIN'),
        propertyType: this.$t('Property'),
        beneficiaryIin: this.$t('IIN/BIN of beneficiary'),
        city: this.$t('Region suitable for contact'),
        address: this.$t('Address'),
      };

      let fields = [];
      for (const key in formData) {
        if (Object.hasOwn(formData, key)) {
          const fieldName = fieldMappings[key];
          let fieldValue = '';
          if (typeof formData[key] === 'object') {
            fieldValue = formData[key].name;
          } else {
            fieldValue = formData[key];
          }
          if (fieldName && fieldValue) {
            fields.push({
              title: fieldName.trim(),
              value: fieldValue.trim()
            });
          }
        }
      }
      return JSON.stringify(fields);
    },
    _sendOgpoPolicyReport (formData) {
      this.$axios
      .post('/insurance-cases/report/send', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (
          typeof response.data.data.errorMsg !== 'undefined' &&
          response.data.data.errorMsg === ''
        ) {
          this.$bvModal.hide('insurance-case-report-modal')
          this.setAlertText(
            this.$t('Request has been successfully sent!')
          )
          this.$bvModal.show('alert-info-text')
          this.resetForm()
          this.errors = []
          this.resetInsuranceAffairs()
          if (this.mobileApp.isWebView()) {
            window.mobileApp.isAffairsLoaded = false
          }

          // TODO: Log on the server
          this.$axios.post('/insurance-cases/report/send/log')
        }
      })
      .catch((error) => {
        if (error.response.data.errors)
          this.errors = error.response.data.errors
        if (error.response.data.message)
          this.requestErrorText = error.response.data.message
      })
      .finally(() => {
        this.sending = false
        this.loading = false
      })
    },
    _createFormData (form) {
      const formData = new FormData()
      for (const key in form) {
        const value = form[key]
        if (Array.isArray(value)) {
          value.forEach((v, i) => {
            formData.append(`${key}[${i}]`, v)
          })
        } else {
          formData.append(
            key,
            value !== null && typeof value === 'object'
              ? value.id
              : value
          )
        }
      }
      return formData
    },
    getPolicyType () {
        console.log('getPolicyType')
      if (this.openedFromPolicy) {
        for (let productIdsKey in this.constants.productIds) {
          if (+this.constants.productIds[productIdsKey] === +this.policy.product_id) {
            let productTypeKey = productIdsKey.toUpperCase()
            for (const index in this.params.policyTypes) {
              if (this.params.policyTypes[index].id === productTypeKey) {
                  console.log(this.params.policyTypes[index])
                  console.log(this.params.policyTypes)
                return this.params.policyTypes[index]
              }
            }
            break
          }
        }
      }
      if (this.form.policyType) {
        return this.form.policyType
      }
      return this.params.policyTypes[0]
    },
  },
  computed: {
    form: {
      get () {
        return this.$store.getters['chat/insuranceCaseReportModalStore/form']
      },
      set (newValue) {
        this.$store.dispatch('chat/insuranceCaseReportModalStore/setForm', newValue)
      },
    },
    showTfFields () {
      return parseInt(this.form.objectType.id) === 1
    },
    showVictimClientFields () {
      return parseInt(this.form.objectType.id) === 2
    },
    showPropertyFields () {
      return parseInt(this.form.objectType.id) === 3
    },
    addPaddingForMobileApp () {
      return this.mobileApp.isWebView()
    },
    openedFromPolicy () {
      return Object.values(this.policy).length > 0
    },
  },
  watch: {
    errors () {
      this.setDocErrorText()
    },
    'form.objectType' (value) {
      switch (parseInt(value.id)) {
        case 1: {
          // 1 - транспорное средство
          this.form.victimClientIin = ''
          this.form.propertyType = ''
          break
        }
        case 2: {
          // 2 - Вред здоровью
          this.form.propertyType = ''
          this.form.mainTfNumber = ''
          this.form.victimTfNumber = ''
          break
        }
        case 3: {
          // 3 - Иное имущество
          this.form.victimClientIin = ''
          this.form.mainTfNumber = ''
          this.form.victimTfNumber = ''
          break
        }
      }
    },
  },
}
</script>

<style lang = "scss">
.insurance-case-report-modal {
    @media (max-width: 991px) {
        padding-top: calc(50px + env(safe-area-inset-top));
        padding-bottom: calc(50px + env(safe-area-inset-top));
    }

    .bg-picker-icon {
        cursor: pointer;
    }

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.7);
        color: rgba(255, 255, 255, 0.7);
        border-radius: 11px;

        .spinner-container {
            transform: translateY(10px);
        }
    }

    .multiselect--disabled {
        background: transparent;

        .multiselect__select {
            background: transparent;
        }
    }

    .multiselect__spinner {
        right: 14px;
        width: 18px;
        height: 28px;
    }

    .modal-title {
        margin-bottom: 35px;
        font-weight: 600;
        font-size: 20px;
        line-height: 127.1%;
        color: #000000;
    }

    .application-2-content {
        display: flex;
        flex-direction: column;
    }

    .form-group + .form-group {
        margin-top: 18px;
    }

    .modal-content {
        position: relative;
        border-radius: 11px;
    }

    .close-modal {
        position: absolute;
        content: "";
        top: 28px;
        right: 27px;
        width: 34px;
        height: 34px;
        background: url("~@/assets/images/x-modal.svg") center/ 20px 20px no-repeat;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.2s;

        &:hover {
            background-color: #f3f5f9;
        }
    }

    .modal-body {
        min-height: 82px;
        padding: 30px 35px;
    }

    .close-tab {
        margin-left: 10px;
        width: 8px;
        height: 8px;
        background: url("~@/assets/images/x-tab.svg") center / auto no-repeat;
    }

    .input {
        padding: 0 12px;
        width: 100%;
        height: 30px;
        border: 1px solid #d0d1e2;
        border-radius: 30px;
        font-weight: 400;
        font-size: 12px;
        transition: border-color 0.2s;

        &.error {
            border: 1px solid #ef2222;
        }
    }

    .label {
        margin-bottom: 5px;
        display: block;
        width: fit-content;
        font-weight: 400;
        font-size: 12px;
        line-height: 127.1%;
        color: #000000;

        &.red {
            color: red;
            margin-top: 20px;
        }
    }

    .input.textarea {
        height: 94px;
        border-radius: 10px;
        resize: none;
        padding: 6px 12px;
    }

    .form-row-50 {
        margin: 0 -5px;
        grid-area: 6 / 1 / 7 / 2;
        display: flex;

        & > .form-group {
            padding: 0 5px;
            flex: 1 0 50%;
        }
    }

    .form-group-radio {
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .radio-label {
        display: flex;
        position: relative;
        align-items: center;
    }

    .radio-input {
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
    }

    .radio-style {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 19px;
        height: 19px;
        background-color: inherit;
        border-radius: 50%;
        border: 1px solid #d0d1e2;
        cursor: pointer;
    }

    .radio-style::after {
        content: "";
        width: 13px;
        height: 13px;
        background-color: $orange;
        border-radius: 50%;
        opacity: 0;
    }

    .radio-input:checked + .radio-style::after {
        opacity: 1;
    }

    .radio-text {
        margin-left: 6px;
        font-weight: 400;
        font-size: 12px;
        line-height: 127.1%;
    }

    .radio-wrapper + .radio-wrapper {
        margin-left: 26px;
    }

    .tab-other {
        margin-bottom: 67px;

        .title {
            margin-top: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 127.1%;
            color: #000000;
        }
    }

    .tab-other-form {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 381px);
        grid-template-rows: repeat(3, 50px) 114px;
        grid-column-gap: 64px;
        grid-row-gap: 18px;

        & > .form-group {
            &:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
            }

            &:nth-child(2) {
                grid-area: 2 / 1 / 3 / 2;
            }

            &:nth-child(3) {
                grid-area: 3 / 1 / 4 / 2;
            }

            &:nth-child(4) {
                grid-area: 4 / 1 / 5 / 2;
            }

            &:nth-child(5) {
                grid-area: 1 / 2 / 2 / 3;
            }

            &:nth-child(6) {
                grid-area: 2 / 2 / 3 / 3;
            }

            &:nth-child(7) {
                grid-area: 3 / 2 / 4 / 3;
            }

            &:nth-child(8) {
                grid-area: 4 / 2 / 5 / 3;
            }
        }
    }

    .form-group-m + .form-group-m {
        margin-left: 37px;
    }

    .form-group {
        .red {
            color: #dd0000;
        }
    }

    .doc__item {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    .file-block {
        label {
            width: 100%;
        }
    }

    .error + .picker {
        border: 1px solid #ef2222;
    }

    .error {
        .multiselect__tags {
            border: 1px solid #ef2222;
        }
    }
}

@media (max-width: 991px) {
    .insurance-case-report-modal {
        .label {
            font-size: calc(12px + 8 * ((100vw - 320px) / (768 - 320)));
        }

        .modal-body {
            padding: 30px 15px;

            .close-modal {
                top: 27px;
                right: 5px;
                background-size: 16px 16px;
            }

            button {
                width: 100%;
            }
        }

        .modal-title {
            margin-bottom: 21px;
            font-weight: 600;
            font-size: calc(20px + 15 * ((100vw - 320px) / (768 - 320)));
            line-height: 127.1%;
            color: #000000;
        }

        button {
            margin-top: 18px;
        }

        .application-2-content {
            .file-block {
                margin-top: 10px;
            }
        }

        .close-modal {
            right: 7px;
        }

        .multiselect .multiselect__input,
        .multiselect .multiselect__single {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 93%;
        }

        .multiselect__content-wrapper {
            overflow: hidden;
        }

        .multiselect__content {
            width: 100%;
        }

        .multiselect__option {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .success-modal {
        .close-modal {
            right: 8px;
        }

        .modal-body {
            padding: 30px 15px 30px 15px;
        }
    }
}

[data-modal-class="padding-for-tab-bar"] {
    #insurance-case-report-modal {
        padding-bottom: calc(100px + env(safe-area-inset-bottom));
        padding-top: calc(100px + env(safe-area-inset-bottom));
    }
}

.hide-scroll-bar {
    &::-webkit-scrollbar {
        width: 0;
    }

    @-moz-document url-prefix() {
        // Cтили только для Mozilla Firefox
        scrollbar-width: none;
    }
}
</style>
