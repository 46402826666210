<template>
  <div class="calc-module-210222">
    <section class="base-calc ogpo-calc" data-calc="OGPO">
      <div class="container">
        <div id="pre-loader">
          <b-spinner/>
        </div>
        <div class="calculator-description-box">
          <div class="calculator-description-header">
            <h2 class="calculator-title">{{ $t('CALCULATOR_INFO.OGPO.TITLE') }}</h2>
            <span :class="['calculator-description-arrow', {'is-closed': isMobile}]"></span>
          </div>
          <div :class="['calculator-description-body', {'is-closed': isMobile}, {'mob-show-scroll': isMobile}]">
            <p class="calculator-description-text" v-html="$t('CALCULATOR_INFO.OGPO.DESCRIPTION')"></p>
          </div>
        </div>
        <h1 class="ogpo-calc__main-title">{{ calculatorName() }}
            <template v-if="isReset">
                ({{ $t('RE_REGISTRATION') }})
            </template>
        </h1>
        <div class="ogpo-content">
          <ExtendedConfirmationModal/>
          <ExtendedConfirmationInsuredModal/>
          <ExtendedConfirmationInsuredListModal/>
          <ExtendedConfirmationWarningModal/>
          <FullScreenPreloader :loading="loading"/>
          <!--step 1 - agreement with data collection and processing-->
          <div class="rights-blocker iin-collect" data-rights-blocker>
            <div class="popup">
              <div class="loader-container" data-loader>
                <div class="loader"></div>
              </div>
              <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
              <p class="popup-text" v-html="$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
              <label class="ogpo-calc__label step1 agreement-container">
                <p class="step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                <input data-field="iin_for_agree" name="iin_for_agree" type="text"
                       class="iin-for-agree imask-iin">
                <span data-error="iin-collect-error-field" class="iin-collect-error-field"></span>
              </label>
              <button class="iin-collect__modal-button mob-right" data-btn="data_collection_confirm">
                {{ $t('I_CONFIRM') }}
              </button>
            </div>
          </div>

          <!--step 2 - agreement with rules of policy-->
          <div class="rights-blocker" data-personal-blocker style="display: none">
            <div class="popup">
              <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
              <p class="popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>

              <label class="ogpo-calc__label step2">
                <input data-field="confirm" name="confirm" type="checkbox" value="true">
                <span class="ogpo-calc__checkBox"></span>
                <div v-html="$t('PROPERTY_RIGHTS_OK')"></div>
              </label>
            </div>
          </div>

          <div class="ogpo-calc__nav">
            <div class="ul">
              <div class="ogpo-calc__line ogpo-calc__line-1 active" data-step-tab="1">
                <div class="ogpo-calc__nav-link link-step-1">1</div>
                <div class="line"></div>
              </div>

              <div class="ogpo-calc__line ogpo-calc__line-2" data-step-tab="2">
                <div class="ogpo-calc__nav-link link-step-2">2</div>
                <div class="line"></div>
              </div>
              <div class="ogpo-calc__line ogpo-calc__line-3" data-step-tab="3">
                <div class="ogpo-calc__nav-link link-step-3">3</div>
                <div class="line"></div>
              </div>
              <div class="ogpo-calc__line ogpo-calc__line-4"></div>
            </div>
          </div>
          <div class="step-1 step" data-step-cont="1">
            <div>
              <form class="radio-wrapper" action="javascript:;" data-form="first" method="post" style="display: none">
                <div class="ogpo-calc__radio">
                  <div class="radio-block">
                    <input class="ogpo-calc__radio-input" id="radio-1" type="radio" name="type" value="base" checked
                           data-calc-type="base" data-linked=type>
                    <label for="radio-1" class="ogpo-calc__radio-label">{{ $t('STD') }}</label>
                  </div>
                  <div class="radio-block">
                    <input class="ogpo-calc__radio-input" id="radio-2" type="radio" name="type" value="temporary_entry"
                           data-calc-type="temporary_entry" data-linked=type>
                    <label for="radio-2" class="ogpo-calc__radio-label">{{ $t('OGPO_TIME') }}</label>
                  </div>
                  <div class="radio-block">
                    <input class="ogpo-calc__radio-input" id="radio-3" type="radio" name="type"
                           value="temporary_registration" data-calc-type="temporary_registration" data-linked=type>
                    <label for="radio-3" class="ogpo-calc__radio-label">{{ $t('OGPO_TIMEREG') }}</label>
                  </div>
                </div>
              </form>
              <div class="step-1__wrapper">
                <form class="ogpo-calc__block-top-box" action="javascript:;" data-form="first" method="post">
                  <div class="ogpo-calc__block-checkbox main-confirm-step1">
                    <label class="ogpo-calc__label">
                      <input required class="ogpo-calc__input" type="checkbox" data-linked="confirm"
                             data-field="confirm" value="true">
                      <span class="ogpo-calc__checkBox"></span>
                      <div v-html="$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                    </label>
                  </div>
                  <div class="ogpo-calc__block-top" :style="[isReset ? {'alignItems': 'flex-start'} : {}]">
                    <div class="ogpo-calc__left dir-col">
                      <div class="ogpo-calc__date">
                        <h4 class="ogpo-calc__title input__title">{{ $t('INS_DATE') }}</h4>
                        <div class="datepicker-wrapper">
                          <input class="input imask-date"
                                 :class="{'ogpo-calc__datepicker datepicker-ins-date' : !isReset}"
                                 type="text" data-linked="ins_date" :value="beginDate"
                                 :disabled="isReset" :title="isReset ?
                                                           'Дата заключения должна быть сегодняшней и запрещена для редактирования'
                                                           :
                                                           'Выбрать дату начала полиса'"
                                 :placeholder="$t('DMY')"
                          >
                        </div>
                      </div>
                      <div class="ogpo-calc__srok">
                        <h4 class=" ogpo-calc__title input__title">{{ $t('TERM_OF_INSURANCE') }}</h4>
                        <select class="js-example-basic-single input select2" name="ins_period" data-linked="ins_period"
                                data-dun-select="ins_period">
                          <option value="AL">12 месяцев</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="ogpo-calc__block-mob">
                  <!--form add driver-->
                  <div data-add-insured="OGPO">
                    <form class="ogpo-calc__person" data-form="add_insured">
                      <input type="hidden" value="true" data-linked="is_insurant" data-field='is_insurant'>
                      <input type="hidden" name="is_reset2" :value="isReset" data-linked-step2="is_reset2">
                      <div class="ogpo-calc__flex">
                        <div class="ogpo-calc__person-left">
                          <div class="ogpo-calc__custom-flex-wrapper input__title">
                            <h4 class="">{{ $t('IIN') }}</h4>
                            <h4 class="ogpo-calc__person-name-title"
                                data-text="is_insurant">{{ $t('I_INSURANT') }}</h4>
                          </div>

                          <input class="input-iin input-iin-1 imask-iin" type="text" data-linked="iin" data-field="iin">
                          <label class="ogpo-calc__label_exp nowrap hide" data-field='select_experience'>
                            {{ $t('DRIVING_EXPERIENCE') }}<br/>
                            <select name="experience" data-linked="experience" data-dun-select="experience"
                                    class="js-example-basic-single input select2">
                              <option value=""></option>
                            </select>
                          </label>
                        </div>
                        <div class="ogpo-calc__person-right">
                          <div class="ogpo-calc__person-info person-info-iin" data-result="success">
                            <p class="ogpo-calc__person-name position" data-result="name"></p>
                            <p class="ogpo-calc__person-class" data-result="class" :data-mask="'%num%'+ $t('CLASS')">1
                                                                                                                     {{ $t('CLASS') }}</p>
                          </div>
                          <p class="ogpo-calc__validation-error" data-result="error">{{ $t('ERROR_DATA') }}</p>
                        </div>
                      </div>
                      <div class="ogpo-calc__flex" id="born-container" style="display: none;">
                        <div class="ogpo-calc__person-left">
                          <div class="ogpo-calc__date">
                            <h4 class="input__title">
                              {{ $t('DTP_BORN_LABEL') }}</h4>
                            <div class="datepicker-wrapper">
                              <input class="input datepicker-base imask-date" type="text" data-field="born"
                                     :placeholder="$t('DMY')">
                            </div>
                          </div>
                        </div>
                        <div class="ogpo-calc__person-right">
                          <p class="ogpo-calc__validation-error" data-born-error>{{ $t('ENTER_BORN_DATE') }}</p>
                        </div>
                      </div>
                      <!--active-->
                    </form>
                  </div>
                  <!--form add car-->
                  <div data-add-cars="OGPO">
                    <div class="ogpo-calc__car">

                      <div class="ogpo-calc__flex">
                        <h4 class="ogpo-calc__title input__title">{{ $t('CUR_NUM') }}</h4>
                        <div class="ogpo-calc__car-left">
                          <input class="input-carNum" type="text" data-linked="number" data-field='number'>
                        </div>
                        <div class="ogpo-calc__car-right">
                          <p class="ogpo-calc__car-info" data-result="success"></p>
                          <p class="ogpo-calc__validation-error" data-result="error">&nbsp;</p>
                          <p class="ogpo-calc__validation-error"
                             data-limit-error="limit_error">{{ $t('LIMIT_10_CAR') }}</p>
                        </div>
                      </div>
                      <div class="ogpo-calc__flex" >
                        <h4 class="ogpo-calc__title input__title">{{ $t('TECK_PASS_NUM') }}</h4>
                        <div data-v-0284ddcc="" class="ont__wrapper">
                          <input data-v-0284ddcc="" type="text" class="ont__input input-iin" data-linked="car_passport" data-field='car_passport'>
                          <div data-v-0284ddcc="" class="ont-calc__name">
                            <div data-v-0284ddcc="" data-result="placeholder" class="input-iin-placeholder" v-html="$t('TECH_PASS_HINT')"></div>
                            <p class="ogpo-calc__validation-error" data-result="passport-error">&nbsp;</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="ogpo-calc__block-bottom">

                  <div class="ogpo-calc__block-bottom-card" data-add-insured="OGPO">
                    <!--insured form-->
                    <div class="ogpo-calc__person des">
                      <form action="" data-form="add_insured">
                        <input type="hidden" data-linked="is_insurant" data-field='is_insurant' value="true">
                        <h4 class="ogpo-calc__title input__title">{{ $t('IIN') }}</h4>
                        <input type="hidden" name="is_reset2" :value="isReset" data-linked-step2="is_reset2">
                        <div class="ogpo-calc__flex">
                          <div class="ogpo-calc__person-left">
                            <!--input__validation-error-->
                            <input class="input-iin input-iin-1  imask-iin" type="text" data-linked="iin"
                                   data-field="iin">
                          </div>
                          <div class="ogpo-calc__person-right">
                            <h4 class="ogpo-calc__person-name-title" data-text="is_insurant">{{ $t('I_INSURANT') }}</h4>
                            <div class="ogpo-calc__person-info person-info-iin" data-result="success">
                              <p class="ogpo-calc__person-name position" data-result="name"></p>
                              <p class="ogpo-calc__person-class" data-result="class" :data-mask="'%num%'+$t('CLASS') ">1
                                                                                                                       {{ $t('CLASS') }}</p>
                            </div>
                            <p class="ogpo-calc__validation-error" data-result="error">
                              {{ $t('ERROR_DATA') }}</p>
                          </div>
                        </div>
                        <div id="born-container" class="ogpo-calc__flex custom-calc__flex"
                             style="display:none">
                          <h4 class="ogpo-calc__title input__title">{{
                              $t('DTP_BORN_LABEL')
                                                                    }}</h4>
                          <div class="custom-horizontal-wrapper">
                            <div class="ogpo-calc__person-left">
                              <div class="ogpo-calc__date custom-calc__date">
                                <div
                                    class="datepicker-wrapper custom__datepicker-wrapper">
                                  <input class="input datepicker-base imask-date" type="text" data-field="born"
                                         :placeholder="$t('DMY')">
                                </div>
                              </div>
                            </div>
                            <p class="ogpo-calc__validation-error" data-born-error>{{ $t('ENTER_BORN_DATE') }}</p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <!--car result-->
                  <div class="ogpo-calc__block-bottom-card bottom-card-grid" data-add-cars="OGPO">
                    <div class="ogpo-calc__car des">
                      <form action="" data-form="add_insured">
                        <div class="custom__horizontal-wrapper">
                          <h4 class="ogpo-calc__title input__title">{{ $t('CUR_NUM') }}</h4>
                          <div class="ogpo-calc__flex">
                            <div class="ogpo-calc__car-left">
                              <input class="input-carNum" type="text" data-linked="number" data-field='number'>
                            </div>
                            <div class="ogpo-calc__car-right">
                              <p class="ogpo-calc__car-info" data-result="success"></p>
                              <p class="ogpo-calc__validation-error" data-result="error">{{ $t('ERROR_DATA') }}</p>
                              <p class="ogpo-calc__validation-error" data-limit-error="limit_error">
                                {{ $t('LIMIT_10_CAR') }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="custom__horizontal-wrapper">
                          <h4 class="ogpo-calc__title input__title">{{$t('TECK_PASS_NUM')}}</h4>
                          <div data-v-0284ddcc="" class="ont__wrapper">
                            <input data-v-0284ddcc="" type="text"
                                   class="ont__input input-iin" data-linked="car_passport" data-field='car_passport'>
                            <div data-v-0284ddcc="" class="ont-calc__name">
                              <div data-v-0284ddcc="" data-result="placeholder" class="input-iin-placeholder" v-html="$t('TECH_PASS_HINT')"></div>
                              <p class="ogpo-calc__validation-error" data-result="passport-error">{{ $t('ERROR_DATA') }}</p>
                            </div>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                  <div class="ogpo-calc__block-bottom-card" data-add-insured="OGPO">
                    <!--insured result-->
                    <div data-list></div>
                  </div>
                  <div class="ogpo-calc__block-bottom-card bottom-card-grid data-list__grid-item"
                       data-add-cars="OGPO">
                    <!--car result-->
                    <div data-list></div>
                  </div>
                </div>
                <!--Button is here-->
                <div class="price-container">
                  <div v-show="isReset" class="ogpo-calc__price">
                    <div class="ogpo-calc__right" :style="[isReset ? {'alignItems': 'flex-start'} : {}]">
                      <div class="ogpo-calc__price">
                          <h4 class="ogpo-calc__price-title" data-calc-result="price_remain">
                              {{ $t('REMAIN_PREMIUM') }}: <span data-calc-result="price_remain_value" class="ogpo-calc__price-reset-value"></span></h4>
                          <h4 class="ogpo-calc__price-title" data-calc-result="price_new">
                              {{ $t('NEW_POLICY_COST') }}: <span data-calc-result="price_new_value" class="ogpo-calc__price-reset-value"></span></h4>
                      </div>
                    </div>
                    <br>
<!--                      @deprecated 11.03.2024-->
<!--                    <template v-if="isReset">
                        <div class="ogpo-calc__price-title">isReset: {{isReset}}</div>
                        <div class="ogpo-calc__price-title">globalId из полиса: {{policy.global_id}}</div>
                        <div class="ogpo-calc__price-title temporary-global-id"></div>
                    </template>-->
                  </div>
                  <div class="ogpo-calc__final-price-container">
                    <div>
                        <h4 v-if="isReset" class="ogpo-calc__price-title">{{ $t('DOPLATA') }}</h4>
                        <div v-if="isReset" class="ogpo-calc__price-value" data-calc-result="price"></div>
                        <h4 class="ogpo-calc__price-title" v-show="!isReset" v-html="$t('POLICY_COST_OGPO')"></h4>
                        <template v-if="!isReset">
                            <div class="ogpo-calc__price-value" data-calc-result="price"></div>
                        </template>
                        <CalculateLoader/>
                    </div>
                    <button class="ogpo-calc__button mob-right" data-btn="buy-step-1"
                            @click="setOthersDriversData" :aria-label="$t('OGPO_STEP1_BTN_TOOLTIP')"
                            v-if="canBuy"
                            data-cooltipz-dir="center">{{ $t('PAY_BTN_LINK') }}
                    </button>
                  </div>
                </div>

                <div class="ogpo__guarantee-fund-wrapper">
                  <div :class="['image-container',this.$store.getters['i18n/locale'] === 'kz'?'kz-logo':'']"></div>
                  <p class="guarantee-fund-text" v-html="$t('INSURANCE_PAYMENT_GUARANTEE')"></p>
                </div>


              </div>
            </div>
          </div>
          <div class="step-2 step" data-step-cont="2">
            <form method="post" action="javascript:;" data-form="second">
              <input type="hidden" name="notify_phone" value="true">
              <input type="hidden" name="is_reset" :value="isReset" data-linked-step2="is_reset">
              <input type="hidden" name="global_id" value="" data-linked-step2="global_id">
              <!--              <div class="calc-type">{{ $t('STD') }}</div>-->
              <div class="ogpo-calc__block-checkbox main-confirm-step1 main-confirm-step2">
                <label class="ogpo-calc__label">
                  <input required class="ogpo-calc__input" type="checkbox" data-linked-step2="confirm"
                         data-field="confirm">
                  <span class="ogpo-calc__checkBox"></span>
                  <div v-html="$t('CALC_RIGHT_S2_CONFIRM')"></div>
                </label>
              </div>
              <div class="step-2__form-wrapper">
                <form action="">
                  <h2 class="step-2__form-title">{{ $t('INSURANT_INFO') }}</h2>
                  <p class="step-2__form-name" data-insurant="name">Олег И</p>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('NAME') }}*</div>
                      <input class="step-2__input uppercase" type="text" required data-linked-step2="name"
                             data-main-user-iin>
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('SURNAME') }}*</div>
                      <input class="step-2__input uppercase" type="text" required data-linked-step2="surname"
                             data-main-user-iin>
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('IMUSHESTVO_UDO') }}*</div>
                      <input class="step-2__input imask-udo-num" type="text" required data-linked-step2="udo_num" data-main-user-iin>
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('IMUSHESTVO_UDO_DATE') }}*</div>
                      <input class="step-2__input datepicker-base imask-date" type="text" required
                             data-linked-step2="udo_date" data-main-user-iin
                             :placeholder="$t('DMY')">
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('HOW_UDO_AUTHOR') }}*</div>
                      <input class="step-2__input" type="text" required
                             data-linked-step2="udo_author">
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('REG_ADDRESS') }}*</div>
                      <input class="step-2__input" type="text" required
                             data-linked-step2="address">
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="horizontal-wrapper input__title">
                        <div>{{ $t('PHONE_NUMBER') }}*</div>
                        <div class="info-icon"
                             :aria-label="$t('VERIFICATION_PHONE_NUMBER_TOOLTIP')"
                             data-cooltipz-dir="bottom">
                          <div class="question-mark"></div>
                        </div>
                      </div>
                      <input class="step-2__input imask-phone" type="text" required data-linked-step2="phone">
                    </div>
                    <div class="step-2__col">
                      <div class="input__title">Email*</div>
                      <input class="step-2__input" type="email" required data-linked-step2="email" disabled>
                    </div>
                  </div>
                  <div class="step-2__row">
                    <div class="step-2__col">
                      <div class="input__title">{{ $t('DRIVING_EXPERIENCE') }}*</div>
                      <label class="w-100"
                             data-field='select_experience'>
                        <div class="ogpo-calc__block-experience-wrap w-100">
                          <select name="experience" data-linked="experience"
                                  data-dun-select="experience" class="w-100 select2"
                                  data-main-user-iin>
                          </select>
                        </div>
                      </label>
                    </div>
                  </div>

                  <!--print insureds fields-->
                  <div data-insured-step2></div>
                  <!--print insureds fields END-->

                  <div class="step-2__checkbox-wrapper">
                    <div class="step-2__checkbox">
                      <label class="ogpo-calc__label">
                        <input class="ogpo-calc__input" type="checkbox" data-linked-step2="notify_email">
                        <span class="ogpo-calc__checkBox"></span>
                        <div>{{ $t('GET_INFO_ON_EMAIL') }}</div>
                      </label>
                    </div>
                    <div class="step-2__checkbox">
                      <label class="ogpo-calc__label">
                        <input class="ogpo-calc__input" type="checkbox" data-linked-step2="prolong">
                        <span class="ogpo-calc__checkBox"></span>
                        <div>{{ $t('AUTO_PROLONG_WITH_DEFINITION_OGPO') }}</div>
                      </label>
                    </div>

                    <!--                    <div class="step-2__checkbox" data-fields="ipdl" >-->
                    <!--                      <div class="checkbox-prprt">-->
                    <!--                        <label class="ogpo-calc__label">-->
                    <!--                          <input class="ogpo-calc__input input-IPDL" type="checkbox"-->
                    <!--                                 data-linked-step2="is_ipdl" value="true">-->
                    <!--                          <span class="ogpo-calc__checkBox"></span>-->
                    <!--                          <div> {{ $t('I_AM_IPDL') }}</div>-->
                    <!--                        </label>-->
                    <!--                      </div>-->

                    <!--                      <div class="step-2__dop-polya" data-field-step2="ipdl_options">-->
                    <!--                        <div class="step-2__radio-wrapper">-->
                    <!--                          <label class="step-2__radio-label">-->
                    <!--                            <input class="step-2__radio-input" type="radio" name="IPDL"-->
                    <!--                                   value="Зарплата" checked data-linked-step2="ipdl_type">-->
                    <!--                            <span class="step-2__radio-style"></span>-->
                    <!--                            <div class="step-2__radio-text">{{ $t('SALARY') }}</div>-->
                    <!--                          </label>-->
                    <!--                        </div>-->
                    <!--                        <div class="step-2__radio-wrapper">-->
                    <!--                          <label class="step-2__radio-label">-->
                    <!--                            <input class="step-2__radio-input" type="radio" name="IPDL"-->
                    <!--                                   value="Доход от предпринимательской деятельности"-->
                    <!--                                   data-linked-step2="ipdl_type">-->
                    <!--                            <span class="step-2__radio-style"></span>-->
                    <!--                            <div class="step-2__radio-text">{{ $t('BUSINESS_INCOME') }}</div>-->
                    <!--                          </label>-->
                    <!--                        </div>-->
                    <!--                        <div class="step-2__radio-wrapper">-->
                    <!--                          <label class="step-2__radio-label">-->
                    <!--                            <input class="step-2__radio-input" type="radio" name="IPDL"-->
                    <!--                                   value="Дивиденды" data-linked-step2="ipdl_type">-->
                    <!--                            <span class="step-2__radio-style"></span>-->
                    <!--                            <div class="step-2__radio-text">{{ $t('DIVIDENDS') }}</div>-->
                    <!--                          </label>-->
                    <!--                        </div>-->
                    <!--                        <div class="step-2__radio-wrapper">-->
                    <!--                          <label class="step-2__radio-label">-->
                    <!--                            <input class="step-2__radio-input" type="radio" name="IPDL"-->
                    <!--                                   value="Иное" data-linked-step2="ipdl_type">-->
                    <!--                            <span class="step-2__radio-style"></span>-->
                    <!--                            <div class="step-2__radio-text">{{ $t('OTHER') }}</div>-->
                    <!--                          </label>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->

                  </div>


                  <div class="step-2__flex block_buy">
                    <button class="step-2__button" data-btn="buy-step-2" disabled>
                      <span v-html="$t('CONFIRM_NUMBER')"></span>
                      <span data-btn="check-btn-stp-2"></span>
                    </button>
                    <div class="block button-flex-wrapper">
                      <div class="step-2__price-lable" v-html="$t('POLICY_COST_OGPO_STEP2')"></div>
                      <div class="step-2__price-value" data-calc-result="price"></div>
                    </div>
                  </div>
                  <div class="step-2__validation-error" data-form-step2="error">
                    <p><span>!</span> {{ $t('ERROR_DATA_CHECK_FIELDS') }}</p>
                  </div>
                </form>
              </div>
            </form>
          </div>
          <div class="step-3 step" data-step-cont="3">
            <div class="step-3__wrapper">
              <div class="calc-type">{{ $t('STD') }}</div>
              <div class="slick-slider">
                <div>
                  <form class="step-3__card" data-form="third" action="javascript:;">
                    <input type="hidden" name="type" value="default">
                    <template v-if="$t('URL_PREFIX')==='/kz'">
                      <p class="step-3__card-text bolder">{{ $t('OSGPO_INFO') }}</p>
                      <p class="step-3__card-text">{{ $t('POLICY_COST') }}</p>
                    </template>
                    <template v-else>
                      <p class="step-3__card-text">{{ $t('POLICY_COST') }}</p>
                      <p class="step-3__card-text bolder">{{ $t('OSGPO_INFO') }}</p>
                    </template>
                    <div v-show="isReset" class="step-3__card-month"><span class="num">{{
                        $t('TOTAL_DOPLATA')
                                                                                       }}: </span></div>
                    <div class="step-3__card-price" :style="[isReset ? {'marginTop': '0', 'marginBottom': '10px'} : {}]"
                         data-calc-result='price'>0 KZT
                    </div>
                    <div v-show="isReset" class="step-3__card-month"><span class="num" data-calc-result='period'>12 месяцев</span>
                    </div>
                    <div v-show="isReset" class="step-3__card-month"><span class="num" data-calc-result='price_remain'>{{
                        $t('REMAIN_PREMIUM')
                                                                                                                       }}: <span data-calc-result="price_remain_value"></span></span></div>
                    <div v-show="isReset" class="step-3__card-month"><span class="num" data-calc-result='price_new'>{{
                        $t('NEW_POLICY_COST')
                                                                                                                    }}: <span data-calc-result="price_new_value"></span></span></div>
                    <button class="step-3__card-button" data-btn="buy-step-3" type="button">{{
                        $t('PAY_BTN_LINK')
                                                                                            }}
                    </button>
                  </form>
                </div>
                <!--
                <div>
                  <div class="step-3__card">
                    <h3 class="step-3__card-title">Стандарт</h3>
                    <h4 class="step-3__card-sm-title">cамый дешевый</h4>
                    <div class="step-3__card-price">38 858 KZT</div>
                    <div class="step-3__card-month">12 месяцев</div>
                    <p class="step-3__card-text">ОС ГПО ВТС (полис обязательного страхования ответственности
                      автовладельцев)</p>
                    <button class="step-3__card-button">Купить</button>
                  </div>
                </div>
                <div>
                  <div class="step-3__card">
                    <h3 class="step-3__card-title">Премиум</h3>
                    <h4 class="step-3__card-sm-title">cамый дешевый</h4>
                    <div class="step-3__card-price">149 579 KZT</div>
                    <div class="step-3__card-month">12 месяцев</div>
                    <p class="step-3__card-text">ОС ГПО ВТС+ <br> КАСКО Трансформер.</p>
                    <div class="step-3__block">
                      <div class="step-3__input-title">Стаж вождения</div>
                      <select class="js-example-basic-single step-3__card-input" name="staj">
                        <option value="WY">от 1 до 2 лет</option>
                        <option value="AL">от 3 до 6 лет</option>
                        <option value="WY">от 7 до 10 лет</option>
                        <option value="AL">от 11 до 15 лет</option>
                      </select>
                    </div>
                    <div class="step-3__block">
                      <div class="step-3__input-title">Дата проведения <br> осмотра ТС</div>
                      <div class="datepicker-wrapper">
                        <input type="text" class="step-3__card-input ogpo-calc__datepicker datepicker-view-date imask-date" readonly>
                      </div>
                    </div>
                    <div class="step-3__rangeSlide">
                      <div class="step-3__rangeSlide-title">Франшиза</div>
                      <input type="text" class="step-3__range" name="rangeSlider" value="">
                    </div>
                    <button class="step-3__card-button">Купить</button>
                  </div>
                </div>
                -->
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import {calculatorsMixin} from "@/mixins/calculators/calculatorsMixin";
import ExtendedConfirmationModal from "@/components/modals/setClient/ExtendedConfirmationModal.vue";
import ExtendedConfirmationInsuredModal from "@/components/modals/setClient/ExtendedConfirmationInsuredModal.vue";
import FullScreenPreloader from "@/components/FullScreenPreloader.vue";
import ExtendedConfirmationInsuredListModal from "@/components/modals/setClient/ExtendedConfirmationInsuredListModal.vue";
import {mapActions} from "vuex";
import ExtendedConfirmationWarningModal from "@/components/modals/setClient/ExtendedConfirmationWarningModal.vue";
import CalculateLoader from "@/components/CalculatePriceLoader.vue";

export default {
  name: "OGPO",
    components: {
        CalculateLoader,
        ExtendedConfirmationWarningModal,
        FullScreenPreloader,
        ExtendedConfirmationInsuredListModal,
        ExtendedConfirmationInsuredModal,
        ExtendedConfirmationModal
    },
  props: {
    policy: {
      type: Object
    },
    isProlong: {
      type: Boolean
    }
  },
  data() {
    return {
      calcName: 'OGPO',
      user: {},
      policyFromSite: {},
      otherDrivers: {},
      loading: false,
      isMobile: false,
      isReset: false,
      globalId: false,
      canBuy: true
    }
  },
  metaInfo: {
    title: i18n.tc('POLICE_OGPO'),
    titleTemplate: '%s | Nomad.kz'
  },
  created(){
    this.isReset = this.$route.query.isReset === 'true' || this.$route.query.isReset === true;
    this.globalId = this.$route.query.globalId;
    this.$axios.get(`/contracts/is-acting/${this.globalId}`).then(response => {
        if (!response.data.success) {
            //поднимаем попап что полис не действует
            this.setAlertText(this.$t('Policy is not acting'))
            this.$bvModal.show('alert-info-text')
        }
    }).catch(e => {
        // поднимаем попап что с сервером что то не так
        this.setAlertText(e.response.data.message)
        this.$bvModal.show('alert-info-text')
    })
  },
  computed: {
    beginDate() {
      if (this.isReset)
        return this.dateHelper.today()
      if (!this.policy)
        return this.dateHelper.nextDay(new Date().toLocaleDateString())
      if (+this.policy.active)
        return this.policy.end_date ? this.dateHelper.nextDay(this.policy.end_date) : ''
      else
        return this.dateHelper.nextDay(new Date().toLocaleDateString())
    }
  },
  mixins: [calculatorsMixin],
  methods: {
      ...mapActions({
          setAlertText: 'modalOptions/setAlertText'
      }),

    fetchUser() {
      this.user = {...this.$store.getters['auth/user']}
    },
    fetchPolicy() {
      console.log('пытаемся получить полис');
      let beginDate = this.beginDate
      // если это переоформление, получаем с datalab
      if (this.isReset) {
        this.fetchDataLabPolicy()
        return;
      }
      // если нет полиса, тогда прекращаем операцию
      if (!this.policy)
        return


      this.loading = true
      // получаем полис с сайта
      console.log('пытаемся получить полис с сайта')
      this.$axios.get(`/contracts/get/from-site/${this.policy.global_id}`)
      .then(async (response) => {
        // если не получили с сайта, получаем из datalab
        if (!response.data.success) {
          this.fetchDataLabPolicy()
          return;
        }

        let calc = window.calcOGPO;
        this.policyFromSite = response.data.contract

         // если нет пропсов с сайта, уходим
        if (!this.policyFromSite.props)
          return

        let props = this.policyFromSite.props;

        let fields = {
          insurance_date: beginDate,
        }
        let contract_number_old = '';
        let is_reset = false;
        calc.setFormData(fields)

        //если переоформление
        if (this.isReset === true) {
          //change date start
          beginDate = new Date(Date.now() + 1000 * 60 * 60 * 24).getDay()
          contract_number_old = props.CONTRACT_NUMBER
          is_reset = true
        }

        let secondFormFields = {
          address: this.user.address ? this.user.address : props.ADDRESS_INS,
          udo_author: props.IIN_AUTHOR,
          phone: '7'+this.user.phone_number.slice(1),
          email: this.user.email ? this.user.email : props.EMAIL,
          global_id: contract_number_old,
          is_reset: is_reset,
        }

        //формирование второго шага
        for (let i = 0; i < props['INSURED_NAME'].length; i++) {
          if (i === 0) {
            secondFormFields.name = props['INSURED_NAME'][i];
            secondFormFields.surname = props['INSURED_SURNAME'][i];
            secondFormFields.udo_num = props['INSURED_UDO_NUM'][i];
            secondFormFields.udo_date = props['INSURED_UDO_DATE'][i];
          }
        }
        for (let i = 0; i < props['INSURED_NAME'].length; i++) {
          if (i === 0) continue

          this.otherDrivers['insured_name[' + props['IINS'][i] + ']'] = props['INSURED_NAME'][i]
          this.otherDrivers['insured_surname[' + props['IINS'][i] + ']'] = props['INSURED_SURNAME'][i]
          this.otherDrivers['insured_udo_num[' + props['IINS'][i] + ']'] = props['INSURED_UDO_NUM'][i]
          this.otherDrivers['insured_udo_date[' + props['IINS'][i] + ']'] = props['INSURED_UDO_DATE'][i]
        }

        calc.setSecondFormData(secondFormFields)

        this.addDriversFromSite(calc, props.IINS);

        if(props['IINS'].length > 1) {
            document.addEventListener('insureds-kdp-finished',
                  () => this.addCarsFromSite(calc, props),
                  { once: true }
            )
        } else {
            await this.addCarsFromSite(calc, props)
        }

        calc.modules.addInsured.obj.addRecordEmit(props.IIN)
        window.calcOGPO.setContractNumber(contract_number_old);
        if (this.isReset !== true) {
          window.calcOGPO.setProlongingPolicy()
        } else {
          window.calcOGPO.setResettingPolicy()
        }
      })
      .catch(() => {
        this.fetchDataLabPolicy()
      })
      .finally(() => {
        this.loading = false
      })
    },

    fetchDataLabPolicy() {
      this.loading = true
      this.$axios.get(`/contracts/by-global-id/${this.globalId}`)
      .then(async (response) => {

        if (!response.data.success)
          return

        let calc = window.calcOGPO;

        let data = response.data.data

        let secondFormFields = {}
        let insurer = data.drivers.filter(item => item.id === parseInt(data.ID_Insured))

        /* если страхователь не является водителем,
           выводим модалку с ошибкой и завершаем выполнение кода
         */
        if(insurer.length === 0) {
            this.setAlertText(this.$t('CALL_CENTER_INFO'))
            this.canBuy = false;
            return;
        }

        let otherDrivers = data.drivers.filter(item => item.id !== parseInt(data.ID_Insured))
        let fullName = insurer[0].name.split(' ')

        // данные по страхователю
        secondFormFields = {
            name: fullName[1],
            surname: fullName[0],
            address: this.user.address,
            email: this.user.email,
            phone: '7'+this.user.phone_number.slice(1)
        }

        this.addDriversFromDatalab(calc, otherDrivers)

        if(otherDrivers.length) {
            document.addEventListener('insureds-kdp-finished',
                () => this.addCarsFromDatalab(calc, data.cars),
                { once: true }
            )
        } else {
            await this.addCarsFromDatalab(calc, data.cars)
        }

        let global_id = '';
        let is_reset = false;
        //если переоформление
        if (this.isReset === true) {
          global_id = this.globalId
          is_reset = true
        }
        secondFormFields.global_id = global_id
        secondFormFields.is_reset = is_reset

        calc.setSecondFormData(secondFormFields)

        window.calcOGPO.setContractNumber(global_id);
        if (this.isReset !== true) {
          window.calcOGPO.setProlongingPolicy()
        } else {
          window.calcOGPO.setResettingPolicy()
        }
      }).finally(() => {
        this.loading = false
      })

      // получение личнх данных застрахованных при переоформлении
      this.loading = true
      this.$axios.get(`/contracts/get/from-site/${this.globalId}`)
      .then(response => {
        let calc = window.calcOGPO;
        this.policyFromSite = response.data.contract

        if (!this.policyFromSite.props)
          return

        let props = this.policyFromSite.props;

        let contract_number_old = '';
        let is_reset = false;
        //если переоформление
        if (this.isReset === true) {
          //change date start
          contract_number_old = props.GLOBAL_ID
          is_reset = true
        }
        let secondFormFields = {
          address: this.user.address ? this.user.address : props.ADDRESS_INS,
          udo_author: props.IIN_AUTHOR,
          phone: '7'+this.user.phone_number.slice(1),
          email: this.user.email ? this.user.email : props.EMAIL,
          global_id: contract_number_old,
          is_reset: is_reset,
        }
        //формирование второго шага
        for (let i = 0; i < props['INSURED_NAME'].length; i++) {
          if (i === 0) {
            secondFormFields.name = props['INSURED_NAME'][i];
            secondFormFields.surname = props['INSURED_SURNAME'][i];
            secondFormFields.udo_num = props['INSURED_UDO_NUM'][i];
            secondFormFields.udo_date = props['INSURED_UDO_DATE'][i];
          }
        }
        for (let i = 0; i < props['INSURED_NAME'].length; i++) {
          if (i === 0) continue

          this.otherDrivers['insured_name[' + props['IINS'][i] + ']'] = props['INSURED_NAME'][i]
          this.otherDrivers['insured_surname[' + props['IINS'][i] + ']'] = props['INSURED_SURNAME'][i]
          this.otherDrivers['insured_udo_num[' + props['IINS'][i] + ']'] = props['INSURED_UDO_NUM'][i]
          this.otherDrivers['insured_udo_date[' + props['IINS'][i] + ']'] = props['INSURED_UDO_DATE'][i]
        }

        calc.setSecondFormData(secondFormFields)
        window.calcOGPO.setContractNumber(contract_number_old);
      })
      .finally(() => {
        this.loading = false
      })
    },

    addDriversFromDatalab(calc, drivers) {
        drivers.forEach((item) => {
            let fullName = item.name.split(' ')

            this.otherDrivers['insured_name[' + item.iin_bin + ']'] = fullName[1]
            this.otherDrivers['insured_surname[' + item.iin_bin + ']'] = fullName[0]
            calc.modules.addInsured.obj.addRecordEmit(item.iin_bin)
        })
    },

    addDriversFromSite(calc, iins) {
        for (let i in iins) {
            calc.modules.addInsured.obj.addRecordEmit(iins[i])
        }
    },

    setOthersDriversData() {
      if (this.policy) {
        setTimeout(() => window.calcOGPO.setSecondFormData(this.otherDrivers), 0)
      }
    },

    async addCarsFromDatalab(calc, cars) {
        let carsRequests = [];

        cars.forEach((item, index) => {
            let isLastElement = index === cars.length - 1
            carsRequests.push(calc.modules.addCars.obj.addRecord(item.reg_number, item.passport_number, true, isLastElement))
        })

        // проверяем наличие ошибок при подтягивании машин
        let addCarsResult = await this.checkAddCarsErrors(carsRequests);
        if(!addCarsResult.success) {
            calc.modules.addCars.obj.showAddCarsError(addCarsResult.carNumbers)
        }
    },

    async addCarsFromSite(calc, props)  {
        let carsRequests = [];

        for (let i in props.reg_numbers) {
            let isLastElement = +i === props.reg_numbers.length - 1;
            carsRequests.push(calc.modules.addCars.obj.addRecord(props.reg_numbers[i], props.car_passports[i], true, isLastElement));
        }

        // проверяем наличие ошибок при подтягивании машин
        let addCarsResult = await this.checkAddCarsErrors(carsRequests);

        if(!addCarsResult.success) {
            calc.modules.addCars.obj.showAddCarsError(addCarsResult.carNumbers, () => this.addDriversFromSite(calc, props.IINS));
        }
    },

    fetchUserData() {
      if (this.policy)
        return

      console.log('получаем пользователя');
      this.loading = true

      this.$axios.get('/contracts/user-data/get/from-site', {
        params: {
          iin: this.user.iin,
          product_name: 'ОГПО'
        }
      }).then(async (response) => {
        if (!response.data.success) {
          let secondFormFields = {
            address: this.user.address,
            phone: '7' + this.user.phone_number.slice(1),
            email: this.user.email
          }

          window.calcOGPO.setSecondFormData(secondFormFields)
          return
        }

        let data = response.data.data;
        let experience = this.getExperience(data.DRIVER_CLASS)
        this.userDataFromSite = true

        let carsRequests = [];

        carsRequests.push(window.calcOGPO.modules.addCars.obj.addRecord(data.CAR_NUMBER, data.CAR_PASSPORT, true));

        // проверяем наличие ошибок при подтягивании машины
        let addCarsResult = await this.checkAddCarsErrors(carsRequests);
        if(!addCarsResult.success) {
            window.calcOGPO.modules.addCars.obj.showAddCarsError(addCarsResult.carNumbers)
        }

        window.calcOGPO.modules.addInsured.obj.addRecordEmit(data.IIN, experience)

        let secondFormFields = {
          udo_num: data.DOCUMENT_NUMBER,
          udo_date: data.DOCUMENT_DATE,
          udo_author: data.DOCUMENT_AUTHOR,
          address: this.user.address ? this.user.address : data.ADDRESS,
          phone: '7' + this.user.phone_number.slice(1),
          email: this.user.email ? this.user.email : data.EMAIL
        }

        window.calcOGPO.setSecondFormData(secondFormFields)
      }).finally(() => {
        this.loading = false
      })
    },
    getExperience(driverClass) {
      if (driverClass <= 2) {
        return 1;
      } else if (driverClass > 2) {
        return 2;
      }
    },
    calculatorName() {
      return this.mobileApp.isWebView() ? this.$t('OGPO_CALCULATOR_MOBILE') : this.$t('OGPO_CALCULATOR')
    },
  },
  mounted() {
    this.fetchUser()
    /* eslint-disable */
    this.isMobile = isMobile()
    this.$nextTick(() => {
      window.calcOGPO = new calcOGPO();
      window.calcOGPO.init();
      if(this.isProlong) {
          window.calcOGPO.setIsProlong(this.isProlong);
      }
      if(this.isReset) {
          window.calcOGPO.setIsReset(this.isReset);
          window.calcOGPO.setContractNumber(this.globalId)
      }
      // подставляем ИИН пользователя
      $('[data-field="iin_for_agree"]').val(this.user.iin)
    })
    initImask()
    initMasks()
    initSelect2()
    initDatepickers()
    initDescriptionAccordion()
    /* eslint-enable */
  },
}
</script>

<style lang="scss" scoped>
.ogpo-calc__price-reset-value{
    color: black;
}
.ogpo-calc__final-price-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.price-container{
    padding: 15px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border-top: 1px solid #F6F8FB;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    max-width: 650px;
    flex-direction: column;
    margin-bottom: 50px;
}
.calc-module-210222 {
    .zhP-calc {
        .container {
            margin: 0 auto;
            position: relative;
        }

        .row {
            margin-left: 0;
        }
    }

    .input {
        width: 298px;
        height: 38px;
    }
}

.my-dropdown-results .select2-results {
    width: unset !important; /* Remove any width set by the inline style */
}

.my-dropdown-results span[style*="width"] {
    width: 300px !important; /* Set the desired width for the dropdown when the span has a style attribute */
}

.select2-container--open .select2-dropdown--below {
    border-top: 1px solid #D8D8D8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: translateY(-1px);
    width: 298px !important;
}

.calc-module-210222 .ogpo-calc__srok {
    width: 298px;
    margin-left: 18px;
}

.calc-module-210222 .ogpo-calc__person-info .ogpo-calc__person-name {

    position: relative !important;
    bottom: 100px !important;
}

.calc-module-210222 .ogpo-calc__person-card .ogpo-calc__person-info p.ogpo-calc__person-name {
    position: relative !important;
    bottom: 40px !important;
    margin-bottom: 20px;
}


@media (max-width: 768px) {
    .calc-module-210222 .input {
        width: 115px;
        height: 31px;
    }

    //.calc-module-210222 .ogpo-calc__srok {
    //    width: 115px !important;
    //    height: 31px;
    //    margin-left: 18px;
    //}

    //.calc-module-210222 .ogpo-calc__srok .select2-selection--single {
    //    width: 100%!important;
    //}

    .calc-module-210222 .ogpo-calc__left, .calc-module-210222 .ogpo-calc__right {
        display: block;

    }

    .calc-module-210222 .ogpo-calc__srok {
        margin-top: 10px;
    }

}


</style>
