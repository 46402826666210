<template>
    <div class="lk_card inProcessCard">
        <div class="des-991">
            <div class="lk_card__body">
                <div class="lk_card__body-top">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="lk_card__block">
                            <h3 class="lk_card-calc">{{ $t('request') }}</h3>
                        </div>
                        <div class="lk_card__block">
                            <h4 class="lk_card-subName">{{ request.status }}</h4>
                        </div>
                    </div>
                </div>
                <div class="lk_card__body-main">
                    <div class="message">
                        {{ request.client_message }}
                    </div>
                    <ul class="list">
                        <li class="item">
                            <h4 class="card-title">{{ $t('Insurance case date') }}</h4>
                            <p class="card-value">{{ request.accident_date2 }}</p>
                        </li>
                        <li class="item">
                            <h4 class="card-title">{{ $t('Full name') }}</h4>
                            <p class="card-value">{{ fullName }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="lk_card-footer">
                <a class="lk_card-footer-link" @click="showDetailInsCaseInRequest">{{ $t('Look') }}</a>
            </div>
        </div>

        <div class="mob-991">
            <div class="lk_card__body">
                <div class="lk_card__body-top">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="lk_card__block">
                            <h3 class="lk_card-calc">{{ $t('request') }}</h3>
                        </div>
                        <div class="lk_card__block">
                            <h4 class="lk_card-subName">{{ request.status }}</h4>
                        </div>
                    </div>
                </div>
                <div class="lk_card__body-main">
                    <div class="message">
                        {{ request.client_message }}
                    </div>
                    <div class="list">
                        <div class="item">
                            <h4 class="card-title">{{ $t('Insurance case date') }}</h4>
                            <p class="card-value">{{ request.accident_date2 }}</p>
                        </div>
                        <b-collapse v-model="visible" class="block-hidden">
                            <li class="item">
                                <h4 class="card-title">{{ $t('Full name') }}</h4>
                                <p class="card-value">{{ fullName }}</p>
                            </li>
                        </b-collapse>
                        <div :class="{'collapsed': visible}" class="card-btn"
                             @click="visible = !visible; openText = !openText">
                            <div class="btn-text">{{ openText ? $t('Less') : $t('More') }}</div>
                            <div :class="{ 'open': openText }" class="icon"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lk_card-footer">
                <a class="lk_card-footer-link" @click="showDetailInsCaseInRequest">{{ $t('Look') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: 'InsuranceAffairsRequest',
    props: {
        request: {
            type: Object,
            required: true
        },
    },
    data: () => {
        return {
            visible: false,
            openText: false,
            user: {},
        }
    },
    methods: {
        fetchUser() {
            this.user = {...this.$store.getters['auth/user']}
        },
        ...mapActions({
            setInsCaseInRequest: 'modalOptions/setInsCaseInRequestForDisplayInModal'
        }),
        showDetailInsCaseInRequest() {
            this.setInsCaseInRequest(this.request)
            this.$bvModal.show('insurance-detail-info-modal')
        }
    },
    mounted() {
        this.fetchUser()
    },
    computed: {
        fullName() {
            return this.stringHelper.capitalize(`${this.user.last_name} ${this.user.first_name}`, true)
        }
    }
}

</script>

<style lang="scss" scoped>
.lk_card__body-main {
    .message {
        font-size: 10px;
        color: #999999;
        margin-bottom: 20px;
    }
}
.inProcessCard {
    &.lk_card {
        .item {
            margin-top: 15px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-top: 15px;
                padding-top: 0;
                border-top: none;
            }
        }
    }

    .lk_card-subName {
        position: static;
    }

    .lk_card__block {
        flex: 0 0 auto;
    }
}
@media (max-width: 991px) {
    .lk_card__body-main .message {
        font-size: 14px;
    }
}
</style>
