<template>
    <div class="lk_card inProcessCard">
        <div class="des-991">
            <div class="lk_card__body">
                <div class="lk_card__body-top">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="lk_card__block">
                            <h3 class="lk_card-calc">{{ insuranceCase.product }}</h3>
                        </div>
                        <div class="lk_card__block">
                            <h4 class="lk_card-subName">{{ insuranceCase.doc_state }}</h4>
                        </div>
                    </div>
                </div>
                <div class="lk_card__body-main">
                    <ul class="list">
                        <li class="item">
                            <h4 class="card-title">GLOBAL_ID</h4>
                            <p class="card-value">{{ insuranceCase.policy_global_id }}</p>
                        </li>
                        <li class="item">
                            <h4 class="card-title">{{ $t('insurance_cases_number') }}</h4>
                            <p class="card-value">{{ insuranceCase.payout_case_number }}</p>
                        </li>
                        <li class="item">
                            <h4 class="card-title">{{ $t('Insurance case date') }}</h4>
                            <p class="card-value">{{ dateAndTime }}</p>
                        </li>
                        <li class="item">
                            <h4 class="card-title">{{ $t('Full name') }}</h4>
                            <p class="card-value">{{ insuranceCase.last_name }} {{ insuranceCase.first_name }}</p>
                        </li>
                        <li class="item">
                          <h4 class="card-title">{{ $t('Declaration') }}</h4>
                          <p class="card-value"><a :href = "insuranceCase.declaration_link"
                                                   target="_blank">{{ $t('Link') }}</a></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="lk_card-footer">
                <a v-b-modal.insurance-case-in-process class="lk_card-footer-link" @click="$emit('selectInsuranceCase', insuranceCase)">
                    {{ $t('Look') }}
                </a>
            </div>
        </div>

        <div class="mob-991">
            <div class="lk_card__body">
                <div class="lk_card__body-top">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="lk_card__block">
                            <h3 class="lk_card-calc">{{ insuranceCase.product }}</h3>
                        </div>
                        <div class="lk_card__block">
                            <h4 class="lk_card-subName">{{ insuranceCase.doc_state }}</h4>
                        </div>
                    </div>
                </div>
                <div class="lk_card__body-main">
                    <div class="list">
                        <li class="item">
                          <h4 class="card-title">GLOBAL_ID</h4>
                          <p class="card-value">{{ insuranceCase.policy_global_id }}</p>
                        </li>
                        <div class="item" v-if="insuranceCase.pc_id">
                            <h4 class="card-title">{{ $t('insurance_cases_number') }}</h4>
                            <p class="card-value">{{ insuranceCase.payout_case_number }}</p>
                        </div>
                        <b-collapse v-model="visible" class="block-hidden">
                            <li class="item">
                                <h4 class="card-title">{{ $t('Insurance case date') }}</h4>
                                <p class="card-value">{{ dateAndTime }}</p>
                            </li>
                            <li class="item">
                                <h4 class="card-title">{{ $t('Full name') }}</h4>
                                <p class="card-value">{{ insuranceCase.last_name }} {{ insuranceCase.first_name }}</p>
                            </li>
                            <li class="item">
                                <h4 class="card-title">{{ $t('Declaration') }}</h4>
                                <p class="card-value"><a :href = "insuranceCase.declaration_link"
                                                         target="_blank">{{ $t('Link') }}</a></p>
                            </li>
                        </b-collapse>
                        <div :class="{'collapsed': visible}" class="card-btn"
                             @click="visible = !visible; openText = !openText">
                            <div class="btn-text">{{ openText ? $t('Less') : $t('More') }}</div>
                            <div :class="{ 'open': openText }" class="icon"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lk_card-footer">
                <a v-b-modal.insurance-case-in-process class="lk_card-footer-link" @click="$emit('selectInsuranceCase', insuranceCase)">
                    {{ $t('Look') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'InsuranceAffairsInProcess',
    props: {
        insuranceCase: {
            type: Object,
            required: true
        },
    },
    data: () => {
        return {
            visible: false,
            openText: false
        }
    },
    computed: {
        dateAndTime() {
            if (this.insuranceCase.accident_date !== '' && this.insuranceCase.accident_time !== '')
                // eslint-disable-next-line no-undef
                return this.insuranceCase.accident_date + ' ' + mess["time in o'clock"] + ' ' + this.insuranceCase.accident_time
            else if (this.insuranceCase.accident_date !== '' && this.insuranceCase.accident_time === '')
                return this.insuranceCase.accident_date
            else if (this.insuranceCase.accident_date === '' && this.insuranceCase.accident_time !== '')
                return this.insuranceCase.accident_time
            // eslint-disable-next-line no-undef
            else return mess["No data"]
        }
    }
}

</script>

<style lang="scss" scoped>
.inProcessCard {
    &.lk_card {
        .item {
            margin-top: 15px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-top: 15px;
                padding-top: 0;
                border-top: none;
            }
        }
    }

    .lk_card-subName {
        position: static;
    }

    .lk_card__block {
        flex: 0 0 auto;
    }
}
</style>
