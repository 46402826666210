<template>
    <div class="calc-module-210222 like_advokat">
        <section class="base-calc ogpo-calc" data-calc="CHSI">
            <div class="container">
                <div id="pre-loader">
                    <b-spinner />
                </div>
          <div class="calculator-description-box">
            <div class="calculator-description-header">
              <h2 class="calculator-title">{{$t('CALCULATOR_INFO.CHSI.TITLE')}}</h2>
              <span :class="['calculator-description-arrow', {'is-closed': isMobile}]"></span>
            </div>
            <div :class="['calculator-description-body', {'is-closed': isMobile}, {'mob-show-scroll': isMobile}]">
              <p class="calculator-description-text" v-html="$t('CALCULATOR_INFO.CHSI.DESCRIPTION')"></p>
            </div>
          </div>
                <h1 class="ogpo-calc__main-title">{{ calculatorName() }}</h1>
                <div class="ogpo-content kascoDTP-content">
                    <ExtendedConfirmationModal/>
                    <FullScreenPreloader :loading="loading"/>
                    <!--step 1 - agreement with data collection and processing-->
                    <div class="rights-blocker iin-collect" data-rights-blocker>
                        <div class="popup">
                            <div class="loader-container" data-loader>
                                <div class="loader"></div>
                            </div>
                            <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                            <p class="popup-text" v-html="$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
                            <label class="ogpo-calc__label step1 agreement-container">
                                <p class="step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                                <input data-field="iin_for_agree" name="iin_for_agree" type="text"
                                       class="iin-for-agree imask-iin">
                                <span data-error="iin-collect-error-field" class="iin-collect-error-field"></span>
                            </label>
                            <button class="ogpo-calc__button mob-right" data-btn="data_collection_confirm">
                                {{ $t('I_CONFIRM') }}
                            </button>
                        </div>
                    </div>
                    <!--step 2 - agreement with rules of policy-->
                    <div class="rights-blocker" data-personal-blocker style="display: none">
                        <div class="popup">
                            <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                            <p class="popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>
                            <label class="ogpo-calc__label step2">
                                <input data-field="confirm" name="confirm" type="checkbox" value="true">
                                <span class="ogpo-calc__checkBox"></span>
                                <div v-html="$t('ADVOKAT_CALC_RIGHT_S2_CONFIRM_ONT')"></div>
                            </label>
                        </div>
                    </div>
                    <div class="ogpo-calc__nav">
                        <div class="ul">
                            <div class="ogpo-calc__line ogpo-calc__line-1 active" data-step-tab="1">
                                <div class="ogpo-calc__nav-link link-step-1">1</div>
                                <div class="line"></div>
                            </div>

                            <div class="ogpo-calc__line ogpo-calc__line-2" data-step-tab="2">
                                <div class="ogpo-calc__nav-link link-step-2">2</div>
                                <div class="line"></div>
                            </div>
                            <div class="ogpo-calc__line ogpo-calc__line-3" data-step-tab="3">
                                <div class="ogpo-calc__nav-link link-step-3">3</div>
                                <div class="line"></div>
                            </div>
                            <div class="ogpo-calc__line ogpo-calc__line-4"></div>
                        </div>
                    </div>
                    <div class="step-1 step" data-step-cont="1">
                        <div>
                            <div class="step-1__wrapper">
                                <form class="ogpo-calc__block-top-box" action="javascript:;" data-form="first"  method="post">
                                    <div class="ogpo-calc__block-checkbox main-confirm-step1">
                                        <label class="ogpo-calc__label">
                                            <input required class="ogpo-calc__input" type="checkbox"  data-linked="confirm" data-field="confirm" value="true">
                                            <span class="ogpo-calc__checkBox"></span>
                                            <div v-html="$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                                        </label>
                                    </div>
                                    <div class="kascoDTP__block-top">
                                        <div class="kascoDTP__top-wrapper">
                                            <div class="kascoDTP__top-flex">
                          <div class="ogpo-calc__date ins_date">
                            <h4 class="input__title">{{ $t('INS_DATE') }}</h4>
                                                    <div class="datepicker-wrapper">
                                                        <input class="input ogpo-calc__datepicker datepicker-ins-date
                                                        imask-date" type="text" data-linked="ins_date"
                                                               :placeholder="$t('DMY')" readonly>
                                                    </div>
                                                </div>
                          <div class="ogpo-calc__srok term_of_insurance">
                            <h4 class="input__title">{{ $t('TERM_OF_INSURANCE') }}</h4>
                                                    <select
                                                        class="input select2" disabled
                                                        name="ins_period" data-linked="ins_period"
                                                        data-dun-select="ins_period">
                                                        <option value="AL"></option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                      <div class="advokat__wrap-flex advocat__main-wrapper" data-add-insured-advokat="ADVOKAT">
                        <div class="left-inner-wrapper">
                                            <div class="advokat__block">
                                                <div class="m-bottom">
                              <h4 class="input__title">{{ $t('IIN') }}</h4>
                              <div id="searchIIN" class="advokat__wrapper two-col-grid">
                                                        <input type="text" class="advokat__input input-iin" data-linked="iin_client">
                                                        <div class="advokat-calc__name">
                                                            <div class="input-iin-placeholder" data-result="placeholder">{{ $t('WRITE_IIN') }}</div>
                                                            <p class="ogpo-calc__validation-error" data-loading>{{ $t('LOADING') }}</p>
                                                            <div class="name-text" data-result="name"></div>
                                                            <p class="advokat-calc__validation-error" data-result="error">{{ $t('ERROR_DATA') }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                          <div class="advokat__block two-col-grid">
                            <div class="m-bottom born">
                              <h4 class="input__title">{{ $t('DTP_BORN_LABEL') }}</h4>
                              <div class="datepicker-wrapper personal-info-wrapper">
                                <input class="input ogpo-calc__datepicker datepicker-ins-date imask-date" type="text" data-linked="born_client" :placeholder="$t('DMY')">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="right-inner-wrapper">
                                            <div class="advokat__block">
                            <div class="m-bottom">
                              <div class="advokat__wrapper flex-column">

                                <h4 class="input__title">{{ $t('chsi_action_area') }}</h4>

                                <select class="js-example-basic-single input select2" name="action_area" data-linked="action_area" data-dun-select="action_area">
                                </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="advokat__block">
                                                <div class="m-bottom advokat_amount">
                                                    <div class="advokat__wrapper flex-column">

                                <h4 class="input__title">{{ $t('SUM_INSURED') }}</h4>

                                                        <select class="js-example-basic-single input select2" name="chsi_amount" data-linked="chsi_amount" data-dun-select="chsi_amount">
                                                        </select>
                              </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="advokat__block">
                                                <div class="m-bottom residency">
                            <h4 class="input__title">{{ $t('RESIDENCY') }}</h4>
                                                    <div class="advokat__wrapper">
                                                        <select class="input select2" disabled
                                                                name="residency" data-linked="residency"
                                                                data-dun-select="residency">
                                                            <option value="YES">Резидент</option>
                                                        </select>
                                                    </div>
                                                </div>
                          <p  class="franchise-tooltip">* {{$t('FIXED_FRANCHISE')}} 5%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="advokat__block-top">
                      <div class="advokat__wrap-flex">
                        <div class="ogpo-calc__price">
                          <h4 class="ogpo-calc__price-title">{{ $t('POLICY_COST') }}</h4>
                                                <div class="ogpo-calc__price-value" data-calc-result="price"></div>
                                            </div>
                                            <button class="ogpo-calc__button mob-right" data-btn="buy-step-1"
                                                    :aria-label="$t('ADVOKAT_STEP1_BTN_TOOLTIP')"
                                                    data-cooltipz-dir="center">{{ $t('PAY_BTN_LINK') }}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="step-2 step" data-step-cont="2">
                        <form  method="post" action="javascript:void(0)" data-form="second">
                            <input type="hidden" name="notify_phone" value="true">
                <div class="ogpo-calc__block-checkbox main-confirm-step1 main-confirm-step2">
                                <label class="ogpo-calc__label">
                                    <input required class="ogpo-calc__input" type="checkbox"  data-linked-step2="confirm" data-field="confirm">
                                    <span class="ogpo-calc__checkBox"></span>
                    <div v-html="$t('CALC_RIGHT_S2_CONFIRM')"></div>
                                </label>
                            </div>
                            <div class="step-2__form-wrapper">
                  <form action="">
                                    <h2 class="step-2__form-title">{{ $t('INSURANT_INFO') }}</h2>
                                    <!--                                    <p class="step-2__form-name" data-insurant="name">Олег И</p>-->
                                    <p class="step-2__form-name" data-insurant="name">{{ user.first_name }} {{ user.last_name }}</p>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                        <div class="input__title">{{ $t('NAME') }}*</div>
                        <input class="step-2__input uppercase" type="text" required
                               data-linked-step2="name" data-main-user-iin>
                                        </div>
                                        <div class="step-2__col">
                        <div class="input__title">{{ $t('SURNAME') }}*</div>
                        <input class="step-2__input uppercase" type="text" required
                               data-linked-step2="surname" data-main-user-iin>
                                        </div>
                                    </div>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                        <div class="input__title">{{ $t('IMUSHESTVO_UDO') }}*</div>
                        <input class="step-2__input imask-udo-num" type="text" required
                               data-linked-step2="udo_num" data-main-user-iin>
                                        </div>
                                        <div class="step-2__col">
                        <div class="input__title">{{ $t('IMUSHESTVO_UDO_DATE') }}*</div>
                        <input class="step-2__input datepicker-base imask-date" type="text" required
                               data-linked-step2="udo_date" data-main-user-iin
                               :placeholder="$t('DMY')">
                                        </div>
                                    </div>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                        <div class="input__title">{{ $t('HOW_UDO_AUTHOR') }}*</div>
                        <input class="step-2__input" type="text" required
                               data-linked-step2="udo_author">
                                        </div>
                                        <div class="step-2__col">
                        <div class="input__title">{{ $t('REG_ADDRESS') }}*</div>
                        <input class="step-2__input" type="text" required
                               data-linked-step2="address">
                                        </div>
                                    </div>
                                    <div class="step-2__row">
                                        <div class="step-2__col">
                        <div class="horizontal-wrapper input__title">
                          <div>{{ $t('PHONE_NUMBER') }}*</div>
                          <div class="info-icon"
                               :aria-label="$t('VERIFICATION_PHONE_NUMBER_TOOLTIP')"
                               data-cooltipz-dir="bottom">
                            <div class="question-mark"></div>
                                        </div>
                                    </div>
                        <input class="step-2__input imask-phone" :value="phone" type="text" required
                               data-linked-step2="phone">
                                                                                    </div>
                                                                                    <div class="step-2__col">
                        <div class="input__title">Email*</div>
                        <input class="step-2__input" type="email" required data-linked-step2="email"
                               disabled>
                                                                                </div>
                                                                            </div>

                    <div class="step-2__checkbox-wrapper">
                      <div class="step-2__checkbox">
                                        <label class="ogpo-calc__label">
                                            <input class="ogpo-calc__input" type="checkbox"  data-linked-step2="notify_email">
                                            <span class="ogpo-calc__checkBox"></span>
                                            <div>{{ $t('GET_INFO_ON_EMAIL') }}</div>
                                        </label>
                                    </div>

<!--                      <div class="step-2__checkbox" data-fields="ipdl" >-->
<!--                        <div class="checkbox-prprt">-->
<!--                          <label class="ogpo-calc__label">-->
<!--                            <input class="ogpo-calc__input input-IPDL" type="checkbox"-->
<!--                                   data-linked-step2="is_ipdl" value="true">-->
<!--                            <span class="ogpo-calc__checkBox"></span>-->
<!--                            <div> {{ $t('I_AM_IPDL') }}</div>-->
<!--                          </label>-->
<!--                        </div>-->

<!--                        <div class="step-2__dop-polya" data-field-step2="ipdl_options">-->
<!--                          <div class="step-2__radio-wrapper">-->
<!--                            <label class="step-2__radio-label">-->
<!--                              <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                     value="Зарплата" checked data-linked-step2="ipdl_type">-->
<!--                              <span class="step-2__radio-style"></span>-->
<!--                              <div class="step-2__radio-text">{{ $t('SALARY') }}</div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                          <div class="step-2__radio-wrapper">-->
<!--                            <label class="step-2__radio-label">-->
<!--                              <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                     value="Доход от предпринимательской деятельности"-->
<!--                                     data-linked-step2="ipdl_type">-->
<!--                              <span class="step-2__radio-style"></span>-->
<!--                              <div class="step-2__radio-text">{{ $t('BUSINESS_INCOME') }}</div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                          <div class="step-2__radio-wrapper">-->
<!--                            <label class="step-2__radio-label">-->
<!--                              <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                     value="Дивиденды" data-linked-step2="ipdl_type">-->
<!--                              <span class="step-2__radio-style"></span>-->
<!--                              <div class="step-2__radio-text">{{ $t('DIVIDENDS') }}</div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                          <div class="step-2__radio-wrapper">-->
<!--                            <label class="step-2__radio-label">-->
<!--                              <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                     value="Иное" data-linked-step2="ipdl_type">-->
<!--                              <span class="step-2__radio-style"></span>-->
<!--                              <div class="step-2__radio-text">{{ $t('OTHER') }}</div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

                                        </div>

                                    <div class="step-2__flex">
                      <button class="step-2__button" data-btn="buy-step-2" disabled>
                        <span v-html="$t('CONFIRM_NUMBER')"></span>
                        <span data-btn="check-btn-stp-2"></span>
                      </button>
                                        <div class="block">
                        <div class="step-2__price-lable">{{ $t('POLICY_COST') }}</div>
                                            <div class="step-2__price-value" data-calc-result="price"></div>
                                        </div>
                                    </div>
                                    <div class="step-2__validation-error" data-form-step2="error">
                                        <p><span>!</span> {{ $t('ERROR_DATA_CHECK_FIELDS') }}</p>
                                    </div>
                                </form>
                            </div>
                        </form>
                    </div>
                    <div class="step-3 step" data-step-cont="3">
                        <div class="step-3__wrapper">
                            <div class="slick-slider">
                                <div>
                                    <form class="step-3__card" data-form="third" action="javascript:void(0);">
                                        <input type="hidden" name="type" value="default">
                                        <template v-if="$t('URL_PREFIX')==='/kz'">
                        <p class="step-3__card-text bolder">{{ $t('ADVOKAT_INFO') }}</p><br>
                                            <p class="step-3__card-text bold">{{ $t('POLICY_COST') }}</p>
                                        </template>
                                        <template v-else>
                                            <p class="step-3__card-text bold">{{ $t('POLICY_COST') }}</p>
                        <p class="step-3__card-text bolder">{{ $t('ADVOKAT_INFO') }}</p>
                                        </template>

                                        <div class="step-3__card-price" data-calc-result='price'>0 KZT</div>
                                        <div class="step-3__card-month"><span class="num" data-calc-result='period'>12</span> {{ $t('MONTH') }}</div>
                                        <button class="step-3__card-button" data-btn="buy-step-3" type="button">{{ $t('PAY_BTN_LINK') }}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import {calculatorsMixin} from "@/mixins/calculators/calculatorsMixin";
import ExtendedConfirmationModal from "@/components/modals/setClient/ExtendedConfirmationModal.vue";
import FullScreenPreloader from "@/components/FullScreenPreloader.vue";

export default {
    name: "CHSI",
    components: {FullScreenPreloader, ExtendedConfirmationModal},
    metaInfo: {
        title: i18n.tc('CHSI_CALCULATOR'),
        titleTemplate: '%s | Nomad.kz'
    },
    props: {
        policy: {
            type: Object
        }
    },
    data() {
        return {
            calcName: 'CHSI',
            user: {},
            phone: '',
            policyFromSite: {},
            userDataFromSite: false,
            loading: false,
            isMobile: false
        }
    },
    mixins: [calculatorsMixin],
    methods: {
        fetchUser() {
            this.user = {...this.$store.getters["auth/user"]}
            this.phone = "7" + this.user.phone_number.slice(1)
        },
        fetchPolicy() {
            if (!this.policy)
                return

            this.loading = true
            this.$axios.get(`/contracts/get/from-site/${this.policy.global_id}`).then(response => {
                this.policyFromSite = response.data.contract
                window.calcChsi.setProlongingPolicy()
                /* eslint-disable */
                if (!this.policyFromSite.props)
                    return

                let props = this.policyFromSite.props

                let fields = {
                    insurance_date: this.beginDate,
                    chsi_amount: props.AMOUNT_SELECT_ID,
                }
                window.calcChSI.setFormData(fields)

                let regExp = new RegExp('[0-9]{10}$');
                let secondFormFields = {
                    name: props.NAME,
                    surname: props.SURNAME,
                    udo_num: props.UDO_NUM,
                    address: this.user.address ? this.user.address : props.ADDRESS,
                    udo_date: props.UDO_DATE,
                    udo_author: props.UDO_AUTHOR,
                    phone: "7"+regExp.exec(props.PHONE),
                    email: props.EMAIL
                }
                window.Chsi.setSecondFormData(secondFormFields)
            }).finally(() => {
                this.loading = false
            })
        },
        fetchUserData() { // если просто перешел по калькулятору на header и поставил галочку "Согласие"
            if (this.policy)
                return

            this.loading = true
            this.$axios.get('/contracts/user-data/get/from-site', {
                params: {
                    iin: this.user.iin,
                    product_name: 'АДВОКАТ'
                }
            }).then(response => {
                if (!response.data.success)
                    return

                let data = response.data.data;
                this.userDataFromSite = true

                let fields = {
                    iin_client: this.user.iin
                }
                window.calcChSI.setFormData(fields)
                $('[data-linked=iin_client]').trigger('keyup')

                let regExp = new RegExp('[0-9]{10}$');
                let secondFormFields = {
                    udo_num: data.DOCUMENT_NUMBER,
                    udo_date: data.DOCUMENT_DATE,
                    udo_author: data.DOCUMENT_AUTHOR,
                    address: this.user.address ? this.user.address : data.ADDRESS,
                    phone: "7"+regExp.exec(data.PHONE),
                    email: data.EMAIL
                }
                window.calcChSI.setSecondFormData(secondFormFields)
            }).finally(() => {
                this.loading = false
            })
        },
        calculatorName() {
            return this.mobileApp.isWebView() ? this.$t('CHSI_CALCULATOR_MOBILE') : this.$t('CHSI_CALCULATOR')
        }
    },
    mounted() {
        this.fetchUser()
        this.isMobile = isMobile()
        this.$nextTick(() => {
            window.calcChSI = new calcChSI();
            window.calcChSI.init();
            // подставляем ИИН пользователя
            $('.popup .agreement-container input[data-field="iin_for_agree"]').val(this.user.iin)
            /* eslint-disable */
            initImask()
            initMasks()
            initSelect2()
            initDatepickers()
            initDescriptionAccordion()
        })
    },
    computed: {
        beginDate() {
            if (!this.policy)
                return this.dateHelper.nextDay(new Date().toLocaleDateString())

            if (this.policy.active)
                return this.policy.end_date ? this.dateHelper.nextDay(this.policy.end_date) : ''
            else
                return this.dateHelper.nextDay(new Date().toLocaleDateString())
        },
        iinValue() {
            if (this.policy || this.userDataFromSite) {
                return ''
            } else {
                return this.user.iin
            }
        }
    }
}
</script>

<style lang="scss">

</style>
