export default Object.freeze({
  productIds: {
    ogpo: 1,
    ost: 285,
    mst: 273,
    imushestvo: 323,
    ont: 6,
    kasko_dtp: 16,
    comfort: 361,
    advokat: 304,
    puk: 303,
    chsi: 159,
    accident: 10,
    accident_sport: 135,
    opp: 5,
    dsgpo: 46,
    kaskoOtMopeda: 373
  },
  iblockIds: {
    APP: {
      IMUSHESTVO: 77,
      DSGPO: 98,
      OGPO_GATEWAY: 108,
      KASKO_DTP: 76,
      KASKO_TRN: 85,
      MST: 78,
      COMFORT: 103,
      ACCIDENT: 128,
      ACCIDENT_SPORT: 129,
    },
    SITE: {
      IMUSHESTVO: 95,
      DSGPO: 96,
      OGPO: 90,
      KASKO_DTP: 101,
      KASKO_TRN: 99,
      MST: 97,
      ONT: 112,
      OS_ECO: 113,
      OS_GPO: 122,
      ADVOKAT: 134,
      CHSI: 135,
      PUK: 136,
      COMFORT: 123,
      OGPO_JUR: 140,
      CARGO: 143,
      CARGO_JURIDICAL: 144,
      OPP_JURIDICAL: 151,
      ACCIDENT: 124,
      ACCIDENT_SPORT: 125,
    }
  },
  insuranceCases: {
    docStateCodes: {
      sent: 'SENT', // Отправлено
      new: 'NEW', // Новый
      collectingDocs: 'COLLECTING_DOCS', // Сбор документов
      concordance: 'CONCORDANCE', // На согласовании
      execute: 'EXECUTE', // На исполнении
      payment: 'PAYMENT', // Выплата
      refusal: 'REFUSAL', // Отказ
    }
  },
  dynamicPageRoutes: {
    ru: {
      aboutUs: '/about/',
      companyHistory: '/about/history/', // deprecated, скрыли (13.03.2023)
      mission: '/about/mission/',
      manual: '/about/rukovodstvo/',
      recommendationLetters: '/recommendation_letters/',
      vacancies: '/vacancies/',
      ourPartners: '/about/nashi_partnery/',
      rekvizity: '/about/rekvizity/',
      documents: '/documents/',
      news: '/news/',
      financialCondition: '/financial_condition/',
      reestrAgentov: '/reestr_agentov/',
      strahovyeTarify: '/strahovye_tarify/',
      branches: '/branches/',
    },
    kz: {
      aboutUs: '/kz/about/',
      companyHistory: '/kz/about/history/', // deprecated, скрыли (13.03.2023)
      mission: '/kz/about/mission/',
      manual: '/kz/about/rukovodstvo/',
      recommendationLetters: '/kz/recommendation_letters/',
      vacancies: '/kz/vacancies/',
      ourPartners: '/kz/about/nashi_partnery/',
      rekvizity: '/kz/about/rekvizity/',
      documents: '/kz/documents/',
      news: '/kz/news/',
      financialCondition: '/kz/financial_condition/',
      reestrAgentov: '/kz/reestr_agentov/',
      strahovyeTarify: '/kz/strahovye_tarify/',
      branches: '/kz/branches/',
    }
  },
  keyboardStates: { // состояния клавиатуры - нужен для приложения лк
    open: 'OPEN',
    close: 'CLOSE',
  },
  contractEventTypes: {
    prolong: 'PROLONG',
    reset: 'RESET',
    buy: 'BUY',
  },
  verification: {
    bts: 1,
    verilive: 2,
  },
  sign: {
    statuses: {
      new: 'new',
      in_progress: 'in-progress',
      failed: 'failed',
      success: 'success',
      expired: 'expired',
      iin_error: 'iin-error',
    },
    contexts: {
      chat: 'chat',
      notification: 'notification',
    }
  },
  geolocationErrorCodes: {
    permission_denied: 1,
    position_unavailable: 2,
    timeout: 3
  },
  signTaskStatuses: {
    request_in_progress: 'request.in_progress',
    request_success: 'request.success',
    file_in_progress: 'file.in-progress',
    file_success: 'file.success',
    sign_in_progress: 'sign.in-progress',
    sign_success: 'sign.success',
    failed: 'failed',
    success: 'success',
    expired: 'expired',
    in_progress: 'in-progress'
  },
  setEsbdEuroprotocolStatuses: {
    in_progress: 'in_progress',
    error: 'error',
    success: 'success'
  }
})
