<template>
    <div class="popup-container extended-confirmation-container hide" data-extended-confirmation-insured-container>
        <div class="popup step-one" data-extended-confirmation-insured-step-one>
            <div class="loader" data-loader></div>
            <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
            <p class="popup-text gray" v-html="$t('MOBILE_CITIZEN_INSURED_CONFIRM_TEXT')"/>
            <div class="calc__label">
                {{ $t('MOBILE_CITIZEN_CONFIRM_IIN') }}
                <input data-field="iin" name="iin" type="text" class="calc__input imask-iin extended-confirmation-insured-iin">
                <span data-error="extended-confirmation-iin-error-field" class="calc__input-error extended-confirmation-iin-error-field"></span>
            </div>
            <div class="btn-container">
                <div class="extended-confirmation-submit-container">
                    <button class="extended-confirmation-cancel calc__button_cancel" data-btn="data_collection_cancel">
                        {{ $t('CANCEL') }}</button>
                </div>
                <div class="extended-confirmation-submit-container">
                    <button class="extended-confirmation-submit-insured calc__button inactive" data-btn="data_collection_confirm_insured">
                        {{ $t('I_CONFIRM') }}</button>
                </div>
            </div>
        </div>
        <div class="popup step-two hide" data-extended-confirmation-insured-step-two>
            <div class="extended-confirmation-wait-container">
                <img src="~@/assets/svg/wait.svg" alt="wait">
            </div>
            <p class="popup-title gray">{{ $t('MOBILE_CITIZEN_INSURED_CONFIRM_SMS') }}</p>
            <p class="popup-text gray">{{ $t('MOBILE_CITIZEN_INSURED_CONFIRM_SMS_KDP') }}</p>
            <p class="popup-text gray">{{ $t('MOBILE_CITIZEN_CONFIRM_CALL') }}</p>
        </div>
        <div class="popup step-two-deny hide" data-extended-confirmation-insured-step-deny>
            <p class="popup-title black">{{ $t('DEAR_CLIENT') }}</p>
            <p class="popup-text gray">{{ $t('MOBILE_CITIZEN_INSURED_CONFIRM_DENY_TEXT') }}</p>
           <div class="btn-container">
               <div class="extended-confirmation-submit-container">
                   <button class="extended-confirmation-cancel calc__button_cancel" data-btn="data_collection_cancel_second">
                       {{ $t('CANCEL') }}</button>
               </div>
               <div class="extended-confirmation-submit-container">
                   <button class="extended-confirmation-submit calc__button" data-btn="data_collection_retry">
                       {{ $t('MOBILE_CITIZEN_CONFIRM_DENY_RETRY') }}</button>
               </div>
           </div>
        </div>
    </div>

</template>
<script>
    export default {
        name: 'ExtendedConfirmationInsuredModal',
    }
</script>
<style scoped>
    .step-two-deny .extended-confirmation-submit-container .extended-confirmation-cancel{
        margin: auto;
        margin-top: 24px;
    }
</style>
