<template>
    <div>
        <b-modal modal-class="insurance-case-crm-modal"
                 ref="insurance-case-crm-modal"
                 id="insurance-case-crm-modal"
                 centered
                 hide-footer
                 hide-header>

            <div class="rights-blocker" v-if="loadingCase">
                <b-spinner />
            </div>
            <div class="close-modal" @click="$bvModal.hide('insurance-case-crm-modal')"></div>
            <h3 class="modal-title">Создать заявку</h3>
            <h4 class="modal-subtitle">Поиск договора</h4>
            <p class="label">Заполните одно из трёх полей</p>
            <div class="rights-blocker" v-if="loading">
                <b-spinner />
            </div>
            <div class="application-2-content">
                <form>
                    <div class="application-2-params top">
                        <div class="application-2-params-right">
                            <div class="application-2-form-date">
                                <div class="form-group">
                                    <label for="tfNumber" class="label">Гос. номер</label>
                                    <input v-model="params.tfNumber" class="input"
                                           id="tfNumber"
                                           autocomplete="off"
                                           @input="(val) => (params.tfNumber = params.tfNumber.toUpperCase())"/>
                                </div>
                                <div class="form-group">
                                    <label for="contractNumber" class="label">Номер договора страхования</label>
                                    <input v-model="params.contractNumber" class="input"
                                           :class="{'error': errors.contractNumber}"
                                           id="contractNumber"
                                           autocomplete="off"/>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="iin" class="label">ИИН/БИН страхователя или застрахованного</label>
                                <the-mask v-model="params.iin" class="input"
                                          :class="{'error': errors.iin}" :mask="['############']"
                                          id="iin"
                                          autocomplete="off"/>
                            </div>
                            <div class="application-2-form-date">
                                <div class="form-group mt-25">
                                    <button @click.prevent="getContractsByParams" class="application-2-form-btn second">
                                        Поиск
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.74214 3.95016C7.85829 5.88499 6.36908 7.55321 4.40756 7.66886C2.44604 7.7845 0.767758 6.30303 0.651606 4.3682C0.535454 2.43336 2.02466 0.765144 3.98618 0.649498C5.9477 0.533851 7.62598 2.01532 7.74214 3.95016Z" stroke="#515D75" stroke-width="0.833333"/> <path d="M6.79297 6.95483L10.001 9.77696" stroke="#515D75" stroke-width="0.833333"/> </svg>
                                    </button>
                                </div>
                                <div class="form-group mt-25">
                                    <button @click.prevent="resetContracts" class="application-2-form-btn">
                                        Сброс
                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 1.12695L7.99967 8.00022" stroke="#515D75" stroke-width="0.888889" stroke-linecap="round"/> <path d="M8 1L1.00033 7.87327" stroke="#515D75" stroke-width="0.888889" stroke-linecap="round"/> </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="application-2-params-left">
                            <div class="form-group list-polices">
                                <label class="label">Список полисов</label>
                                <multiselect
                                    v-model="form.contract"
                                    :options="params.contracts"
                                    :multiple="false"
                                    :searchable="false"
                                    label="NAME"
                                    track-by="CONTRACT_ID"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    :placeholder="'Выберите полис'"
                                    :class="{'error': errors.contract_name}"
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <p class="modal-subtitle mb-25">Основная информация</p>
                <form>
                    <div class="application-2-form">
                        <div class="application-2-form-left">
                            <div class="form-group mt-0 bold" :class="{'error': errors.insurance_type_id}">
                                <label class="label">Наименование заявки</label>
                                <multiselect
                                    v-model="form.insCaseTypeId"
                                    :options="params.insCaseTypeId"
                                    :multiple="false"
                                    :searchable="false"
                                    label="name"
                                    track-by="id"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                />
                            </div>
                            <div class="custom-control custom-switch form-group" v-if="form.insCaseTypeId.id && form.insCaseTypeId.id !== 25">
                                <input type="checkbox" v-model="form.createdByHand" class="custom-control-input" id="createdByHand">
                                <label for="createdByHand" class="custom-control-label"><span class="label hand">Ручная заявка</span></label>
                            </div>
                            <div class="form-group bold">
                                <label for="contractName" class="label">Номер договора</label>
                                <input v-model="form.contract.NAME" class="input"
                                       disabled id="contractName"/>
                            </div>
                            <div class="application-2-form-date">
                                <div class="form-group date bold" :class="{'error': errors.contract_date}">
                                    <label for="date" class="label">Дата страхового случая</label>
                                    <flat-pickr
                                        id="date"
                                        v-model="form.date"
                                        :config="configDate"
                                        class="picker"
                                        :placeholder="'Выберите дату'"
                                        name="date"/>
                                </div>
                                <div class="form-group bold" :class="{'error': errors.time}">
                                    <label for="time" class="label">Время</label>
                                    <the-mask
                                        id="time"
                                        v-model="form.time"
                                        :mask="['##:##']"
                                        class="input"
                                        :placeholder="'Выберите время'"
                                        name="time"/>
                                </div>
                            </div>
                            <div class="application-2-form-date">
                                <div class="form-group date bold" :class="{'error': errors.start_date}">
                                    <label for="start_date" class="label">Период действия</label>
                                    <flat-pickr
                                        disabled
                                        id="start_date"
                                        v-model="form.contract.START_DATE"
                                        :config="configContractDate"
                                        class="picker"
                                        :placeholder="'Дата начала'"
                                    />
                                </div>
                                <div class="form-group date bold" :class="{'error': errors.end_date}">
                                    <label for="end_date" class="label"></label>
                                    <flat-pickr
                                        disabled
                                        id="end_date"
                                        v-model="form.contract.END_DATE"
                                        :config="configContractDate"
                                        class="picker"
                                        :placeholder="'Дата окончания'"
                                    />
                                </div>
                            </div>
                            <div class="form-group" v-if="form.insCaseTypeId.id !== 1 && form.insCaseTypeId.id !== 20 && form.insCaseTypeId.id !== 21 && form.insCaseTypeId.id !== 22">
                                <label class="label">Номер договора добровольного типа</label>
                                <multiselect
                                    v-model="form.voluntaryContract"
                                    :options="params.voluntaryContracts"
                                    :multiple="false"
                                    :searchable="false"
                                    label="NAME"
                                    track-by="CONTRACT_ID"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    :placeholder="'Выберите полис'"
                                    :class="{'error': errors.v_contract_name}"
                                />
                            </div>
                            <div class="form-group link" v-if="form.voluntaryContract && form.insCaseTypeId.id !== 20 && form.insCaseTypeId.id !== 21 && form.insCaseTypeId.id !== 22">
                                <label class="label">Ссылка</label>
                                <a href="javascript:void(0)" @click="openVoluntaryModal">{{ form.voluntaryContract.NAME }}</a>
                            </div>
                            <div class="form-group date" v-if="form.insCaseTypeId.id !== 1 && form.insCaseTypeId.id !== 20 && form.insCaseTypeId.id !== 21 && form.insCaseTypeId.id !== 22">
                                <label class="label">Период действия договора добровольного типа</label>
                                <div class="application-2-form-date">
                                    <div class="form-group mt-0">
                                        <flat-pickr
                                            disabled
                                            v-model="form.voluntaryContract.START_DATE"
                                            :config="configContractDate"
                                            class="picker"
                                            :placeholder="'Дата начала'"
                                        />
                                    </div>
                                    <div class="form-group date mt-0">
                                        <flat-pickr
                                            disabled
                                            v-model="form.voluntaryContract.END_DATE"
                                            :config="configContractDate"
                                            class="picker"
                                            :placeholder="'Дата окончания'"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group bold">
                                <label for="clientName" class="label">Страхователь</label>
                                <input v-model="form.contract.CLIENT_NAME" class="input clientName"
                                       disabled id="clientName"/>
                            </div>
                            <div class="form-group" v-if="form.contract.INSURED && form.contract.INSURED.length > 0">
                                <label class="label">Застрахованный на момент СС</label>
                                <multiselect
                                    v-model="form.insured"
                                    :options="form.contract.INSURED"
                                    :multiple="false"
                                    :searchable="false"
                                    label="FULL_NAME"
                                    track-by="CLIENT_ID"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    :placeholder="'Выберите полис'"
                                    :class="{'error': errors.insured}"
                                />
                            </div>
                            <div class="form-group bold" :class="{'error': errors.declarer}">
                                <label class="label">Контактные данные страхователя</label>
                                <input v-model="form.declarer" class="input"/>
                            </div>
                            <div class="form-group bold">
                                <label for="declarerPhone" class="label">Контактные данные заявителя</label>
                                <the-mask v-model="form.declarerPhone" class="input"
                                          :class="{'error': errors.declarer_phone}" :mask="['+7 (###) ###-##-##']"
                                          id="declarerPhone"
                                          :placeholder="'+7 ('"
                                          autocomplete="off"/>
                            </div>
                            <div class="form-group">
                                <label for="declarerPhone" class="label">Сотовый номер менеджера</label>
                                <the-mask v-model="form.managerPhone" class="input"
                                          :class="{'error': errors.manager_phone}" :mask="['+7 (###) ###-##-##']"
                                          id="managerPhone"
                                          :placeholder="'+7 ('"
                                          autocomplete="off"/>
                            </div>
                        </div>
                        <div class="application-2-form-right">
                            <div class="form-group mt-0" :class="{'error': errors.insurance_type_name}">
                                <label for="insuranceTypeName" class="label">Наименование продукта</label>
                                <input v-model="form.contract.INSURANCE_TYPE_NAME" class="input"
                                       disabled id="insuranceTypeName"/>
                            </div>
                            <div class="form-group bold" v-if="!checkInsCaseTypeKasko(form.insCaseTypeId.id)">
                                <label class="label">Вид СС</label>
                                <multiselect
                                    v-model="form.typeInsuredEvent"
                                    :options="params.typeInsuredEvent"
                                    :multiple="false"
                                    :searchable="false"
                                    label="name"
                                    track-by="id"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    :class="{'error': errors.typeInsuredEvent}"
                                />
                            </div>
                            <div class="form-group bold" v-if="form.contract.TF" :class="{'error': errors.tf}">
                                <label class="label">ТС</label>
                                <multiselect
                                    v-model="form.tf"
                                    :options="form.contract.TF"
                                    :multiple="false"
                                    :searchable="false"
                                    label="TF_NUMBER"
                                    track-by="TF_ID"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    :class="{'error': errors.tf}"
                                    :custom-label="showTF"
                                />
                            </div>
                            <div class="form-group bold">
                                <label for="address" class="label">Адрес СС</label>
                                <input v-model="form.address" class="input"
                                       id="address"
                                       :class="{'error': errors.address}"
                                       autocomplete="off"/>
                            </div>
                            <div class="form-group">
                                <label for="specConditions" class="label">Особенные условия</label>
                                <textarea v-model="form.specConditions" class="input textarea"
                                          id="specConditions"></textarea>
                            </div>
                            <div class="form-group" v-if="checkInspectionComment(form.insCaseTypeId.id)">
                                <label for="inspectionComment" class="label">Комментарии</label>
                                <input v-model="form.inspectionComment" class="input"
                                       id="inspectionComment"
                                       autocomplete="off"/>
                            </div>
                            <div class="form-group" v-if="form.insCaseTypeId.id !== 1 && form.insCaseTypeId.id !== 20 && form.insCaseTypeId.id !== 21 && form.insCaseTypeId.id !== 22" :class="{'error': errors.sign_of_guilt}">
                                <label class="label">Признак виновности</label>
                                <multiselect
                                    v-model="form.signGuilt"
                                    :options="params.signGuilt"
                                    :multiple="false"
                                    :searchable="false"
                                    label="name"
                                    track-by="id"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    :class="{'error': errors.sign_of_guilt}"
                                    :custom-label="translateSelect"
                                />
                            </div>
                            <div class="form-group bold" :class="{'error': errors.city_id}">
                                <label class="label">Город</label>
                                <multiselect
                                    v-model="form.city"
                                    :options="params.cities"
                                    :multiple="false"
                                    :searchable="false"
                                    label="name"
                                    track-by="id"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    :class="{'error': errors.city_id}"
                                    :custom-label="translateSelect"
                                />
                            </div>
                            <div class="form-group" v-if="form.insCaseTypeId.id !== 1 && form.insCaseTypeId.id !== 20 && form.insCaseTypeId.id !== 21 && form.insCaseTypeId.id !== 22">
                                <label for="secondParticipant" class="label">Второй участник</label>
                                <input v-model="form.secondParticipant" class="input"
                                       id="secondParticipant"
                                       autocomplete="off"/>
                            </div>
                            <div v-if="form.insCaseTypeId.id !== 3">
                                <div class="form-group" :class="{'error': errors.inspection_address}">
                                    <p class="modal-subtitle-osmotr">Осмотр</p>
                                    <label for="address" class="label">Адрес осмотра</label>
                                    <input v-model="form.inspectionAddress" class="input"
                                           id="inspectionAddress"
                                           autocomplete="off"/>
                                </div>
                                <div class="form-group date" :class="{'error': errors.inspection_date}">
                                    <label for="inspectionDate" class="label">Дата осмотра</label>
                                    <flat-pickr
                                        id="inspectionDate"
                                        v-model="form.inspectionDate"
                                        :config="configDate"
                                        class="picker"
                                        :placeholder="'Выберите дату'"
                                        name="inspectionDate"/>
                                </div>
                                <div class="application-2-form-date">
                                    <div class="form-group" :class="{'error': errors.inspection_start_ts}">
                                        <label for="inspectionStartTime" class="label">Период времени для осмотра с</label>
                                        <the-mask
                                            id="inspectionStartTime"
                                            v-model="form.inspectionStartTime"
                                            :mask="['##:##']"
                                            class="input"
                                            :placeholder="'Выберите время'"
                                            name="inspectionStartTime"/>
                                    </div>
                                    <div class="form-group"  :class="{'error': errors.inspection_end_ts}">
                                        <label for="inspectionEndTime" class="label">Период времени для осмотра по</label>
                                        <the-mask
                                            id="inspectionEndTime"
                                            v-model="form.inspectionEndTime"
                                            :mask="['##:##']"
                                            class="input"
                                            :placeholder="'Выберите время'"
                                            name="inspectionEndTime"/>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-if="form.contract.OBJECT && Object.keys(form.contract.OBJECT).length > 0">
                                <label class="label">Объекты страхования:</label>
                                <div class="form-group__object" v-for="(item, key) in form.contract.OBJECT" :key="key">
                                    <p class="label"><span>Тип:</span> {{ item.TYPE }}</p>
                                    <p class="label"><span>Категория:</span> {{ item.CATEGORY }}</p>
                                    <p class="label"><span>Описание:</span> {{ item.DESCRIPTION }}</p>
                                    <p class="label"><span>Адрес:</span> {{ item.ADDRESS }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-btns">
                        <button @click.prevent="setInsuredCase(true)"  :title="'Создать'" class="w-202 fs-14 h-38 mt-25">Сохранить</button>
                        <button @click.prevent="changeStatusInsuredCase(TO_RASP)" class="w-202 fs-14 h-38 mt-25">Отправить на распределение</button>
                        <button @click.prevent="changeStatusInsuredCase(TO_ONLINE_CONSULT)" class="w-202 fs-14 h-38 mt-25">Онлайн консультация</button>
                    </div>
                </form>
            </div>
        </b-modal>

        <b-modal
            modal-class="insurance-case-success-crm-modal"
            id="insurance-case-success-crm-modal"
            centered
            hide-footer
            hide-header>
            <h3 class="modal-title">{{ $t(responseMessage) }}</h3>
            <button @click="$bvModal.hide('insurance-case-success-crm-modal')" class="w-202 fs-14 h-38 mt-25">Oк</button>
        </b-modal>
        <ModalPolicyVoluntaryDetail
            v-if="Object.keys(form.voluntaryContract).length > 0"
            :contract="form.voluntaryContract"/>
    </div>
</template>

<script>

import {TheMask} from 'vue-the-mask'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian} from 'flatpickr/dist/l10n/ru.js';
import dateHelpers from "@/helpers/date-helpers";
import {mapActions, mapGetters} from "vuex";
import ModalPolicyVoluntaryDetail from "@/components/operator/ModalPolicyVoluntaryDetail";

export default {
    name: "CrmInsuranceCaseModal",
    data() {
        return {
            insuranceCase: false,
            form: {
                contract: {},
                voluntaryContract: {},
                insured: {},
                typeInsuredEvent: {},
                insCaseTypeId: {},
                tf: {},
                signGuilt: {},
                city: {},
                declarerPhone: '',
                declarer: '',
                date: '',
                time: '',
                specConditions: '',
                secondParticipant: '',
                address: '',
                createdByHand: false,
                managerPhone: '',
                inspectionAddress: '',
                inspectionDate: '',
                inspectionStartTime: '',
                inspectionEndTime: '',
                inspectionComment: '',
            },
            params: {
                contracts: [],
                voluntaryContracts: [],
                tempVoluntaryContracts: [],
                cities: [],
                signGuilt: [],
                typeInsuredEvent: [],
                insCaseTypeId: [],
                iin: '',
                tfNumber: '',
                contractNumber: ''
            },
            loading: false,
            loadingCase: false,
            errors: {},
            configDate: {
                wrap: false,
                altFormat: 'd/m/Y',
                altInput: true,
                dateFormat: 'd/m/Y',
                position: 'auto right',
                static: true,
                shorthandCurrentMonth: true,
                locale: Russian
            },
            configContractDate: {
                wrap: false,
                altFormat: 'd/m/Y',
                altInput: true,
                dateFormat: 'd/m/Y',
                position: 'auto right',
                static: true,
                shorthandCurrentMonth: true,
                locale: Russian
            },
            responseMessage: "",
            TO_RASP: 'TO_RASP',
            TO_ONLINE_CONSULT: 'TO_ONLINE_CONSULT',
            TO_DELETED: 'TO_DELETED',
        }
    },
    components: {
        TheMask,
        Multiselect,
        flatPickr,
        ModalPolicyVoluntaryDetail
    },
    mounted() {
        this.getDirectories()

        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (modalId === 'insurance-case-crm-modal')
                this.setInsuranceCase(false)
        })

        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            if (modalId === 'insurance-case-crm-modal') {
                this.insuranceCase = this.$store.getters["modalOptions/currentApplication"]
                this.resetContracts()
                this.fillContract()
            }
        })
    },
    methods: {
        ...mapActions({
            setInsuranceCase: 'modalOptions/setCurrentApplication'
        }),
        ...mapGetters({
            getInsuranceCase: 'modalOptions/currentApplication'
        }),
        openVoluntaryModal() {
            this.$bvModal.show('policy-voluntary-detail')
        },
        getDirectories() {
            this.$axios.get('/insurance-cases/crm/directories/get')
            .then(response => {
                this.params.typeInsuredEvent = response.data.data.type_insured_event
                this.params.cities = response.data.data.city
                this.params.signGuilt = response.data.data.sign_of_guilt
                this.params.insCaseTypeId = response.data.data.ins_case_type_id

                this.form.typeInsuredEvent = this.params.typeInsuredEvent[0]
                this.form.insCaseTypeId = this.params.insCaseTypeId[1]
                this.form.city = this.params.cities[0]
                this.form.signGuilt = this.params.signGuilt[0]
                this.form.time = dateHelpers.getCurrentTime()
                this.form.inspectionStartTime = dateHelpers.getCurrentTime()
                this.form.inspectionEndTime = dateHelpers.getCurrentTime()
                this.form.date = dateHelpers.today().replace(/[.]/g,'/')
            })
        },
        getContractsByParams() {
            this.loading = true
            this.$axios.get('/insurance-cases/crm/contracts/get', {params:
                {
                    iin: this.params.iin,
                    tf_number: this.params.tfNumber,
                    contract_number: this.params.contractNumber,
                }
            }
            ).then(response => {
                this.params.contracts = response.data.contracts
                this.params.tempVoluntaryContracts = response.data.voluntaryContracts

                this.form.contract = this.params.contracts[0]
                this.form.tf = this.form.contract.TF[0]
                this.form.voluntaryContract = {}
            }).catch(errors => {
                this.resetContracts()
                alert(this.$t(errors.response.data.message))
            }).finally(() => {
                this.loading = false
            })
        },
        async setInsuredCase(isCreate) {
            let result = {}
            this.loadingCase = true
            await this.$axios.post('/insurance-cases/crm/set', {
                contract_id: this.form.contract.CONTRACT_ID,
                contract_date: typeof this.form.contract.CONTRACT_DATE !== 'undefined' ? this.form.contract.CONTRACT_DATE.replace(/[\\/]/g,'.') : '',
                contract_name: this.form.contract.NAME,
                start_date: typeof this.form.contract.START_DATE !== 'undefined' ? this.form.contract.START_DATE.replace(/[\\/]/g,'.') : '',
                end_date: typeof this.form.contract.END_DATE !== 'undefined' ? this.form.contract.END_DATE.replace(/[\\/]/g,'.') : '',
                date: this.form.date !== null ? this.form.date.replace(/[\\/]/g,'.') : '',
                time: this.reformatTime(this.form.time),
                client_id: this.form.contract.CLIENT_ID,
                client_name: this.form.contract.CLIENT_NAME,
                tf_id: this.form?.tf?.TF_ID,
                tf_number: this.form?.tf?.TF_NUMBER,
                tf: this.form?.tf?.MARK ? `${this.form?.tf?.MARK} ${this.form?.tf?.MODEL} ${this.form?.tf?.TF_NUMBER}` : '',
                insured_id: this.form.insured.CLIENT_ID ?? '',
                insured: this.form.insured.FULL_NAME ?? '',
                declarer: this.form.declarer,
                declarer_phone: this.form.declarerPhone,
                spec_conditions: this.form.specConditions,
                address: this.form.address,
                insurance_type_id: this.form.contract.INSURANCE_TYPE_ID,
                insurance_type_name: this.form.contract.INSURANCE_TYPE_NAME,
                v_contract_id: this.form.voluntaryContract.CONTRACT_ID ?? '',
                v_contract_name: this.form.voluntaryContract.NAME ?? '',
                second_participant: this.form.secondParticipant,
                sign_of_guilt: this.form.signGuilt.id,
                ins_case_type_id: this.form?.insCaseTypeId?.id ?? '',
                city_id: typeof this.form.city !== 'undefined' ? this.form.city.id : '',
                created_by_hand: this.form.createdByHand,
                manager_phone: this.form.managerPhone,
                inspection_address: this.form.inspectionAddress,
                inspection_date: this.form.inspectionDate.replace(/[\\/]/g,'.'),
                inspection_start_ts: this.reformatDateInspection(this.form.inspectionDate, this.form.inspectionStartTime),
                inspection_end_ts: this.reformatDateInspection(this.form.inspectionDate, this.form.inspectionEndTime),
                inspection_comment: this.form.inspectionComment.replace(/[\\/]/g,'.'),
                is_create: isCreate,
            }).then(response => {
                this.responseMessage = response.data.message
                result = {
                    success: true,
                    id: response.data.id
                }
                if (isCreate) {
                    this.$bvModal.hide('insurance-case-crm-modal')
                    this.$bvModal.show('insurance-case-success-crm-modal')
                    this.clearForm()
                    setTimeout(()=> {
                        window.location.reload();
                    }, 2000)
                }
            }).catch(errors => {
                if (typeof errors.response.data.errors == 'string') {
                    this.$bvModal.show('insurance-case-success-crm-modal')
                    this.responseMessage = errors.response.data.errors
                } else {
                    this.errors = errors.response.data.errors
                    for (let error in this.errors) {
                        result = {
                            success: false,
                            errors: this.errors
                        }
                        alert(this.$t(this.errors[error]))
                        break
                    }
                }
            }).finally(() => {
                this.loadingCase = false
            })

            return result
        },
        async changeStatusInsuredCase(status) {
            this.loadingCase = true
            let result = await this.setInsuredCase(false)
            if (result.success) {
                this.$axios.post(`/insurance-cases/crm/change-status`, {
                    id: result.id,
                    status: status
                }).then(response => {
                    this.responseMessage = response.data.message
                    this.$bvModal.hide('insurance-case-crm-modal')
                    this.$bvModal.show('insurance-case-success-crm-modal')
                    this.clearForm()
                    setTimeout(()=> {
                        window.location.reload();
                    }, 2000)
                }).catch(errors => {
                    this.deleteInsuredCase(errors.response.data.id)
                    if (errors.response.data.message) {
                        this.responseMessage = errors.response.data.message
                        this.$bvModal.hide('insurance-case-crm-modal')
                        this.$bvModal.show('insurance-case-success-crm-modal')
                    }
                    if (errors.response.data.errors) {
                        this.errors = errors.response.data.errors
                        for (let error in this.errors) {
                            alert(this.$t(this.errors[error]))
                            break
                        }
                    }
                }).finally(() => {
                    this.loadingCase = false
                })
            }
        },
        resetContracts() {
            this.params.iin = ''
            this.params.tfNumber = ''
            this.params.contracts = []
            this.params.contractNumber = ''
            this.form.tf = {}
            this.form.insured = {}
            this.form.contract = {}
            this.form.createdByHand = false
            this.form.typeInsuredEvent = {}
            this.form.voluntaryContract = {}
            this.form.declarerPhone = ''
            this.params.voluntaryContracts = {}
            this.params.tempVoluntaryContracts = {}
            this.form.managerPhone = ''
            this.form.inspectionAddress = ''
            this.form.inspectionDate = ''
            this.form.inspectionComment = ''
        },
        fillContract() {
            if (!this.insuranceCase)
                return

            this.form.city = this.params.cities.find(city => city.id === this.insuranceCase.CITY_ID)
            this.form.insCaseTypeId = this.params.insCaseTypeId.find(insCaseType => insCaseType.name === this.insuranceCase.INSURANCE_CASE_TYPE)
            this.form.inspectionAddress = this.insuranceCase.ADDRESS
            this.form.declarer = this.insuranceCase.DECLARER
            this.form.declarerPhone = this.insuranceCase.DECLARER_PHONE !== null
                ? this.insuranceCase.DECLARER_PHONE.replace('8', '+7')
                : ''
            this.form.inspectionDate = this.dateHelper.today()
        },
        showTF({MARK, MODEL, TF_NUMBER}) {
            return `${MARK} ${MODEL} ${TF_NUMBER}`
        },
        translateSelect({name}) {
            return this.$t(name)
        },
        checkInsCaseType(id) {
            let array = [23, 24, 1]
            return array.includes(id)
        },
        checkInsCaseTypeKasko(id) {
            // id указаны в config(crm.directory.ins_case_type_id)
            let array = [20, 21, 22, 23, 24, 1, 25]
            return array.includes(id)
        },
        checkInspectionAddress(id) {
            let array = [21, 24, 1]
            return array.includes(id)
        },
        checkInspectionAddressKasko(id) {
            let array = [21, 24, 1, 25]
            return array.includes(id)
        },
        checkManagerPhone(id) {
            let array = [20, 21, 22, 1]
            return array.includes(id)
        },
        checkInspectionComment(id) {
            let array = [21, 24]
            return array.includes(id)
        },
        reformatDateInspection(date, time) {
            if (!date) {
                return null
            }
            let validDate = date.replace(/[\\/]/g,'.')
            let validTime = this.reformatTime(time)
            return `${validDate} ${validTime}`
        },
        reformatTime(time) {
            if (time) {
                time = time.replace(/[^0-9]/g, '');
                return `${time[0]}${time[1]}:${time[2]}${time[3]}`
            }
            return ''
        },
        clearForm() {
            this.form = {
                contract: {},
                voluntaryContract: {},
                insured: {},
                typeInsuredEvent: {},
                insCaseTypeId: {},
                tf: {},
                signGuilt: {},
                city: {},
                declarerPhone: '',
                declarer: '',
                date: '',
                time: '',
                specConditions: '',
                secondParticipant: '',
                address: '',
                createdByHand: false,
                managerPhone: '',
                inspectionAddress: '',
                inspectionDate: '',
                inspectionStartTime: '',
                inspectionEndTime: '',
                inspectionComment: '',
            }
        },
        deleteInsuredCase(id) {
            this.$axios.post(`/insurance-cases/crm/change-status`, {
                id: id,
                status: this.TO_DELETED
            }).then(response => {
                console.log(response.data)
            }).catch(errors => {
                console.log(errors.response.data)
            })
        }
    },
    watch: {
        'params.tfNumber'() {
            if (this.params.tfNumber.length === 0) {
                this.resetContracts()
            }
        },
        'params.contractNumber'() {
            if (this.params.contractNumber.length === 0) {
                this.resetContracts()
            }
        },
        'params.iin'() {
            if (this.params.iin.length === 0) {
                this.resetContracts()
            }
        },
        'form.contract'() {
            let self = this
            self.form.insured = {}
            self.params.voluntaryContracts = []
            if (self.params.tempVoluntaryContracts.length > 0) {
                self.params.tempVoluntaryContracts.forEach(function (voluntaryContract) {
                    if (voluntaryContract.CONTRACT_ID != self.form.contract.CONTRACT_ID) {
                        self.params.voluntaryContracts.push(voluntaryContract)
                    }
                })
            }
            self.form.declarer = self.form.insured.FULL_NAME ?? self.form.contract.CLIENT_NAME
        },
        'form.insCaseTypeId'() {
            if (this.form.insCaseTypeId.id === 25) {
                this.form.createdByHand = false
                this.form.typeInsuredEvent = {}
            }
            if (this.form.insCaseTypeId.id === 20 || this.form.insCaseTypeId.id === 21 || this.form.insCaseTypeId.id === 22 ||
                this.form.insCaseTypeId.id === 23 || this.form.insCaseTypeId.id === 24 || this.form.insCaseTypeId.id === 1) {
                this.form.typeInsuredEvent = {}
                this.form.address = ''
            }
            if (this.form.insCaseTypeId.id === 1) {
                this.form.voluntaryContract = {}
                this.form.time = ''
                this.form.date = ''
                this.form.signGuilt = {}
                this.form.secondParticipant = ''
            }

            if (this.form.insCaseTypeId.id === 20 || this.form.insCaseTypeId.id === 21 || this.form.insCaseTypeId.id === 22) {
                this.form.voluntaryContract = {}
                this.form.signGuilt = {}
                this.form.secondParticipant = ''
            }
        }
    }
}
</script>

<style lang="scss">
@import "~vue-multiselect/dist/vue-multiselect.min.css";
#insurance-case-crm-modal {
    .modal-dialog {
        max-width: 910px;
    }
    .form-group.error .multiselect .multiselect__tags {
        border-color: red !important;
    }
    .modal-title {
        margin-bottom: 35px;
        font-weight: 600;
        font-size: 20px;
        line-height: 127.1%;
        color: #000000;
    }
    .modal-subtitle {
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 16px;
        line-height: 127.1%;
        color: #000000;
        &.mb-25 {
            margin-bottom: 25px;
        }
    }

    .application-2-content {
        display: flex;
        flex-direction: column;
    }
    .form-group {
        position: relative;
        margin-top: 18px;
        &.mt-0 {
            margin-top: 0;
        }
        &.mt-25 {
            margin-top: 25px;
        }
        &.link {
            a {
                font-size: 13px;

                &:hover {
                    color: $orange;
                }
            }
        }
        &.date {
            .flatpickr-wrapper {
                &:before {
                    background: url("~@/assets/images/calendar.svg") no-repeat;
                    background-size: contain;
                    position: absolute;
                    content: "";
                    width: 16px;
                    height: 15px;
                    right: 10px;
                    z-index: 1;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
    }

    .modal-content {
        position: relative;
    }

    .close-modal {
        position: absolute;
        content: '';
        top: 28px;
        right: 27px;
        width: 34px;
        height: 34px;
        background: url('~@/assets/images/x-modal.svg') center/ 18px 18px no-repeat;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.2s;

        &:hover {
            background-color: #F3F5F9;
        }
    }

    .modal-body {
        padding: 30px 35px 25px;
    }

    .close-tab {
        margin-left: 10px;
        width: 8px;
        height: 8px;
        background: url('~@/assets/images/x-tab.svg') center / auto no-repeat;
    }

    .add-tab-icon {
        margin-left: 5px;
        width: 15px;
        height: 15px;
        background: url('~@/assets/images/add-tab.svg') center / auto no-repeat;
    }

    .add-tab-icon-sm {
        display: inline-block;
        margin-right: 7px;
        width: 9px;
        height: 9px;
        background: url('~@/assets/images/add-tab-sm.svg') center / auto no-repeat;
    }

    .nav-tabs .nav-item.add-tab {
        > a {
            min-width: auto;
            padding: 8px 10px;
        }
    }

    .tab-first-form {
        margin-top: 15px;
        display: grid;
        grid-template-columns: repeat(2, 381px);
        grid-template-rows: 68px repeat(5, 50px);
        grid-column-gap: 64px;
        grid-row-gap: 18px;

        .form-group {
            &:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
            }

            &:nth-child(2) {
                grid-area: 2 / 1 / 3 / 2;
            }

            &:nth-child(3) {
                grid-area: 3 / 1 / 4 / 2;
            }

            &:nth-child(4) {
                grid-area: 4 / 1 / 5 / 2;
            }

            &:nth-child(5) {
                grid-area: 5 / 1 / 6 / 2;
            }

            &:nth-child(7) {
                grid-area: 2 / 2 / 3 / 3;
            }

            &:nth-child(8) {
                grid-area: 3 / 2 / 4 / 3;
            }

            &:nth-child(9) {
                grid-area: 4 / 2 / 5 / 3;
            }

            &:nth-child(10) {
                grid-area: 5 / 2 / 6 / 3;
            }
        }
    }

    .input {
        padding: 0 12px;
        width: 100%;
        height: 30px;
        border: 1px solid #D0D1E2;
        border-radius: 30px;
        font-weight: 400;
        font-size: 12px;
        transition: border-color .2s;
        &.clientName {
            border-color: #EF7F22;
            color: #000;
        }
        &.error {
            border-color: red;
        }
    }

    .label {
        margin-bottom: 5px;
        display: block;
        width: fit-content;
        font-weight: 400;
        font-size: 14px;
        line-height: 127.1%;
        color: #000000;
        &.mb-25 {
            margin-bottom: 25px;
        }
        &.hand {
            margin-top: 2px;
        }
    }

    .input.textarea {
        height: 94px;
        border-radius: 10px;
        resize: none;
        padding: 3px 12px;
    }

    .form-row-50 {
        margin: 0 -5px;
        grid-area: 6 / 1 / 7 / 2;
        display: flex;

        & > .form-group {
            padding: 0 5px;
            flex: 1 0 50%;
        }
    }

    .form-group-radio {
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .form-group.bold {
        label {
            font-weight: 600 !important;
        }
    }

    .radio-label {
        display: flex;
        position: relative;
        align-items: center;
    }

    .radio-input {
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
    }

    .radio-style {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 19px;
        height: 19px;
        background-color: inherit;
        border-radius: 50%;
        border: 1px solid #D0D1E2;
        cursor: pointer;
    }

    .radio-style::after {
        content: '';
        width: 13px;
        height: 13px;
        background-color: $orange;
        border-radius: 50%;
        opacity: 0;
    }

    .radio-input:checked + .radio-style::after {
        opacity: 1;
    }

    .radio-text {
        margin-left: 6px;
        font-weight: 400;
        font-size: 12px;
        line-height: 127.1%;
    }

    .radio-wrapper + .radio-wrapper {
        margin-left: 26px;
    }

    .tab-other {
        margin-bottom: 67px;

        .title {
            margin-top: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 127.1%;
            color: #000000;
        }
    }

    .tab-other-form {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 381px);
        grid-template-rows: repeat(3, 50px) 114px;
        grid-column-gap: 64px;
        grid-row-gap: 18px;

        & > .form-group {
            &:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
            }

            &:nth-child(2) {
                grid-area: 2 / 1 / 3 / 2;
            }

            &:nth-child(3) {
                grid-area: 3 / 1 / 4 / 2;
            }

            &:nth-child(4) {
                grid-area: 4 / 1 / 5 / 2;
            }

            &:nth-child(5) {
                grid-area: 1 / 2 / 2 / 3;
            }

            &:nth-child(6) {
                grid-area: 2 / 2 / 3 / 3;
            }

            &:nth-child(7) {
                grid-area: 3 / 2 / 4 / 3;
            }

            &:nth-child(8) {
                grid-area: 4 / 2 / 5 / 3;
            }
        }
    }

    .form-group-m + .form-group-m {
        margin-left: 37px;
    }

    .doc__item {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    .file-block {
        label {
            width: 100%;
        }
    }
    .application-2-params,
    .application-2-form,
    .application-2-form-date
    {
        display: flex;
        justify-content: space-between;
        width: 100%;
        &.mb-40 {
            margin-bottom: 40px;
        }
    }
    .application-2-params.top {
        border-bottom: 0.5px solid #ACB9CF;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }

    .application-2-form-date {
        align-items: end;
        .form-group {
            width: 48%;
            & .flatpickr-wrapper {
                width: 100%;
            }
        }
    }

    .application-2-params-right,
    .application-2-params-left ,
    .application-2-form-left ,
    .application-2-form-right {
        width: 48%;
    }

    .flatpickr-wrapper {
        position: relative;
        & input {
            background: #fff !important;
        }
    }
    .w-202 {
        margin-right: 0;
        &.mt-25 {
            margin-top: 25px;
        }
    }
    .application-2-form-btn {
        position: relative;
        width: 100%;
        transition: 0.5s ease;
        line-height: 1;
        padding: 7px;
        border: 1px solid #515D75;
        border-radius: 40px;
        background: #fff;
        font-size: 12px;
        color: #515D75;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        & svg {
            margin-left: 4px;
            margin-top: 2px;
            & path {
                transition: 0.5s ease;
            }
        }
        &.second {
            border-color: #EF7F22;
            color: #EF7F22;
            & svg path {
                stroke: #EF7F22;
            }
            &:hover {
                border-color: #EF7F22;
                color: #fff;
                background: #EF7F22;
                & svg path {
                    stroke: #fff;
                }
            }
        }
        &:hover {
            border-color: #EF7F22;
            color: #EF7F22;
            & svg path {
                stroke: #EF7F22;
            }
        }
    }
    .form-group.error .input {
        border-color: red !important;
    }
    .custom-control {
        position: relative;
        z-index: 1;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
    }

    .custom-control-inline {
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-right: 1rem;
    }

    .custom-control-input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1rem;
        height: 1.25rem;
        opacity: 0;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #ef863a;
        background-color: #ef863a;
    }
    .form-group__object span {
        font-weight: 700;
    }
    .form-group__object:not(:last-child) {
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid;
    }

    /*.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }*/

    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #aaacc9;
    }

    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        color: #fff;
        background-color: #fff;
        border-color: #aaacc9;
    }

    .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
        color: #ffffff;
    }

    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
        background-color: #ffffff;
    }

    .custom-control-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
    }

    .custom-control-label::before {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: calc(1rem - 3px);
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: #aaacc9 solid 1px;
    }

    .custom-control-label::after {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
        background: no-repeat 50% / 50% 50%;
    }

    .custom-checkbox .custom-control-label::before {
        border-radius: 0.25rem;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    }

    .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
        border-color: #aaacc9;
        background-color: #aaacc9;
    }

    .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
    }

    .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: #ffffff;
    }

    .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
        background-color: #ffffff;
    }

    .custom-radio .custom-control-label::before {
        border-radius: 50%;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: #ffffff;
    }

    .custom-switch {
        padding-left: 2.25rem;
    }

    .custom-switch .custom-control-label::before {
        left: -2.25rem;
        width: 1.6rem;
        pointer-events: all;
        border-radius: 0.5rem;
    }

    .custom-switch .custom-control-label::after {
        top: calc(0.25rem + 1px);
        left: calc(-2.25rem - 1px);
        width: calc(1rem - 4px);
        height: calc(1rem - 5px);
        background-color: #fff;
        border-radius: 0.5rem;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
        scale: 1.35;
        border: 1px solid #aaacc9;
    }
    .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #fff;
        -webkit-transform: translateX(0.75rem);
        transform: translateX(0.75rem);
    }

    .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: #ef863a;
    }
    .modal-subtitle-osmotr {
        color: #000;
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 3px;
    }
    .form-group-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 20px;
    }
    .form-group-btns button {
        margin-left: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 190px;
        height: 50px;
        background: $orange;
        border-radius: 50px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        transition: background .2s;
        cursor: pointer;

        .spinner-border {
            color: #ffffff;
            margin: 0 auto;
        }

        &:hover {
            background: $hoverOrange;
        }
    }

    .form-group.bold {
        input ,
        .multiselect__tags,
        .multiselect__tags .multiselect__single,
        .flatpickr-wrapper input{
            background: #FBF0DB !important;
        }
    }
}
.insurance-case-success-crm-modal {
    .modal-body {
        padding: 3em 2em;
        height: 310px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .modal-dialog {
        max-width: 390px;
    }
    .modal-title {
        margin-bottom: 45px;
        font-weight: 600;
        font-size: 24px;
        line-height: 127.1%;
        color: #000000;
        text-align: center;
    }
    .modal-content {
        position: relative;
    }
    button {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 256px;
        height: 50px;
        background: $orange;
        border-radius: 50px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        transition: background .2s;
        cursor: pointer;

        .spinner-border {
            color: #ffffff;
        }

        &:hover {
            background: $hoverOrange;
        }

        &.negative {
            color: #73758C;
            border: 1px solid #ACB9CF;
            background: transparent;

            &:hover {
                border-color: $orange;
                color: $orange;
            }
        }

        &.fs-14 {
            font-size: 14px;
        }

        &.h-38 {
            height: 38px;
        }

        &.w-162 {
            width: 162px;
        }

        &.w-202 {
            width: 202px;
        }

        &.w-238 {
            width: 238px;
        }

        &.w-245 {
            width: 245px;
        }

        &.m-right {
            margin-right: 0;
        }
    }
}
.form-group.list-polices {
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
@media (max-width: 991px) {
    .insurance-case-crm-modal {
        .label {
            font-size: calc(12px + 8 * ((100vw - 320px) / (768 - 320)));
        }

        .modal-body {
            padding: 30px 15px 20px;

            .close-modal {
                top: 30px;
                right: 5px;
                background-size: 16px 16px;
            }

            button {
                width: 100%;
            }
        }

        .modal-title {
            margin-bottom: 21px;
            font-weight: 600;
            font-size: calc(20px + 15 * ((100vw - 320px) / (768 - 320)));
            line-height: 127.1%;
            color: #000000;
        }

        button {
            margin-top: 18px;
        }

        .application-2-content {
            .file-block {
                margin-top: 10px;
            }
        }

        .close-modal {
            right: 7px;
        }
    }
}
</style>
