<template>
    <main class="verilive-wrapper-lk" :class="{'verilive-wrapper-lk-mob': isMobile}">
        <div class="rights-blocker" v-if="overlayLoading">
            <b-spinner/>
        </div>

        <div id="id_verilive"></div>

        <AppButton class="repeat-verilive-button" v-if="showRepeatVeriliveButton" @click.native="repeatVeriliveClicked"
                   :title="'Повторить'"/>

        <AppButton v-if="successText" class="go-register" @click.native="goBackToRegisterClicked"
                   :title="redirectToLoginText"/>

        <AlertInfoText/>
    </main>
</template>

<script>
import i18n from '../plugins/i18n'
import Cookies from "js-cookie";
import {mapActions} from "vuex";
import AlertInfoText from "@/components/modals/AlertInfoText";
import AppButton from "@/components/AppButton";
import {veriliveConfig} from "@/config/veriliveConfig";

const tenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

export default {
    name: 'BiometryView',
    metaInfo: {
        title: i18n.tc('Authorization'),
        titleTemplate: '%s | Nomad.kz'
    },
    components: {
        AlertInfoText,
        AppButton,
    },
    data() {
        return {
            loading: false,
            overlayLoading: false,

            veriliveFailCallback: false,
            veriliveErrorCallback: false,
            coidNotPassError: false,
            coidServerError: false,
            showRepeatVeriliveButton: false,
            once: false,
            successText: false,
            iin: '',
            reRegistration: false,
        }
    },
    methods: {
        ...mapActions({
            setAlertText: 'modalOptions/setAlertText',
            setShowSecondStepOfLoginPage: 'verilive/setShowSecondStepOfLoginPage'
        }),

        async repeatVeriliveClicked() {
            await this.finishBiometry()
            await this.startVeriliveVerification()
        },

        goBackToRegisterClicked() {
            // устанавливаем флаг, чтобы в логин странице перенаправил на второй шаг (шаг где вводит тел номер)
            this.setShowSecondStepOfLoginPage(true)
            this.$router.push({name: 'login'})
        },

        resetData() {
            this.veriliveFailCallback = false
            this.veriliveErrorCallback = false
            this.coidNotPassError = false
            this.coidServerError = false
            this.showRepeatVeriliveButton = false
        },

        /**
         * Точка входа триггера, инициализирует Verilive
         * */
        async startVeriliveVerification() {
            this.resetData()
            await this.initWebVersion()
            await this.checkIsReRegistration()
        },

        /**
         * Если клиент проверку на живость прошел, то проверяем bestframe с ЦОИД-ом
         * */
        async successCallback(result) {
            await this.finishBiometry()

            const veriliveIin = this.$store.getters["verilive/iin"]
            this.verifyBestFrameWithCoidService(veriliveIin, result.bestframe)
        },
        failCallback(result) {
            this.finishBiometry()

            switch (result.reason) {
                case 'model_prediction':
                    this.veriliveFailCallback = this.$t('verilive.reason.model_prediction')
                    break
                case 'timeout':
                    this.veriliveFailCallback = this.$t('verilive.reason.timeout')
                    break
                case 'page_closed':
                    this.veriliveFailCallback = this.$t('verilive.reason.page_closed')
                    break
                default:
                    this.veriliveFailCallback = this.$t('verilive.reason.unknown_error')
                    break
            }
            this.setAlertText(this.veriliveFailCallback)

            this.showRepeatVeriliveButton = true
        },
        errorCallback(data) {
            console.error(data);
            this.finishBiometry()

            this.veriliveErrorCallback = 'Произошла непредвиденная ошибка при проверке на живость, пожалуйста попробуйте еще раз'
            this.setAlertText(this.veriliveErrorCallback)

            this.showRepeatVeriliveButton = true
        },

        /**
         * Инициализация Verilive для веб браузеров
         * */
        async initWebVersion() {
            this.overlayLoading = true
            const config = veriliveConfig.webVersionConfig

            /*eslint-disable*/
            if (!this.once) {
                verilive.errorCallback = this.errorCallback;
                verilive.successCallback = this.successCallback;
                verilive.failCallback = this.failCallback;
                this.once = true
            }
            /*eslint-enable*/

            // eslint-disable-next-line no-undef
            await verilive.init(veriliveConfig.sourceWebVersion, config)
                .then(async () => {
                    await this.startCameraVeriliveService();
                })
                .catch((e) => {
                    this.commonHelper.debugLog({
                        errorMessage: e.message,
                        iin: this.iin
                    })

                    this.setAlertText(e.message)
                    this.showRepeatVeriliveButton = true
                });
            this.overlayLoading = false
        },

        /**
         * Инициализирует камеру в веб странице
         * */
        async startCameraVeriliveService() {
            this.overlayLoading = true

            // получаем access_token и person_id
            let accessToken = Cookies.get('access_token');
            let personId = Cookies.get('person_id');

            // если нету access_token или person_id, то получаем или генерируем потом получаем
            if (!accessToken || !personId) {
                try {
                    const iin = this.$store.getters["verilive/iin"]
                    await this.generateAccessToken(iin);
                    // получаем access_token и person_id
                    accessToken = Cookies.get('access_token');
                    personId = Cookies.get('person_id');
                    // eslint-disable-next-line no-undef
                    await verilive.start(accessToken, personId);
                } catch (e) {
                    this.commonHelper.debugLog({
                        errorMessage: e.message,
                        iin: this.iin
                    })
                }
            } else {
                // eslint-disable-next-line no-undef
                await verilive.start(accessToken, personId);
            }
            this.overlayLoading = false
        },

        /**
         * Получает access_token и person_id и сохраняет в cookie на 10 минут.
         * Если нету access_token или person_id, то генерирует их
         * */
        async generateAccessToken(iin) {
            try {
                this.loading = true;
                const response = await this.$axios.get('/verigram/credentials', {
                    params: {
                        iin: iin
                    }
                });
                if (response.data.success) {
                    Cookies.set('access_token', response.data.access_token, {expires: tenMinutes});
                    Cookies.set('person_id', response.data.person_id, {expires: tenMinutes});
                }
            } catch (e) {
                this.commonHelper.debugLog({
                    errorName: e.name,
                    errorMessage: e.message,
                    requestUrl: e.config.url,
                    responseStatus: e.status,
                    iin: iin
                })
            } finally {
                this.loading = false;
            }
        },

        /**
         * Останавливает камеру и удаляет все HTML-элементы, созданные при запуске сеанса.
         * */
        async finishBiometry() {
            this.overlayLoading = true
            // eslint-disable-next-line no-undef
            await verilive.dispose()
            this.overlayLoading = false
        },

        /**
         * проверка фото лица с государственной базой данных
         * */
        verifyBestFrameWithCoidService(iin, bestFrame) {
            this.overlayLoading = true
            this.$axios.post('/verigram/verify/coid', {
                iin: iin,
                best_frame: bestFrame,
            }).then(response => {
                if (response.data.success) {
                    this.finishBiometry()
                    this.showRepeatVeriliveButton = false
                    this.successText = 'Вы успешно прошли проверку!'
                    this.setAlertText(this.successText)
                } else {
                    this.coidNotPassError = "Вы прошли проверку на живость, но не прошли идентификацию с государственной базой данных"
                    this.setAlertText(this.coidNotPassError)
                    this.showRepeatVeriliveButton = true
                }
            }).catch(e => {
                this.commonHelper.debugLog({
                    errorName: e.name,
                    errorMessage: e.message,
                    requestUrl: e.config.url,
                    responseStatus: e.status,
                    iin: iin
                })

                this.coidServerError = "Возникли проблемы с сервером, попробуйте еще раз"
                this.setAlertText(this.coidServerError)
                this.showRepeatVeriliveButton = true
            }).finally(() => {
                this.overlayLoading = false
            })
        },

        async checkIsReRegistration() {
            const btsResponse = await this.$axios.post('auth/check-profile-exists-bts', {
                iin: this.iin
            });
            if(btsResponse.data?.reRegistration) {
                this.reRegistration = btsResponse.data.reRegistration
            }
        }
    },
    computed: {
        isMobile() {
            // eslint-disable-next-line no-undef
            return isMobile()
        },
        redirectToLoginText ()
        {
            return this.reRegistration ? 'Сменить телефон' : 'Вернуться к регистрации'
        }
    },
    mounted() {
        if (this.$store.getters["verilive/iin"]) {
            this.iin = this.$store.getters["verilive/iin"]
            this.startVeriliveVerification()
        } else {
            this.$router.push({name: 'login'})
        }
    }
}
</script>

<style lang="scss">
.verilive-wrapper-lk{
    width: 600px;
    height: 400px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 40%;

    .repeat-verilive-button, .go-register{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
}

@media (max-width: 767px){
    .verilive-wrapper-lk-mob{
        width: 68vw;
        top: 35%;
    }
}
</style>
