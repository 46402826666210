import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '@/pages/LoginPage'
import LkView from '@/pages/LkPage'
import store from "@/store";
import PropertyInsurance from "@/pages/insurance/PropertyInsurance";
import OGPO from "@/pages/insurance/OGPO";
// import KaskoDtp from "@/pages/insurance/KaskoDtp";
import DSGPO from "@/pages/insurance/DSGPO";
import KaskoTransformer from "@/pages/insurance/KaskoTransformer";
import MST from "@/pages/insurance/MST";
import ONT from "@/pages/insurance/ONT";
import Advokat from "@/pages/insurance/Advokat";
import Chsi from "@/pages/insurance/Chsi";
import Puk from "@/pages/insurance/Puk";
import Opp from "@/pages/insurance/Opp";
import Comfort from "@/pages/insurance/Comfort";
import Cargo from "@/pages/insurance/Cargo";
import OperatorLoginPage from "@/pages/operator/OperatorLoginPage";
import OperatorDashboard from "@/pages/operator/OperatorDashboard";
import knowledgeBase from "@/pages/knowledgeBase";

import middlewarePipeline from "./middleware/middlewarePipeline";
import guest from './middleware/guest'
import auth from './middleware/auth'
import operator from "@/router/middleware/operator";
import payer from "@/router/middleware/payer";
import user from "@/router/middleware/user";
import PayPage from "@/pages/PayPage";
import ClientCard from "@/pages/operator/ClientCard";
import AllCallHistory from "@/pages/operator/AllCallHistory";
import ApplicationsPage from "@/pages/operator/ApplicationsPage";
import ApplicationsPageVV from "@/pages/operator/ApplicationsPageVV";
import ApplicationReportPage from "@/pages/operator/ApplicationReportPage";
import PurchasedPoliciesReport from "@/pages/operator/PurchasedPoliciesReport";
import MissedCallsReport from "@/pages/operator/MissedCallsReport";
import JuridicalContracts from "@/pages/operator/JuridicalContracts";
import ServerSetting from "@/pages/operator/ServerSetting";
import mobileApp from "@/router/middleware/mobileApp";
import MorePage from "@/pages/mobileApp/MorePage";
import AboutPage from "@/pages/mobileApp/AboutPage";
import EuroprotocolPage from "@/pages/mobileApp/EuroprotocolPage";
import DynamicInfoPage from "@/pages/mobileApp/DynamicInfoPage";
import BiometryPage from "@/pages/BiometryPage";
import InsuranceCaseChatOperator from "@/components/InsuranceCaseChatOperator.vue";
import InsuranceCaseChatOperator_full from "@/components/InsuranceCaseChatOperator_full.vue";
import Accident from '@/pages/insurance/Accident.vue'
import AccidentSport from '@/pages/insurance/AccidentSport.vue'
import VremenniyPoliciesReport from "@/pages/operator/VremenniyPoliciesReport.vue";
import KdpPage from "@/pages/KdpPage.vue";

Vue.use(VueRouter)

export const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: LkView,
        meta: {
            middleware: [
                auth,
                user
            ],
            layout: 'AppLayoutMain'
        }
    },
    {
      path: '/chat/:chatId',
      name: 'showChat',
      component: LkView,
      props: true,
      meta: {
        middleware: [
          auth,
          user
        ],
        layout: 'AppLayoutMain'
      }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/biometry',
        name: 'biometry',
        component: BiometryPage,
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
      path: '/kdp',
      name: 'kdp',
      component: KdpPage,
      meta: {
        middleware: [
          guest
        ]
      }
    },
    {
        path: '/insurance/property',
        name: 'propertyInsurance',
        component: PropertyInsurance,
        props: true,
        meta: {
            middleware: [
                auth,
                user
            ],
            layout: 'AppLayoutWithScrollBar'
        }
    },
/*    {
        path: '/insurance/dtp',
        name: 'KaskoDtp',
        component: KaskoDtp,
        props: true,
        meta: {
            middleware: [
                auth,
                user
            ],
            layout: 'AppLayoutWithScrollBar'
        }
    },*/
    {
        path: '/pay',
        name: 'PayPage',
        component: PayPage,
        props: true,
        meta: {
            middleware: [
                auth
            ],
            layout: 'AppLayoutMain'
        }
    },
    {
        path: '/insurance/dsgpo',
        name: 'DSGPO',
        component: DSGPO,
        props: true,
        meta: {
            middleware: [
                auth,
                user
            ],
            layout: 'AppLayoutWithScrollBar'
        }
    },
    {
        path: '/insurance/ogpo',
        name: 'OGPO',
        component: OGPO,
        props: true,
        meta: {
            middleware: [
                auth,
                user
            ],
            layout: 'AppLayoutWithScrollBar'
        }
    },
    {
        path: '/insurance/kasko-transformer',
        name: 'KaskoTransformer',
        component: KaskoTransformer,
        props: true,
        meta: {
            middleware: [
                auth,
                user
            ],
            layout: 'AppLayoutWithScrollBar'
        }
    },
    {
        path: '/insurance/mst',
        name: 'MST',
        component: MST,
        props: true,
        meta: {
            middleware: [
              auth,
              user
            ],
            layout: 'AppLayoutWithScrollBar'
        }
    },
  {
    path: '/insurance/ont',
    name: 'ONT',
    component: ONT,
    props: true,
    meta: {
      middleware: [
        auth,
        user
      ],
      layout: 'AppLayoutWithScrollBar'
    }
  },
  {
    path: '/insurance/advokat',
    name: 'ADVOKAT',
    component: Advokat,
    props: true,
    meta: {
      middleware: [
        auth,
        user
      ],
      layout: 'AppLayoutWithScrollBar'
    }
  },
  {
    path: '/insurance/chsi',
    name: 'CHSI',
    component: Chsi,
    props: true,
    meta: {
      middleware: [
        auth,
        user
      ],
      layout: 'AppLayoutWithScrollBar'
    }
  },
  {
    path: '/insurance/puk',
    name: 'PUK',
    component: Puk,
    props: true,
    meta: {
      middleware: [
        auth,
        user
      ],
      layout: 'AppLayoutWithScrollBar'
    }
  },
  {
    path: '/insurance/comfort',
    name: 'Comfort',
    component: Comfort,
    props: true,
    meta: {
      middleware: [
        auth,
        user
      ],
      layout: 'AppLayoutWithScrollBar'
    }
  },
  {
    path: '/insurance/cargo',
    name: 'Cargo',
    component: Cargo,
    props: true,
    meta: {
      middleware: [
        auth,
        user
      ],
      layout: 'AppLayoutWithScrollBar'
    }
  },
  {
    path: '/insurance/accident',
    name: 'ACCIDENT',
    component: Accident,
    props: true,
    meta: {
      middleware: [
        auth,
        user
      ],
      layout: 'AppLayoutWithScrollBar'
    }
  },
  {
    path: '/insurance/accident-sport',
    name: 'ACCIDENT_SPORT',
    component: AccidentSport,
    props: true,
    meta: {
      middleware: [
        auth,
        user
      ],
      layout: 'AppLayoutWithScrollBar'
    }
  },
  {
    path: '/insurance/opp',
    name: 'Opp',
    component: Opp,
    props: true,
    meta: {
      middleware: [
        auth,
        user
      ],
      layout: 'AppLayoutWithScrollBar'
    }
  },
  {
    path: '/more',
    name: 'MorePage',
    component: MorePage,
    props: true,
    meta: {
      middleware: [
        auth,
        mobileApp
      ],
      layout: 'AppLayoutMain'
    }
  },
  {
    path: '/more/about',
    name: 'AboutPage',
    component: AboutPage,
    props: true,
    meta: {
      middleware: [
        auth,
        mobileApp
      ],
      layout: 'AppLayoutWithTopNavBar'
    }
  },
  {
    path: '/europrotocol',
    name: 'EuroprotocolPage',
    component: EuroprotocolPage,
    props: true,
    meta: {
      middleware: [
        auth
        // mobileApp
      ],
      layout: 'AppLayoutEuroprotocol'
    }
  },
  {
    path: '/more/dynamic-info',
    name: 'DynamicInfoPage',
    component: DynamicInfoPage,
    props: true,
    meta: {
      middleware: [
        auth,
        mobileApp
      ],
      layout: 'AppLayoutWithTopNavBar'
    }
  },
    //operator routes
    {
        path: '/operator',
        component: {
            render: (c) => c('router-view'),
        },
        children: [
            {
                path: 'login',
                name: 'OperatorLogin',
                component: OperatorLoginPage,
                meta: {
                    middleware: [
                        guest
                    ],
                    layout: 'AppLayoutOperatorLogin'
                }
            },
            {
                path: 'dashboard',
                name: 'OperatorDashboard',
                component: OperatorDashboard,
                meta: {
                    middleware: [
                        auth,
                        operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
                path: '/operator/chat',
                name: 'chat',
                component: InsuranceCaseChatOperator,
                meta: {
                    middleware: [
                        auth,
                        payer
                    ],
                }
            },
            {
                path: 'knowledgeBase',
                name: 'knowledgeBase',
                component: knowledgeBase,
                meta: {
                    middleware: [
                        auth,
                        operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
                path: 'clients/:id',
                name: 'ClientCard',
                component: ClientCard,
                meta: {
                    middleware: [
                        auth,
                        operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
                path: 'call/history',
                name: 'AllCallHistory',
                component: AllCallHistory,
                meta: {
                    middleware: [
                      auth,
                      operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
                path: 'call/missed/report',
                name: 'MissedCallsReport',
                component: MissedCallsReport,
                meta: {
                    middleware: [
                        auth,
                        operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
                path: 'applications',
                name: 'ApplicationsPage',
                component: ApplicationsPage,
                meta: {
                    middleware: [
                        auth,
                        operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
            path: 'applications/vremenniy',
            name: 'ApplicationsPageVV',
            component: ApplicationsPageVV,
            meta: {
              middleware: [
                auth,
                operator
              ],
              layout: 'AppLayoutOperator'
            }
          },
            {
                path: 'applications/report',
                name: 'ApplicationReportPage',
                component: ApplicationReportPage,
                meta: {
                    middleware: [
                        auth,
                        operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
                path: 'applications/:id',
                name: 'ApplicationsPageId',
                component: ApplicationsPage,
                meta: {
                    middleware: [
                        auth,
                        operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
                path: 'purchased-policies-report',
                name: 'PurchasedPoliciesReport',
                component: PurchasedPoliciesReport,
                meta: {
                    middleware: [
                        auth,
                        operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
              path: 'vremenniy-policies-report',
              name: 'VremenniyPoliciesReport',
              component: VremenniyPoliciesReport,
              meta: {
                middleware: [
                  auth,
                  operator
                ],
                layout: 'AppLayoutOperator'
              }
            },
            {
                path: 'juridical-contracts',
                name: 'JuridicalContracts',
                component: JuridicalContracts,
                meta: {
                    middleware: [
                        auth,
                        operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
                path: 'server-setting',
                name: 'ServerSetting',
                component: ServerSetting,
                meta: {
                    middleware: [
                        auth,
                        operator
                    ],
                    layout: 'AppLayoutOperator'
                }
            },
            {
              path: 'chat-full',
              name: 'PayChatFull',
              component: InsuranceCaseChatOperator_full,
              meta: {
                middleware: [
                  auth,
                  payer
                ],
              }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const guard = (to, from, next) => {
    // check for valid auth token
    Vue.prototype.$axios.get('/token/check').then(() => {
      next();
    })
};


router.beforeEach((to, from, next) => {
    const loginRoutes = ['login', 'OperatorLogin', 'biometry', 'kdp'];

    if (!loginRoutes.includes(to.name)) {
      guard(to, from, next);
    }

    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router
