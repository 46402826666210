<template>
    <div class="list-container">
        <div class="list-item list-item-separator"
             v-for="(item, index) in listActions"
             :key="index"
             @click="listItemClickHandler(item)">
            <div v-if="item.icon !== ''" class="nui-icon list-item-icon" :class="item.icon"></div>
            <div class="list-item-label">{{ item.label }}</div>
            <div class="nui-icon nui-icon-arrow-right-rounded"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoNavigationList",
    props: {
        listActions: {
            required: true,
            type: Array
        }
    },
    methods: {
        listItemClickHandler(item) {
           if(item.action) {
               item.action();
           }
        },
    }
}
</script>

<style lang="scss">
</style>
