<template>
    <div class="calc-module-210222 mst-gateway">
            <section class="base-calc mst-calc" data-calc="MST">
                <div class="container">
                    <div id="pre-loader">
                        <b-spinner />
                    </div>
              <div class="calculator-description-box">
                <div class="calculator-description-header">
                  <h2 class="calculator-title">{{$t('CALCULATOR_INFO.MST.TITLE')}}</h2>
                  <span :class="['calculator-description-arrow', {'is-closed': isMobile}]"></span>
                </div>
                <div :class="['calculator-description-body', {'is-closed': isMobile}, {'mob-show-scroll': isMobile}]">
                  <p class="calculator-description-text" v-html="$t('CALCULATOR_INFO.MST.DESCRIPTION')"></p>
                </div>
              </div>
                    <h1 class="ogpo-calc__main-title">{{ calculatorName() }}</h1>
                    <div class="ogpo-content">
                        <ExtendedConfirmationModal/>
                        <ExtendedConfirmationInsuredModal/>
                        <FullScreenPreloader :loading="loading"/>
                        <!--step 1 - agreement with data collection and processing-->
                        <div class="rights-blocker iin-collect" data-rights-blocker>
                            <div class="popup">
                            <div class="loader-container" data-loader>
                                <div class="loader"></div>
                            </div>
                                <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                                <p class="popup-text" v-html="$t('AGREEMENT_WITH_DATA_COLLECTION')"></p>
                                <label class="ogpo-calc__label step1 agreement-container">
                                <p class="step1__agree-text">{{ $t('IINS_WHO_GAVE_CONSENT') }}</p>
                                    <input data-field="iin_for_agree" name="iin_for_agree" type="text"
                                           class="iin-for-agree imask-iin">
                                    <span data-error="iin-collect-error-field" class="iin-collect-error-field"></span>
                                </label>
                                <button class="ogpo-calc__button mob-right" data-btn="data_collection_confirm">
                                    {{ $t('I_CONFIRM') }}
                                </button>
                            </div>
                        </div>
                        <div class="delete-country-popup" data-delete-country-blocker>
                            <div class="popup">
                                <button type="button" class="close-popup" data-btn="cancel-delete-country"></button>
                                <p class="popup-text">{{ $t('DELETE_COUNTRY_CONFIRM_MESS') }}</p>
                                <div class="popup-actions">
                                    <button class="cancel-delete-country__button" data-btn="cancel-delete-country">
                                        {{ $t('CANCEL_DELETE_COUNTRY') }}
                                    </button>
                                    <button class="delete-country__button mob-right" data-btn="confirm-delete-country">
                                        {{ $t('DELETE') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--step 2 - agreement with rules of policy-->
                        <div class="rights-blocker" data-personal-blocker style="display: none">
                            <div class="popup">
                                <p class="popup-title gray">{{ $t('DEAR_CLIENT') }}</p>
                                <p class="popup-text">{{ $t('CALC_RIGHT_S2_CONTENT_BODY') }}</p>
                                <label class="ogpo-calc__label step2 mt-30">
                                    <input data-field="confirm" name="confirm" type="checkbox" value="true">
                                    <span class="ogpo-calc__checkBox"></span>
                                    <div v-html="$t('CALC_RIGHT_S2_CONFIRM_MST')"></div>
                                </label>
                            </div>
                        </div>
                        <div class="ogpo-calc__nav">
                            <div class="ul">
                                <div class="ogpo-calc__line ogpo-calc__line-1 active" data-step-tab="1">
                                    <div class="ogpo-calc__nav-link link-step-1">1</div>
                                    <div class="line"></div>
                                </div>
                                <div class="ogpo-calc__line ogpo-calc__line-2" data-step-tab="2">
                                    <div class="ogpo-calc__nav-link link-step-2">2</div>
                                    <div class="line"></div>
                                </div>
                                <div class="ogpo-calc__line ogpo-calc__line-3" data-step-tab="3">
                                    <div class="ogpo-calc__nav-link link-step-3">3</div>
                                    <div class="line"></div>
                                </div>
                                <div class="ogpo-calc__line ogpo-calc__line-4"></div>
                            </div>
                        </div>
                        <div class="step-1 step" data-step-cont="1">
                            <div class="step-1__wrapper">
                                <form class="ogpo-calc__block-top-box" action="javascript:void(0);" data-form="first"
                                      method="post">
                                    <div class="ogpo-calc__block-checkbox main-confirm-step1">
                                        <label class="ogpo-calc__label">
                                            <input required class="ogpo-calc__input" type="checkbox"
                                                   data-linked="confirm" data-field="confirm" value="true">
                                            <span class="ogpo-calc__checkBox"></span>
                                            <div v-html="$t('PROPERTY_DATA_COLLECTION_OK')"></div>
                                        </label>
                                    </div>
                                    <div class="ogpo-calc__block-top">
                                        <div class="mst-calc__left">
                                        <div class="row m-bottom main-country-wrapper">
                                            <div
                                                class="datepicker-block pt-18 mst-calc__datepicker-wrapper custom__input-wrapper">
                                                <div class="mst__datepicker-wrapper custom__input-box">
                                                    <h4 class="mst-calc__title input__title">{{
                                                            $t('START_DATE')
                                                        }}</h4>
                                                    <div class="input-box input-box-left">
                                                        <input type="text" name="start_date"
                                                               readonly
                                                               class="bg-icon mst-input-sm
                                                                       input-date-from imask-date mst-calc__datepicker"
                                                               data-linked="start_date">
                                                    </div>
                                                </div>

                                                <div class="mst__datepicker-wrapper custom__input-box">
                                                    <h4 class="mst-calc__title input__title">{{
                                                            $t('END_DATE')
                                                        }}</h4>
                                                    <div class="input-box">
                                                        <input type="text"
                                                               readonly
                                                               name="end_date" class="bg-icon mst-input-sm
                                                                       input-date-to imask-date mst-calc__datepicker"
                                                               data-linked="end_date">
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="mst-calc__country-wrapper custom__input-wrapper"
                                                 data-number="1">
                                                <div class="mst-calc__country custom__input-box">
                                                    <h4 class="mst-calc__title input__title">{{
                                                            $t('HOST_COUNTRY')
                                                        }}</h4>
                                                        <select class="country-select mst-input-sm mst-select2"
                                                                name="country" data-linked="country"
                                                                data-dun-select="country">
                                                            <option value="1">Австралич</option>
                                                            <option value="2">Австрия</option>
                                                            <option value="3">Азербайджан</option>
                                                            <option value="4">Австралич</option>
                                                            <option value="5">Австрия</option>
                                                            <option value="6">Азербайджан</option>
                                                            <option value="1">Австралич</option>
                                                            <option value="2">Австрия</option>
                                                            <option value="3">Азербайджан</option>
                                                            <option value="4">Австралич</option>
                                                            <option value="5">Австрия</option>
                                                        </select>
                                                    </div>
                                                <div class="mst-calc__price custom__input-box">
                                                    <h4 class="mst-calc__title input__title">{{
                                                            $t('SUM_INSURED')
                                                        }}</h4>
                                                        <div class="ms-selct">
                                                        <select class="js-select2 price-select mst-input-sm" name="ins_sum"
                                                                    data-linked="ins_sum" data-dun-select="ins_sum">
                                                            </select>
                                                        </div>
                                                    </div>
                                                <div class="mst-calc__add-country-wrapper">
                                                    <button type="button"
                                                            class="mst-calc__add-country-btn mst-calc__name-title"
                                                            data-btn="add-country">
                                                        {{ $t('ADD_COUNTRY') }}
                                                    </button>
                                                </div>
                                                    <div class="mst-calc__delete-country-wrapper">
                                                        <button type="button"
                                                                class="mst-calc__delete-country-btn mst-calc__name-title"
                                                                data-btn="delete-country">
                                                            {{ $t('DELETE_COUNTRY') }}
                                                        </button>
                                                    </div>
                                                </div>
                                                </div>

                                            <div class="row mst-row">
                                            <div class="mst-calc__block-flex1 custom__input-wrapper mob">
                                                <div class="mst-left-block custom__input-box">
                                                        <div class="col-wrapper">
                                                        <h4 class="mst-calc__title input__title"> {{
                                                                $t('TRIP_PURPOSE')
                                                            }} </h4>
                                                        <select class="trip-select mst-input-b js-select2"
                                                                name="trip_purpose" data-dun-select="trip_purpose"
                                                                data-linked="trip_purpose">
                                                            <option value="1">Туризм, деловые поездки, гостевые
                                                                поездки
                                                            </option>
                                                                </select>
                                                            </div>
                                                            <div class="version2023 section-row-one select-file-block mst-select mst-photo-input" data-js="select-stud-bilet">
                                                    <div class="input-group">
                                                        <h4 class="input-title">{{ $t('PHOTO_OF_STUD_BILET') }}</h4>
                                                        <div class="input-wrapper">
                                                            <div class="calc-dropzone dropzone js-dropzone-two" data-js="dropzone-area">
                                                                <button data-js="dropzone-btn" class="dropzone-button">{{ $t('SELECT_FILE') }}</button>
                                                                <p class="dz-message needsclick calc-dropzone-placeholder">{{ $t('DRAG_FILE_HERE') }}</p>
                                                            </div>
                                                            <p class="input-error"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                        </div>
                                                <div class="mst-right-block custom__input-box active_relax">
                                                        <div class="ogpo-calc__block-checkbox mst-checkbox">
                                                            <label class="ogpo-calc__label active-relax mst-relax">
                                                                <input class="ogpo-calc__input" type="checkbox" data-linked="active_relax" value="true">
                                                                <span class="ogpo-calc__checkBox"></span>
                                                                {{ $t('MST_ACTIVE_RELAX_LABEL') }}
                                                            </label>
                                                            <div class="info-icon mob-left cooltipz-custom-width-164"
                                                                 :aria-label="$t('MST_TEXT')"
                                                             data-cooltipz-dir="bottom">
                                                                <div class="question-mark"></div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                            <div class="custom__input-wrapper mob">
                                                <div class="mst-calc__block-flex custom__input-box" data-sport-fields>
                                                            <div class="col-wrapper">
                                                        <h4 class="mst-calc__title input__title">{{
                                                                $t('SPORT_TYPE')
                                                            }}</h4>
                                                        <select class="trip-select mst-input-b js-select2"
                                                                name="sport_type" data-dun-select="sport_type"
                                                                data-linked="sport_type">
                                                            <option value="1">Зимние виды спорта (кроме горных лыж)
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="mst-calc__block-flex custom__input-box" data-sport-fields>
                                                    <div class="col-wrapper">
                                                        <h4 class="mst-calc__title input__title">{{
                                                                $t('SPORT_LEVEL')
                                                            }}</h4>
                                                                <div class="ms-selct pad-right-mst">
                                                                    <select class="trip-select mst-input-b js-select2" name="sport_level" data-dun-select="sport_level" data-linked="sport_level">
                                                                        <option value="1">Любительский спорт</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                                        <div class="mst-calc__block-flex mr-none custom__input-box"
                                             style="display: none">
                                                <div class="row mob-column covi">
                                                    <div class="ogpo-calc__block-checkbox mst-checkbox-covid">
                                                        <label class="ogpo-calc__label">
                                                            <input class="ogpo-calc__input covid-cb" type="checkbox" data-linked="covid_19_radio"
                                                                   value="true">
                                                            <span class="ogpo-calc__checkBox"></span>
                                                            {{ $t('MST_COVID_19') }}
                                                        </label>
                                                    </div>
                                                    <div class="col-wrapper-xsm covidPrice-block" data-covid-prices>
                                                        <div class="ms-selct">
                                                            <select class="covidPrice-select mst-input-sm" name="price" data-linked="covid_19" data-dun-select="covid_19">
                                                                <option value="">0 KZT</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <!--module addTourist-->
                                <div data-add-tourist="MST" class="mst-card">
                                    <form action="javascript:;">
                                        <input type="hidden" name="is_child" value="false" data-linked="is_child">
                                        <input type="hidden" name="is_insurant" value="true" data-linked="is_insurant" data-field="is_insurant">
                                        <input type="hidden" name="is_insured" value="true" data-linked="is_insured">
                                        <div class="mst-calc__block-bottom-wrap">
                                        <div class="custom__input-wrapper">
                                            <div class="mst-calc__block-bottom custom__input-box last-input-block">
                                                <div class="mst-calc__block-flex mob-option">
                                                    <div class="col-wrapper ">
                                                        <div class="block-ins-mst input__title">
                                                            <h4 class="mst-calc__title">{{ $t('IIN') }}</h4>
                                                            <h4 class="mst-calc__name-title des mst-is-insurant" data-text="is_insurant">{{ $t('I_INSURANT') }}</h4>
                                                        </div>
                                                        <label class="label-iin">
                                                            <input class="input-iin mst-input-b iin-child imask-iin" type="text" data-linked="iin" data-field="iin" :value="iinValue">
                                                            <div class="preloader hide" data-preloader>
                                                                <div class="preloader__item"></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div class="col-wrapper width-option">
                                                        <p class="ogpo-calc__validation-error" data-result="error">{{ $t('ERROR_DATA') }}</p>
                                                        <p class="ogpo-calc__validation-error" data-loading>{{ $t('LOADING') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mst-calc__block-bottom custom__input-box last-input-block">
                                                <div class="mst-calc__block-flex mob-option">
                                                    <div class="col-wrapper ">
                                                        <h4 class="mst-calc__title input__title">{{
                                                                $t('DTP_BORN_LABEL')
                                                            }}</h4>
                                                        <div class="datepicker-wrapper-container">
                                                            <div class="datepicker-wrapper">
                                                                <input class="input datepicker-base imask-date" type="text" style="width: 100%" data-linked="born" data-field="born" :placeholder="$t('DMY')">
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div data-list class="data-list-mst"></div>
                                </div>

                                <div class="mst-calc__block-bottom w-100">
                                    <div class="col-wrapper"></div>
                                    <div class="form-mst-result-price">

                                        <div class="mst-discount-wrapper"  data-js="discount-container">
                                            <div class="ogpo-calc__price"
                                                 data-js="price-without-discount-container">
                                                <h4 class="ogpo-calc__price-title">{{ $t('POLICY_COST') }}</h4>
                                                <div class="ogpo-calc__price-value price-without-discount black"
                                                     data-js="initial-price"></div>
                                            </div>
                                            <div class="ogpo-calc__price discount-container">
                                                <h4 class="ogpo-calc__price-title">{{ $t('Discount') }}</h4>
                                                <div class="ogpo-calc__price-value price-discount-percent black"
                                                     data-js="price-discount-percent"></div>
                                            </div>
                                            <div class="ogpo-calc__price">
                                                <h4 class="ogpo-calc__price-title">
                                                    {{ $t('POLICY_COST_WITH_DISCOUNT') }}</h4>
                                                <div class="ogpo-calc__price-value" data-calc-result="price">18 858 KZT
                                                </div>
                                            </div>
                                            <div data-js="discount_mst_hint_text" class="discount-mst-hint-text">
                                                {{ $t('GET_DISCOUNT_IN_MOBILE_APP') }}
                                            </div>
                                        </div>

                                        <div class="mst-discount-wrapper" data-js="without-discount-container">
                                            <div class="ogpo-calc__price">
                                                <h4 class="ogpo-calc__price-title">
                                                    {{ $t('POLICY_COST') }}</h4>
                                                <div class="ogpo-calc__price-value" data-calc-result="price">18 858 KZT
                                                </div>
                                            </div>
                                            <div data-js="discount_mst_hint_text" class="discount-mst-hint-text">
                                                <p class="discount-mst-hint-text-width">{{
                                                   $t('GET_DISCOUNT_IN_MOBILE_APP') }}</p>
                                            </div>
                                        </div>

                                        <button class="ogpo-calc__button mst-buy-mst-step1" data-btn="buy-step-1">{{ $t('BUY') }}</button>
                                    </div>
                                </div>

                                <!--discount information popup-->
                                <div class="rights-blocker nomad-kz-app-description-popup" data-js="nomad_kz_app_description_popup">
                                    <div class="popup">
                                        <p class="popup-text" v-html="$t('NOMAD_KZ_APP_DESCRIPTION')"/>
                                        <p class="popup-text" v-html="$t('NOMAD_KZ_APP_DESCRIPTION_DOWNLOAD')"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="step-2 step" data-step-cont="2">
                            <form method="post" action="javascript:void(0);" data-form="second">
                                <input type="hidden" name="notify_phone" value="true">
<!--                            <div class="calc-type">{{ $t('STD') }}</div>-->
                            <div class="ogpo-calc__block-checkbox main-confirm-step1 main-confirm-step2">
                                    <label class="ogpo-calc__label">
                                        <input required class="ogpo-calc__input" type="checkbox"
                                               data-linked-step2="confirm" data-field="confirm">
                                        <span class="ogpo-calc__checkBox"></span>
                                    <div v-html="$t('CALC_RIGHT_S2_CONFIRM')"></div>
                                    </label>
                                </div>
                                <div class="step-2__form-wrapper">
                                    <form action="">
                                        <h2 class="step-2__form-title">{{ $t('INSURANT_INFO') }}</h2>
                                        <p class="step-2__form-name" data-insurant="name">Олег И</p>
                                        <div class="step-2__row">
                                            <div class="step-2__col">
                                            <div class="input__title" v-html="$t('MST_LAT_FIRST_NAME')"></div>
                                                <input class="step-2__input uppercase" type="text" data-linked-step2="name" data-main-user-iin>
                                            </div>
                                            <div class="step-2__col">
                                            <div class="input__title" v-html="$t('MST_LAT_LAST_NAME')"></div>
                                                <input class="step-2__input uppercase" type="text" required data-linked-step2="surname" data-main-user-iin>
                                            </div>
                                        </div>
                                        <div class="step-2__row">
                                            <div class="step-2__col">
                                            <div class="input__title">{{ $t('PASSPORT_NUMBER') }}*</div>
                                                <input class="step-2__input imask-passport" type="text" required data-linked-step2="udo_num" placeholder="N12312312" data-main-user-iin>
                                            </div>
                                            <div class="step-2__col">
                                            <div class="input__title">{{ $t('MST_UDO_DATE_LABEL') }}*</div>
                                                <input class="step-2__input datepicker-base imask-date" type="text" required  data-linked-step2="udo_date" data-main-user-iin :placeholder="$t('DMY')">
                                            </div>
                                        </div>
                                        <div class="step-2__row">
                                            <div class="step-2__col">
                                            <div class="input__title">{{ $t('PASSPORT_AUTHOR') }}*</div>
                                                <input class="step-2__input" type="text" data-linked-step2="udo_author">
                                            </div>
                                            <div class="step-2__col">
                                            <div class="input__title">{{ $t('REG_ADDRESS') }}*</div>
                                                <input class="step-2__input" type="text" required data-linked-step2="address">
                                            </div>
                                        </div>
                                        <div class="step-2__row">
                                            <div class="step-2__col">
                                          <div class="horizontal-wrapper input__title">
                                            <div>{{ $t('PHONE_NUMBER') }}*</div>
                                            <div class="info-icon"
                                                 :aria-label="$t('VERIFICATION_PHONE_NUMBER_TOOLTIP')"
                                                 data-cooltipz-dir="bottom">
                                              <div class="question-mark"></div>
                                            </div>
                                          </div>
                                                <input class="step-2__input imask-phone" type="text" required data-linked-step2="phone" :value="user.phone_number">
                                            </div>
                                            <div class="step-2__col">
                                            <div class="input__title">Email*</div>
                                                <input class="step-2__input" type="email" required data-linked-step2="email" :value="user.email">
                                            </div>
                                        </div>

                                        <!--print insureds fields-->
                                        <div data-insured-step2></div>
                                        <!--print insureds fields END-->
                                  <div class="step-2__checkbox-wrapper">
                                    <div class="step-2__checkbox">
                                            <label class="ogpo-calc__label">
                                                <input class="ogpo-calc__input" type="checkbox"
                                                       data-linked-step2="notify_email">
                                                <span class="ogpo-calc__checkBox"></span>
                                                <div>{{ $t('GET_INFO_ON_EMAIL') }}</div>
                                            </label>
                                        </div>

<!--                                    <div class="step-2__checkbox" data-fields="ipdl">-->
<!--                                        <div class="checkbox-prprt">-->
<!--                                            <label class="ogpo-calc__label">-->
<!--                                                <input class="ogpo-calc__input input-IPDL" type="checkbox"-->
<!--                                                       data-linked-step2="is_ipdl" value="true">-->
<!--                                                <span class="ogpo-calc__checkBox"></span>-->
<!--                                                <div>{{ $t('I_AM_IPDL') }}</div>-->
<!--                                            </label>-->
<!--                                        </div>-->
<!--                                        <div class="step-2__dop-polya" data-field-step2="ipdl_options">-->
<!--                                            <div class="step-2__radio-wrapper">-->
<!--                                                <label class="step-2__radio-label">-->
<!--                                                    <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                                           value="Зарплата" checked data-linked-step2="ipdl_type">-->
<!--                                                    <span class="step-2__radio-style"></span>-->
<!--                                                    <div class="step-2__radio-text">{{ $t('SALARY') }}</div>-->
<!--                                                </label>-->
<!--                                            </div>-->
<!--                                            <div class="step-2__radio-wrapper">-->
<!--                                                <label class="step-2__radio-label">-->
<!--                                                    <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                                           value="Доход от предпринимательской деятельности"-->
<!--                                                           data-linked-step2="ipdl_type">-->
<!--                                                    <span class="step-2__radio-style"></span>-->
<!--                                                    <div class="step-2__radio-text">{{ $t('BUSINESS_INCOME') }}</div>-->
<!--                                                </label>-->
<!--                                            </div>-->
<!--                                            <div class="step-2__radio-wrapper">-->
<!--                                                <label class="step-2__radio-label">-->
<!--                                                    <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                                           value="Дивиденды" data-linked-step2="ipdl_type">-->
<!--                                                    <span class="step-2__radio-style"></span>-->
<!--                                                    <div class="step-2__radio-text">{{ $t('DIVIDENDS') }}</div>-->
<!--                                                </label>-->
<!--                                            </div>-->
<!--                                            <div class="step-2__radio-wrapper">-->
<!--                                                <label class="step-2__radio-label">-->
<!--                                                    <input class="step-2__radio-input" type="radio" name="IPDL"-->
<!--                                                           value="Иное" data-linked-step2="ipdl_type">-->
<!--                                                    <span class="step-2__radio-style"></span>-->
<!--                                                    <div class="step-2__radio-text">{{ $t('OTHER') }}</div>-->
<!--                                                </label>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->

                                            </div>

                                        <div class="step-2__flex">
                                            <button class="step-2__button" data-btn="buy-step-2" disabled>
                                          <span v-html="$t('CONFIRM_NUMBER')"></span>
                                                <span data-btn="check-btn-stp-2"></span>
                                            </button>
                                      <div class="block">
                                        <div class="step-2__price-lable">{{ $t('POLICY_COST') }}</div>
                                        <div class="step-2__price-value" data-calc-result="price"></div>
                                      </div>
                                        </div>
                                        <div class="step-2__validation-error" data-form-step2="error">
                                            <p><span>!</span> {{ $t('ERROR_DATA_CHECK_FIELDS') }}</p>
                                        </div>
                                    </form>
                                </div>
                            </form>
                        </div>
                        <div class="step-3 step" data-step-cont="3">
                            <div class="step-3__wrapper">
                                <div class="slick-slider">
                                    <div>
                                        <form class="step-3__card" data-form="third" action="javascript:void(0);">
                                            <input type="hidden" name="type" value="default">
                                            <template v-if="$t('URL_PREFIX')==='/kz'">
                                                <p class="step-3__card-text bolder">{{ $t('MST_INFO') }}</p>
                                                <p class="step-3__card-text">{{ $t('POLICY_COST') }}</p>
                                            </template>
                                            <template v-else>
                                                <p class="step-3__card-text">{{ $t('POLICY_COST') }}</p>
                                                <p class="step-3__card-text bolder">{{ $t('MST_INFO') }}</p>
                                            </template>
                                            <div class="step-3__card-price" data-calc-result='price'>0 KZT</div>
                                            <button class="step-3__card-button" data-btn="buy-step-3" type="button">
                                                {{ $t('PAY_BTN_LINK') }}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import {calculatorsMixin} from "@/mixins/calculators/calculatorsMixin";
import ExtendedConfirmationModal from "@/components/modals/setClient/ExtendedConfirmationModal.vue";
import ExtendedConfirmationInsuredModal from "@/components/modals/setClient/ExtendedConfirmationInsuredModal.vue";
import FullScreenPreloader from "@/components/FullScreenPreloader.vue";
/* eslint-disable */

export default {
    name: "MST",
    components: {FullScreenPreloader, ExtendedConfirmationInsuredModal, ExtendedConfirmationModal},
    metaInfo: {
        title: i18n.tc('MST_CALCULATOR'),
        titleTemplate: '%s | Nomad.kz'
    },
    data: function () {
        return {
            calcName: 'MST',
            user: {},
            clientData: false,
            loading: false,
            isMobile: false
        }
    },
    mixins: [calculatorsMixin],
    methods: {
        fetchUser() {
            this.user = {...this.$store.getters['auth/user']}
            this.user.phone_number = "7" + this.user.phone_number.slice(1)
        },
        async fetchUserData() {
            if (this.policy)
                return

            this.loading = true

            await this.$axios.get(`/client/get/full/?iin=${this.user.iin}`).then(response => {
                this.clientData = response.data.data
            })

            await this.$axios.get('/contracts/user-data/get/from-site', {
                params: {
                    iin: this.user.iin,
                    product_name: 'МСТ'
                }
            }).then(response => {
                if (!response.data.success)
                    return

                let data = response.data.data;
                let fullName = data.LAT_FIO.split(' ')

                let secondFormFields = {
                    name: fullName[1],
                    surname: fullName[0],
                    udo_num: data.DOCUMENT_NUMBER,
                    udo_date: data.DOCUMENT_DATE,
                    udo_author: data.DOCUMENT_AUTHOR,
                    address: this.user.address ? this.user.address : data.ADDRESS,
                    phone: "7" + data.PHONE.slice(1),
                    email: data.EMAIL
                }

                window.calcMST.modules.addTourist.obj.changeForm()
                window.calcMST.modules.addTourist.obj.saveRecord()

                window.calcMST.setSecondFormData(secondFormFields)
            }).finally(() => {
                this.loading = false
            })
        },
        calculatorName() {
            return this.mobileApp.isWebView() ? this.$t('MST_CALCULATOR_MOBILE') : this.$t('MST_CALCULATOR')
        }
    },
    mounted() {
        this.fetchUser()
        /* eslint-disable */
        this.isMobile = isMobile()
        this.$nextTick(() => {
            window.calcMST = new calcMST()
            window.calcMST.init();
            // подставляем ИИН пользователя
            $('[data-field="iin_for_agree"]').val(this.user.iin)
            initImask()
            initMasks()
            initSelect2()
            initDatepickers()
            initDescriptionAccordion()
        })
    },
    computed: {
        iinValue () {
            if (this.policy || this.clientData) {
                return ''
            } else  {
                return this.user.iin
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .container {
        margin: 0 auto;
        position: relative;
    }
    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .mt-30 {
        margin-top: 30px;
    }

    @media (max-width: 991px) {
        .calc-module-210222 .mst-calc .rights-blocker .popup {
            top: unset;
        }
    }

    @media (max-width: 768px) {
        .calc-module-210222 .mst-calc__title {
            font-size: 11px;
        }

    }
</style>
